import CloseIcon from "@mui/icons-material/Close";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetDsSettings, UpdateDsSettings } from "../action/DsSettingsAction";
import { UpdateEntity } from "../action/EntityAction";
import { ENTITY_SUCCESS } from "../action/EntityActionType";
import { EntitiesStoreContext } from "../store/EntitiesStore";
import { RootStore } from "../store/Store";
import { useStyles } from "../style/styles";
import { AntSwitch } from "./Switch";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

interface IDataSharing {
  connectionId: string;
}

const DefaultSettingsModal = (prop: IDataSharing) => {
  const css = useStyles();
  const entityStore = useContext(EntitiesStoreContext);
  const dispatch = useDispatch();

  const handleClose = () => {
    setShowDsModal(false);
  };

  const entityState = useSelector(
    (state: RootStore) => state.entityRootReducer
  );

  const [showDsModal, setShowDsModal] = useState(false);
  const [showResume, setshowResume] = useState(true);
  const [showAddress, setshowAddress] = useState(true);
  const [showEmail, setshowEmail] = useState(true);
  const [showPhone, setshowPhone] = useState(true);
  const [_dsSettingsSelfCompany, _setDsSettingsCompany] = useState(
    entityState.entityReducer?.companies[prop.connectionId]?.dsSettingsSelf
  );
  const [_dsSettingsSelfIndividual, _setDsSettingsIndividual] = useState(
    entityState.entityReducer?.connections[prop.connectionId]?.dsSettingsSelf
  );

  const changeShowResume = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setshowResume(checked);
  };
  const changeShowAddress = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setshowAddress(checked);
  };
  const changeShowEmail = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setshowEmail(checked);
  };
  const changeShowPhone = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setshowPhone(checked);
  };

  const handleShowModal = () => {
    // Company Data Sharing
    if (_dsSettingsSelfCompany) {
      console.log("MODAL COMPANY");
      console.log(_dsSettingsSelfCompany);
      setshowResume(_dsSettingsSelfCompany.showResume);
      setshowAddress(_dsSettingsSelfCompany.showAddress);
      setshowEmail(_dsSettingsSelfCompany.showEmail);
      setshowPhone(_dsSettingsSelfCompany.showPhone);
    } else if (_dsSettingsSelfIndividual) {
      console.log("MODAL INDIVIDUAL");
      // Individual level from entity response
      setshowResume(_dsSettingsSelfIndividual.showResume);
      setshowAddress(_dsSettingsSelfIndividual.showAddress);
      setshowEmail(_dsSettingsSelfIndividual.showEmail);
      setshowPhone(_dsSettingsSelfIndividual.showPhone);
    } else {
      dispatch(GetDsSettings(entityStore.entityId, prop.connectionId)); // indvidual level from data sharing endpoint response (need to check this response)
    }
    setShowDsModal(true);
  };

  const handleSave = () => {
    const payloads = {
      entityId: entityStore.entityId,
      connectionId: prop.connectionId,
      showEmail: showEmail,
      showAddress: showAddress,
      showPhone: showPhone,
      showResume: showResume,
    };

    const comppayloads = {
      id: entityStore.entityId,
      connectionId: prop.connectionId,
      connect: false,
    };

    if (_dsSettingsSelfCompany) {
      console.log("COMPANY");
      _setDsSettingsCompany(payloads);
      if (entityState.entityReducer) {
        for (var key in entityState.entityReducer.companies) {
          if (
            entityState.entityReducer.companies.hasOwnProperty(key) &&
            key == prop.connectionId
          ) {
            console.log("Company Level");
            const companySettings = entityState.entityReducer.companies[key];
            const indivConnComp =
              entityState.entityReducer.companies[key].connections;

            if (showEmail || showPhone || showAddress || showResume) {
              companySettings.contactInfoEnabled = true;
              companySettings.isConnected = true;
            } else {
              companySettings.contactInfoEnabled = false;
              companySettings.isConnected = false;

              // Set the individual connections profile access and contact info to FALSE
              for (var i = 0; i < indivConnComp.length; i++) {
                const indivSettings =
                  entityState.entityReducer.connections[indivConnComp[i]];
                indivSettings.contactInfoEnabled = false;
                indivSettings.profileAccessEnabled = false;
                indivSettings.isConnected = false;
                indivSettings.status = "declined";
              }
            }
            dispatch(UpdateEntity(comppayloads));
          }
          // This will set the state
          dispatch({
            type: ENTITY_SUCCESS,
            payload: entityState.entityReducer,
          });
        }
      }
    } else if (_dsSettingsSelfIndividual) {
      console.log("INDIVIDUAL CONNECTIONS");
      _setDsSettingsIndividual(payloads);
      // It will update the entity state
      if (entityState.entityReducer) {
        for (var key in entityState.entityReducer.connections) {
          const compID = entityState.entityReducer.connections[key].companyId;
          const connections = entityState.entityReducer.connections;
          const companies = entityState.entityReducer.companies;

          if (connections.hasOwnProperty(key) && key == prop.connectionId) {
            if (showEmail || showPhone || showAddress || showResume) {
              if (connections[key].status == "declined") {
                connections[key].contactInfoEnabled = true;
                connections[key].isConnected = true;
                connections[key].status = "accepted";

                companies[compID].count.totalActive++;
                companies[compID].count.totalInactive--;
              }
            } else {
              connections[key].contactInfoEnabled = false;
              connections[key].isConnected = false;
              connections[key].status = "declined";

              companies[compID].count.totalActive--;
              companies[compID].count.totalInactive++;
            }
          }
        }
        dispatch(UpdateDsSettings(payloads));
        // This will set the state
        dispatch({
          type: ENTITY_SUCCESS,
          payload: entityState.entityReducer,
        });
      }
    }

    setShowDsModal(false);
  };

  return (
    <div>
      <Link
        href="#"
        onClick={handleShowModal}
        className={css.enabledLink}
        underline="none"
      >
        <SettingsOutlinedIcon
          sx={{
            fontWeight: 300,
            color: "#2F86DD",
            fontStyle: "normal",
            pr: 1,
          }}
        />
        Default Connections Settings
      </Link>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={showDsModal}
        // sx={{ minWidth: "498px" }}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <Typography className={css.dataSharingSettingsModal}>
            Default Connections Settings
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 12, sm: 8, md: 12 }}
          >
            <Grid
              item
              xs={10}
              container
              spacing={0}
              direction="row"
              alignItems="left"
              justifyContent="left"
            >
              <Typography className={css.normalText500}>
                Data Sharing Settings
              </Typography>
            </Grid>
            <Grid
              item
              xs={2}
              container
              spacing={0}
              direction="row"
              alignItems="right"
              justifyContent="right"
            ></Grid>
            <Grid
              item
              xs={10}
              container
              spacing={0}
              direction="row"
              alignItems="left"
              justifyContent="left"
            >
              <Typography className={css.normalText400} sx={{ pl: "20px" }}>
                Resume
              </Typography>
            </Grid>
            <Grid
              item
              xs={2}
              container
              spacing={0}
              direction="row"
              alignItems="right"
              justifyContent="right"
            >
              <AntSwitch
                inputProps={{ "aria-label": "ant design" }}
                checked={showResume}
                onChange={changeShowResume}
              />
            </Grid>
            <Grid
              item
              xs={10}
              container
              spacing={0}
              direction="row"
              alignItems="left"
              justifyContent="left"
            >
              <Typography className={css.normalText400} sx={{ pl: "20px" }}>
                Address
              </Typography>
            </Grid>
            <Grid
              item
              xs={2}
              container
              spacing={0}
              direction="row"
              alignItems="right"
              justifyContent="right"
            >
              <AntSwitch
                inputProps={{ "aria-label": "ant design" }}
                checked={showAddress}
                onChange={changeShowAddress}
              />
            </Grid>
            <Grid
              item
              xs={10}
              container
              spacing={0}
              direction="row"
              alignItems="left"
              justifyContent="left"
            >
              <Typography className={css.normalText400} sx={{ pl: "20px" }}>
                Phone
              </Typography>
            </Grid>
            <Grid
              item
              xs={2}
              container
              spacing={0}
              direction="row"
              alignItems="right"
              justifyContent="right"
            >
              <AntSwitch
                inputProps={{ "aria-label": "ant design" }}
                checked={showPhone}
                onChange={changeShowPhone}
              />
            </Grid>
            <Grid
              item
              xs={10}
              container
              spacing={0}
              direction="row"
              alignItems="left"
              justifyContent="left"
            >
              <Typography className={css.normalText500}>
                Communication Settings
              </Typography>
            </Grid>
            <Grid
              item
              xs={2}
              container
              spacing={0}
              direction="row"
              alignItems="right"
              justifyContent="right"
            ></Grid>
            <Grid
              item
              xs={10}
              container
              spacing={0}
              direction="row"
              alignItems="left"
              justifyContent="left"
            >
              <Typography className={css.normalText400} sx={{ pl: "20px" }}>
                Email
              </Typography>
            </Grid>
            <Grid
              item
              xs={2}
              container
              spacing={0}
              direction="row"
              alignItems="right"
              justifyContent="right"
            >
              <AntSwitch
                inputProps={{ "aria-label": "ant design" }}
                checked={showEmail}
                onChange={changeShowEmail}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Stack spacing={2} direction="row" sx={{ padding: "10px" }}>
            <Button
              variant="text"
              className={css.cancelTextBtn}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              className={css.saveBtn}
              onClick={handleSave}
            >
              Save
            </Button>
          </Stack>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default DefaultSettingsModal;
