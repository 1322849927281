import { Grid, Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import * as React from "react";
import { useState } from "react";
import { Dimensions } from "react-native";
import { useSelector } from "react-redux";
import NoConnections from "../assets/images/NoConnections.png";
import { RootStore } from "../store/Store";
import { useStyles } from "../style/styles";
import Company from "../views/jobseeker/Company";
import IndividualList from "../views/recruiter/IndividualList";
import SwipeableViews from 'react-swipeable-views'
import { useGlobalState } from "../utils/GlobalState";
import { getEntityCompanyConnection } from "../utils/EntityFunctions";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabContent(conntype: string, tabIndex: number, totalCount: number) {
  const css = useStyles();
  const currPage = useGlobalState("currentPage");
  // function TabContent(conntype, tabIndex) {
  if (conntype.toLowerCase() == "jobseeker") {
    if (totalCount == 0 && currPage[0] == "main") {
      return (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="60vh"
        >
          <Grid>
            <Grid container direction="column">
              <Avatar
                src={NoConnections}
                sx={{ width: "400px", height: "400px" }}
              />
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "24px",
                  lineHeight: "36px",
                }}
                align="center"
              >
                No Connections Available
              </Typography>
            </Grid>
          </Grid>
        </Box>
      );
    } else {
      return (
          <Company tabIndex={tabIndex} />
      );
    }
  } else if (conntype.toLowerCase() == "recruiter") {
    if (totalCount == 0) {
      return (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="60vh"
        >
          <Grid>
            <Grid container direction="column">
              <Avatar
                src={NoConnections}
                sx={{ width: "400px", height: "400px" }}
              />
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "24px",
                  lineHeight: "36px",
                }}
                align="center"
              >
                No Connections Available
              </Typography>
            </Grid>
          </Grid>
        </Box>
      );
    } else {
      return (
        <>
          <IndividualList tabIndex={tabIndex} />
        </>
      );
    }
  }
  return <>Invalid Type</>;
}

interface TabConnProps {
  hideCountBlocked: number;
}

export default function TabConn(props: TabConnProps) {
  const css = useStyles();
  const entityState = useSelector(
    (state: RootStore) => state.entityRootReducer
  );
  const { isMobile } = useSelector(
    (state: RootStore) => state.deviceReducer.deviceData
  );
  const [value, setValue] = useState(0);
  const entity = entityState.entityReducer;
  var totalActive = 0;
  var totalInactive = 0;
  var totalPending = 0;
  var totalBlocked = 0;
  var totalStatus = 0;
  const isJobSeeker = entity && entity.type.toLowerCase() == "jobseeker";
  //const totalBlockedCount = props.hideCountBlocked ? (props.count.totalBlocked - props.hideCountBlocked) : props.count.totalBlocked;

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  if (entity) {
    if (entity.type.toLowerCase() == "jobseeker") {
      entity &&
        Object.entries(entity.companies).map(([k, conn], indx) => {
          if (conn.isConnected) {
            totalActive++;
          }
          if (!conn.isConnected && !conn.isBlocked) {
            totalInactive++;
          }
          if (conn.isBlocked) {
            totalBlocked++;
          }

          totalStatus = totalActive + totalInactive + totalBlocked;
        });
    }

    if (entity.type.toLowerCase() == "recruiter") {
      entity &&
        Object.entries(entity.connections).map(([key, connIndiv], i) => {
          if (connIndiv.status.toLowerCase() == "accepted") {
            totalActive++;
          }
          if (connIndiv.status.toLowerCase() == "declined") {
            totalInactive++;
          }
          if (connIndiv.status.toLowerCase() == "pending") {
            totalPending++;
          }
          if (connIndiv.status.toLowerCase() == "blocked") {
            totalBlocked++;
            totalBlocked = props.hideCountBlocked ? (totalBlocked - props.hideCountBlocked) : totalBlocked;
          }
          totalStatus =
            totalActive + totalInactive + totalPending + totalBlocked;
        });
    }
  }

  const getPendingOrBlockedCount = () => {
    if (isJobSeeker)
      return totalBlocked;
    else 
      return totalPending;
  };

  // update all company that has isBlokedLink connections
  // if (entityState.entityReducer) {
  //   for (var compId in entityState.entityReducer.companies) {
  //     const [company, connections] = getEntityCompanyConnection(entityState.entityReducer,
  //       compId)
      
  //     const isBlockedLink = Object.entries(connections).filter(([key, val]) => 
  //     val.isBlockedLink);
  //     if (isBlockedLink && isBlockedLink.length > 0 && 
  //         company[compId].count.totalBlocked > 0 &&
  //         company[compId].count.totalBlocked >= isBlockedLink.length) {
  //       company[compId].count.totalBlocked -= isBlockedLink.length;
  //       company[compId].connections = 
  //         company[compId].connections.filter(conn => 
  //         isBlockedLink.filter(([key, val]) => key == conn).length == 0 );
  //     }
  //   }
  // }

  return !isMobile ? (
    <Box>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs"
        >
          <Tab
            className={css.TabStyle}
            label="All Connections"
            {...a11yProps(0)}
          />
          <Tab className={css.TabStyle} label="Connected" {...a11yProps(1)} />
          {/* <Tab
            className={css.TabStyle}
            label="Disconnected"
            {...a11yProps(2)}
          /> */}
          {
            !isJobSeeker && <Tab
              className={css.TabStyle}
              label="Pending"
              {...a11yProps(3)}
            />
          }
          {/* <Tab
            className={css.TabStyle}
            label="Blocked"
            {...a11yProps(4)}
          /> */}
        </Tabs>
      </Box>
      <SwipeableViews enableMouseEvents index={value} onChangeIndex={handleChange}>
      <TabPanel value={value} index={0}>
        {TabContent(entity ? entity.type : "", 0, totalStatus)}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {TabContent(entity ? entity.type : "", 1, totalActive)}
      </TabPanel>
      <TabPanel value={value} index={2}>
        {TabContent(entity ? entity.type : "", 2, totalInactive)}
      </TabPanel>
      <TabPanel value={value} index={3}>
        {TabContent(entity ? entity.type : "", 3, getPendingOrBlockedCount())}
      </TabPanel>
      {/* <TabPanel value={value} index={4}>
        {TabContent(entity ? entity.type : "", 4, totalBlocked)}
      </TabPanel> */}
      {/* {PendingTabsPanel()}
      {BlockedTabsPanel()} */}
      </SwipeableViews>
    </Box>
  ) : (
    <Box>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons={true}
          // allowScrollButtonsMobile
        >
          <Tab
            className={css.TabStyle}
            label= {"All" + ` (${totalStatus})`}
            {...a11yProps(0)}
            sx={{minWidth: 10}}
          />
          <Tab
            className={css.TabStyle}
            label={"Connected" + ` (${totalActive})`}
            {...a11yProps(1)}
          />
          <Tab
            className={css.TabStyle}
            label={"Disconnected" + ` (${totalInactive})`}
            {...a11yProps(2)}
          />
          {
            !isJobSeeker && <Tab
              className={css.TabStyle}
              label={"Pending" + ` (${totalPending})`}
              {...a11yProps(3)}
            />
          }
          
          <Tab
            className={css.TabStyle}
            label={"Blocked" + ` (${totalBlocked})`}
            {...a11yProps(4)}
          />
          {/* {PendingTab()}
          {BlockedTab()} */}
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        {TabContent(entity ? entity.type : "", 0, totalStatus)}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {TabContent(entity ? entity.type : "", 1, totalActive)}
      </TabPanel>
      <TabPanel value={value} index={2}>
        {TabContent(entity ? entity.type : "", 2, totalInactive)}
      </TabPanel>
      {
        !isJobSeeker && <TabPanel value={value} index={3}>
          {TabContent(entity ? entity.type : "", 3, totalPending)}
        </TabPanel>
      }
      <TabPanel value={value} index={isJobSeeker? 3: 4}>
        {TabContent(entity ? entity.type : "", isJobSeeker? 3: 4, totalBlocked)}
      </TabPanel>
    </Box>
  );
}
