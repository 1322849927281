import { Dispatch } from "redux";

import {
    UPDATE_DEVICE,
    DeviceDispatchTypes
} from "./DeviceActionTypes";

export const UpdateDevice = () => async (dispatch: Dispatch<DeviceDispatchTypes>) => {
    dispatch({
        type: UPDATE_DEVICE,
    });
};