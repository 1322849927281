export const DSSETTINGS_LOADING = "DSSETTINGS_LOADING";
export const DSSETTINGS_FAIL = "DSSETTINGS_FAIL";
export const DSSETTINGS_SUCCESS = "DSSETTINGS_SUCCESS";
export const DSSETTINGS_UPDATE = "DSSETTINGS_UPDATE";

export type DsSetting = {
  entityId: string;
  id?: string;
  connectionId: string;
  date: string;
  showEmail: boolean;
  showAddress: boolean;
  showPhone: boolean;
  showResume: boolean;
  showToRecruiters: boolean;
  showProfile: boolean;
  showReferences: boolean;
  showBackgroundCheck: boolean;
};

export interface DsSettingsLoading {
  type: typeof DSSETTINGS_LOADING;
}

export interface DsSettingsFail {
  type: typeof DSSETTINGS_FAIL;
}

export interface DsSettingsSuccess {
  type: typeof DSSETTINGS_SUCCESS;
  payload: DsSetting;
}

export interface DsSettingsUpdate {
  type: typeof DSSETTINGS_UPDATE;
  payload: DsSetting;
}

export type DsSettingsDispatchTypes =
  | DsSettingsLoading
  | DsSettingsFail
  | DsSettingsSuccess
  | DsSettingsUpdate;
