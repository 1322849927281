import { Dispatch } from "redux";
import axiosInstance from "../helpers/axios";
import {
  DsSettingsDispatchTypes,
  DSSETTINGS_FAIL,
  DSSETTINGS_LOADING,
  DSSETTINGS_SUCCESS,
  DSSETTINGS_UPDATE,
} from "./DsSettingsActionType";

// const entityState = useSelector((state: RootStore) => state.entityRootReducer);

export const GetDsSettings =
  (entityId: string, connectionId: string) =>
  async (dispatch: Dispatch<DsSettingsDispatchTypes>) => {
    try {
      dispatch({
        type: DSSETTINGS_LOADING,
      });

      await axiosInstance
        .get(
          `/conn/network/dssettings/fetch?entityId=${entityId}&connectionId=${connectionId}`
        )
        .then((response) => {
          dispatch({
            type: DSSETTINGS_SUCCESS,
            payload: response.data.data.dsSettings,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (e) {
      dispatch({
        type: DSSETTINGS_FAIL,
      });
    }
  };

export const UpdateDsSettings =
  (payloads: object) => async (dispatch: Dispatch<DsSettingsDispatchTypes>) => {
    try {
      dispatch({
        type: DSSETTINGS_LOADING,
      });

      await axiosInstance
        .put(`/conn/network/dssettings/update/`, payloads)
        .then((response) => {
          dispatch({
            type: DSSETTINGS_UPDATE,
            payload: response.data.data.dsSettings,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (e) {
      console.log(e);
      dispatch({
        type: DSSETTINGS_FAIL,
      });
    }
  };

export const UpdateIsBlocked =
  (payloads: object) => async (dispatch: Dispatch<DsSettingsDispatchTypes>) => {
    try {
      dispatch({
        type: DSSETTINGS_LOADING,
      });

      await axiosInstance
        .put(`/conn/network/dssettings/block`, payloads)
        .then((response) => {
          // dispatch({
          //   type: DSSETTINGS_UPDATE,
          //   payload: response.data.data.dsSettings,
          // });
          return response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (e) {
      console.log(e);
      dispatch({
        type: DSSETTINGS_FAIL,
      });
    }
  };

export const SendEventsByMessage =
  (payloads: object) => async (dispatch: Dispatch<DsSettingsDispatchTypes>) => {
    try {
      dispatch({
        type: DSSETTINGS_LOADING,
      });

      await axiosInstance
        .post(`event/network/api/sendEventsByMessage`, payloads)
        .then((response) => {
          // return response.data;
          console.log(response.data)
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (e) {
      console.log(e);
      dispatch({
        type: DSSETTINGS_FAIL,
      });
    }
  };
