 import { Companies, Connections } from "../action/EntityActionType";

export const getCompanyConnection = (companyInfo, companyId) => {
    const company = {};
    company[companyId] = companyInfo.company[companyId];
    const connections = {};
    for (var index in company[companyId].connections) {
        const connId = company[companyId].connections[index];
        connections[connId] = companyInfo.connections[connId];
    }

    return [company, connections];
}

export const getEntityCompanyConnection = (entity, companyId) => {
    const company = {};
    company[companyId] = entity?.companies[companyId];
    const connections = {};
    for (var index in company[companyId].connections) {
        const connId = company[companyId].connections[index];
        connections[connId] = entity.connections[connId];
    }

    return [company, connections];
}

export const handleStatusChange = (
    stat: string,
    isByCompany: boolean,
    company?, 
    connections?, 
    recruiter?,
    companyId?) => {
    
    console.log("handleStatusChange - {stat: " + stat +"} {isByCompany: " +
        isByCompany+"}");
    let isShow = false;
    let connStatus = "";
    let isBlocked = false;
    if (stat == "Connect") {
        isShow = true;
        connStatus = "accepted";
    } else {
        connStatus = "declined";
        if (stat == "Block") {
            connStatus = "blocked";
            isBlocked = true;
        }
    } 

    if (!isByCompany && recruiter) {
        for (var key in recruiter) {
            recruiter[key].isConnected = isShow;
            recruiter[key].isBlocked = isBlocked;
            recruiter[key].contactInfoEnabled = isShow;
            recruiter[key].profileAccessEnabled = false;
            recruiter[key].dsSettingsSelf.showProfile = false;
            recruiter[key].dsSettingsSelf.showResume = false;
            recruiter[key].dsSettingsSelf.showEmail = isShow;
            recruiter[key].dsSettingsSelf.showAddress = false;
            recruiter[key].dsSettingsSelf.showPhone = false;
            recruiter[key].status = connStatus;
            break;
        }
    }

    if (isByCompany && company && companyId) {
        company[companyId].isConnected = isShow;
        company[companyId].isBlocked = isBlocked;
        company[companyId].contactInfoEnabled = isShow;

        if (stat == "Connect") {
            // console.log("CONNECT COMPANY");
            company[companyId].dsSettingsSelf.showEmail = true;
            company[companyId].dsSettingsSelf.showAddress = false;
            company[companyId].dsSettingsSelf.showPhone = false;
        } else {
            // console.log("DISCONNECT COMPANY");
            company[companyId].profileAccessEnabled = false;
        }

        for (var key in connections) {
            connections[key].isConnected = isShow;
            connections[key].status = connStatus;
            connections[key].contactInfoEnabled = isShow;
            connections[key].dsSettingsSelf.showProfile = false;
            connections[key].dsSettingsSelf.showResume = false;
            connections[key].dsSettingsSelf.showEmail = isShow;
            connections[key].dsSettingsSelf.showAddress = false;
            connections[key].dsSettingsSelf.showPhone = false;

            connections[key].isBlocked = isBlocked;
            connections[key].contactInfoEnabled = isShow;
        }
    }
};

export const handleConnectionUpdate = (companyId, company, connections) => {
    console.log("handleConnectionUpdate - companyId: " + companyId);

    if (company) {
        company[companyId].count.totalActive = 0;
        company[companyId].count.totalInactive = 0;
        company[companyId].count.totalPending = 0;
        company[companyId].count.totalBlocked = 0;

        for (var key in connections) {
            if (connections[key].status.toLowerCase() == "accepted" &&
                connections[key].isConnected)
                company[companyId].count.totalActive += 1;
            else if (connections[key].status.toLowerCase() == "declined" &&
                !connections[key].isConnected)
                company[companyId].count.totalInactive += 1;
            else if (connections[key].status.toLowerCase() == "pending" &&
                !connections[key].isConnected)
                company[companyId].count.totalPending += 1;
            if (connections[key].status.toLowerCase() == "blocked" &&
                connections[key].isBlocked && !connections[key].isConnected)
                company[companyId].count.totalBlocked += 1;
        }

        
        if (company[companyId].count.totalActive > 0) {
            company[companyId].isConnected = true;
            company[companyId].status = "accepted";
        }

        if (company[companyId].count.totalInactive ==
                company[companyId].connections.length || 
                company[companyId].count.totalActive == 0) {
            company[companyId].isConnected = false;
            company[companyId].status = "declined";
            company[companyId].isBlocked = false;
        }
        
        if (company[companyId].count.totalBlocked ==
                company[companyId].connections.length) {
            company[companyId].isConnected = false;
            company[companyId].isBlocked = true;
            company[companyId].status = "blocked";
        }
    }
};

export const formatConnectionDsSettings = (isShow, connectionId, connection) => {
    console.log("formatConnectionDsSettings - connectionId: " + connectionId);
    
    if (isShow) {
        connection[connectionId].status = "accepted";
    } else {
        connection[connectionId].status = "declined";
    }

    connection[connectionId].isConnected = isShow;
    connection[connectionId].profileAccessEnabled = false;
    connection[connectionId].contactInfoEnabled = isShow;
    connection[connectionId].isBlocked = false;

    connection[connectionId].dsSettingsSelf.showEmail = isShow;
    connection[connectionId].dsSettingsSelf.showProfile = false;
    connection[connectionId].dsSettingsSelf.showResume = false;
    connection[connectionId].dsSettingsSelf.showAddress = false;
    connection[connectionId].dsSettingsSelf.showPhone = false;

    connection[connectionId].dsSettingsLink.showEmail = isShow;
    connection[connectionId].dsSettingsLink.showProfile = false;
    connection[connectionId].dsSettingsLink.showResume = false;
    connection[connectionId].dsSettingsLink.showAddress = false;
    connection[connectionId].dsSettingsLink.showPhone = false;
}
