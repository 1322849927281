import { Box, Typography } from "@mui/material";
import { useHealthCheck } from "@webscopeio/react-health-check";

const HealthCheck = () => {
  const { available } = useHealthCheck("conn-middleware");

  if (available) {
    return (
      <Box sx={{ p: 2 }}>
        <Typography>Connections App is healthy!!!</Typography>
      </Box>
    );
  }
  return (
    <Box sx={{ p: 2 }}>
      <Typography>Connections App not found!!!</Typography>
    </Box>
  );
};

export default HealthCheck;
