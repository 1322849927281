import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  connHeader: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "22px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    color: "#000000",
    marginTop: "5px",
  },
  TabStyle: {
    fontFamily: "Poppins",
    textTransform: "none",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
    fontStyle: "normal",
  },
  TabStyleIndividual: {
    fontFamily: "Poppins",
    textTransform: "none",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "22px",
    color: "#3F4254",
  },
  enabledLink: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "22px",
    display: "flex",
    alignItems: "center",
    color: "#1E6DBB",
  },
  disabledLink: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "22px",
    display: "flex",
    alignItems: "center",
    color: "#6C757D",
    pointerEvents: "none",
  },
  search: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "22px",
    color: "#3F4254",
    marginRight: "10px",
  },
  companyBox: {
    /* Auto layout */
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    padding: "12px",

    position: "static",
    width: "100%",
    left: "0px",
    top: "0px",

    /* Utility/White - #FFFFFF */
    background: "#FFFFFF",

    /* Fill/Light - #F3F6F9 */
    border: "1px solid #E4E6EF",
    boxSizing: "border-box",
    borderBottom: "none",

    /* Inside auto layout */
    flex: "none",
    order: 0,
    alignSelf: "stretch",
    flexGrow: 0,
    margin: "0px 0px",
  },
  paperBox: {
    /* Auto layout */
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    padding: "12px",

    position: "static",
    width: "100%",
    left: "0px",
    top: "0px",

    /* Utility/White - #FFFFFF */
    // background: "#FFFFFF",

    /* Fill/Light - #F3F6F9 */
    border: "1px solid #E4E6EF",
    boxSizing: "border-box",
    // borderBottom: "none",

    /* Inside auto layout */
    flex: "none",
    order: 0,
    alignSelf: "stretch",
    flexGrow: 0,
    marginTop: "10px",
  },
  connectionEstablishedBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    padding: "8px 12px",

    border: "1px solid #E4E6EF",
    borderTop: "0",
    boxSizing: "border-box",
  },
  primaryName: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "22px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    color: "#212529",
    flex: "none",
    order: "0",
    flexGrow: "0",
    // margin: "8px 0px",
  },
  accordionHeader: {
    /* Auto layout */
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "0px 12px",

    position: "static",
    width: "100%",
    /*height: 40px,*/
    left: "0px",
    top: "84px",

    /* Blue/10 - #EAF3FC */
    background: "#EAF3FC",

    /* Inside auto layout */
    flex: "none",
    order: 1,
    alignSelf: "stretch",
    flexGrow: 0,
    margin: "0px 0px",
  },
  accordionIndiv: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "22px",
    color: "#2F86DD",
    flex: "none",
    flexGrow: "0",
    margin: "0px 5px",
  },
  accordionContent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px",
    position: "static",
    left: "0px",
    top: "0px",
    background: "#F3F6F9",
    flex: "none",
    order: "1",
    alignSelf: "stretch",
    flexGrow: "0",
    margin: "0px 0px",
  },
  accordionButton: {
    position: "absolute",
    left: "1.56%",
    right: "1.56%",
    top: "1.56%",
    bottom: "1.56%",
    background: "#2F86DD",
  },
  searchBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    padding: "1px",
    position: "static",
    width: "200px",
    height: "32px",
    left: "0px",
    top: "0px",
    background: "#FFFFFF",
    border: "1px solid #E4E6EF",
    boxSizing: "border-box",
    borderRadius: "4px",
    flex: "none",
    order: "0",
    alignSelf: "stretch",
    flexGrow: "0",
    margin: "8px 0px",
  },
  dotDanger: {
    position: "static",
    width: "12px",
    height: "12px",
    left: "0px",
    top: "17px",
    color: "#F64E60",
    transform: "matrix(1, 0, 0, -1, 0, 0)",
    flex: "none",
    order: "0",
    flexGrow: "0",
    margin: "0px 6px",
  },
  dotSuccess: {
    position: "static",
    width: "12px",
    height: "12px",
    left: "0px",
    top: "17px",
    color: "#1BC5BD",
    transform: "matrix(1, 0, 0, -1, 0, 0)",
    flex: "none",
    order: "0",
    flexGrow: "0",
    margin: "0px 6px",
  },
  dotGrey: {
    position: "static",
    width: "12px",
    height: "12px",
    left: "0px",
    top: "17px",
    color: "grey",
    transform: "matrix(1, 0, 0, -1, 0, 0)",
    flex: "none",
    order: "0",
    flexGrow: "0",
    margin: "0px 6px",
  },
  dotWarning: {
    position: "static",
    width: "12px",
    height: "12px",
    left: "0px",
    top: "17px",
    color: "#FFA800",
    transform: "matrix(1, 0, 0, -1, 0, 0)",
    flex: "none",
    order: "0",
    flexGrow: "0",
    margin: "0px 6px",
  },
  dataSharingSettings: {
    position: "static",
    left: "0px",
    top: "0px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "22px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    color: "#212529",
    flex: "none",
    order: "0",
    flexGrow: "0",
    margin: "0px",
  },
  dataSharingSettingsModal: {
    fontFamily: "'Poppins'",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "20px",
    lineHeight: "26px",
    color: "#343A40",
  },
  normalText400: {
    position: "static",
    left: "0px",
    top: "0px",
    fontFamily: "'Poppins'",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "22px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    color: "#343A40",
    flex: "none",
    order: "0",
    flexGrow: "0",
    margin: "0px",
  },
  normalText500: {
    position: "static",
    left: "0px",
    top: "0px",
    fontFamily: "'Poppins'",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "22px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    color: "#343A40",
    flex: "none",
    order: "0",
    flexGrow: "0",
    margin: "0px",
  },
  activeStatus: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "21px",
    display: "flex",
    alignItems: "center",
    color: "#11807A",
    background: "#89EFEA",
    borderRadius: "4px",
    padding: "1px 8px",
  },
  InActiveStatus: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "21px",
    display: "flex",
    alignItems: "center",
    color: "#6C757D",
    background: "#CED4DA",
    borderRadius: "4px",
    padding: "1px 8px",
  },
  blockedStatus: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "21px",
    display: "flex",
    alignItems: "center",
    color: "#802932",
    background: "#FFE2E5",
    borderRadius: "4px",
    padding: "1px 8px",
  },
  cancelTextBtn: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "26px",
    color: "#2F86DD",
  },
  saveBtn: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "26px",
    color: "#FFFFFF",
    background: "#2F86DD",
    borderRadius: "1000px",
  },
  primaryBtn: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "26px",
    color: "#FFFFFF",
    background: "#2F86DD",
    margin: "0px 5px 0px 0px",
  },
  noIndivConn: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
    height: "115px",
    background: "#F3F6F9",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "22px",
    color: "#B5B5C3",
  },
  resumeDialogContent: {
    overflow: "hidden",
    height: "639px",
  },
  resumeIframe: {
    height: "100%",
    width: "100%",
  },
  profileDialogContent: {
    height: "614px",
    padding: "24px !important",
  },
  profileDialogContainer: {
    gap: "24px",
    overflowY: "auto",
  },
  profileDialogHeader: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "17.5px",
    lineHeight: "24px",
    color: "#343A40",
  },
  profileDialogSubHeader: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "22px",
    color: "#343A40",
  },
  profileDialogDefaultText: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "22px",
    color: "#343A40",
  },
  profileDialog_A: {
    gap: "4px",
  },
  profileDialog_B: {
    gap: "149px",
  },
  profileDialogB_Item: {
    marginTop: "12px",
  },
  profileDialogB_Item_Custom: {
    gap: "16px",
  },
  profileDialogB_Item_Icon_Custom: {
    marginLeft: "3px",
  },
  profileDialogDefaultPadding: {
    padding: "24px 0 ",
  },
  profileDialogIcon: {
    gap: "12px",
  },
  profileDialog_C: {
    paddingTop: "24px",
  },
  profileDialog_C_item: {
    maxWidth: "946px",
  },
  profileDialog_D: {
    marginTop: "12px",
  },
  profileDialog_D_header: {
    marginBottom: "12px",
  },
  profileDialog_D_item: {
    marginLeft: "25px",
    marginBottom: "12px",
  },
  profileDialog_E: {
    marginTop: "12px",
  },
  profileDialog_divider: {
    background: "#E9ECEF",
    height: "1px",
    width: "100%",
  },
  profileDialog_E_Items: {
    background: "#C0DAF5",
    border: "1px solid #2F86DD",
    borderRadius: "1000px",
    padding: "6px 16px",
    marginRight: "  24px",
    fontSize: "14px",
    fontWeight: 400,
    color: "#343A40",
    marginBottom: 8,
    whiteSpace: "nowrap",
  },
  dialogLoadingProgress: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  roundedNamelogo: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    width: "60px",
    height: "60px",
    borderRadius: "50%",
    fontFamily: "Poppins",
    fontSize: "22px",
    fontWeight: "500",
    textTransform: "uppercase",
  },
});
