import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses, TooltipProps } from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { Dimensions } from "react-native";
import UnBlockStatusMenu from "../../component/UnBlockStatusMenu";
import { useStyles } from "../../style/styles";
import ConnectionEstablished from "./ConnectionEstablished";
import Brightness1RoundedIcon from "@mui/icons-material/Brightness1Rounded";
import defaultUser from "../../assets/images/default_user.png";
import { setGlobalState, useGlobalState } from "../../utils/GlobalState";
import IndividualEntityMobile from "./individualEntityMobile";
import { useState } from "react";
import { useSelector } from "react-redux";
import { RootStore } from "../../store/Store";

interface IIndividualBlock {
  backgroundColor: string;
  uniqueKey: string;
  id64: string;
  fullname: string;
  email: string;
  phone?: string;
  positionTitle: string;
  contact: string;
  isConnected: boolean;
  status: string;
  connectionEstablished: string;
  profileAccess: boolean;
  contactInfo: boolean;
  connectionId: any;
  companyId: number;
  entity: string;
  showProfile: boolean;
  showResume: boolean;
}
const IndividualBlock = (props: IIndividualBlock) => {
  const css = useStyles();
  var color = props.backgroundColor == "#FFF4DE" ? "#FFF4DE" : "#FFFFFF";
  const { isMobile, windowWidth } = useSelector(
    (state: RootStore) => state.deviceReducer.deviceData
  );
  // const [personIndividualStatus, setpersonIndividualStatus] = useState(false);
  // const [id64, setid64] = useState(props.id64);
  //   const [fullname, setfullname] = useState(props.fullname);
  //   const [positionTitle, setpositionTitle] = useState(props.positionTitle);
  //   const [email,setemail] = useState(props.email);
  //   const [phone,setphone] = useState(props.phone);
  //   const [isConnected,setisConnected] = useState(props.isConnected);
  //   const [status,setstatus] = useState(props.status);
  //   const [connectionEstablished,setconnectionEstablished] = useState(props.connectionEstablished);
  //   const [profileAccess,setprofileAccess] = useState(props.profileAccess);
  //   const [contactInfo,setcontactInfo] = useState(props.contactInfo);
  //   const [connectionId,setconnectionId] = useState(props.connectionId);
  //   const [companyId,setcompanyId] = useState(props.companyId);
  //   const [entity,setentity] = useState("jobseeker");
  //   const [showProfile,setshowProfile] = useState(props.showProfile);
  //   const [showResume,setshowResume] = useState(props.showResume);

  const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} placement="top" arrow />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: "rgba(0, 0, 0, 0.20)",
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));

  const connEstablished = () => {
    if (props.entity == "jobseeker") {
      return (
        <Typography className={css.normalText400}>
          Connection Established date: {props.connectionEstablished}
        </Typography>
      );
    }
    return <></>;
  };

  

  return (
    !isMobile ?
    <>
      <Box
        display="flex"
        sx={{
          p: 2,
          border:
            props.entity.toLowerCase() == "recruiter"
              ? "1px solid #E4E6EF"
              : "none",
        }}
      >
        <Grid container sx={{ py: "10px", px: "12px" }}>
          <Grid
            item
            xs={1}
            container
            spacing={0}
            direction="column"
            alignItems="left"
            justifyContent="center"
          >
            <Avatar
              alt="Remy Sharp"
              src="/static/images/avatar/1.jpg"
              sx={{ width: "60px", height: "60px" }}
            />
          </Grid>
          <Grid item xs={5}>
            <Stack direction="column" spacing={1}>
              <Typography className={css.primaryName}>
                {props.fullname}
              </Typography>
              {props.positionTitle ? (
                <Typography className={css.normalText400}>
                  {props.positionTitle}
                </Typography>
              ) : (
                ""
              )}
              {/* <Typography className={css.normalText400}>
                {props.contact}
              </Typography>
              {connEstablished()} */}
            </Stack>
          </Grid>
          <Grid
            item
            xs={6}
            container
            spacing={0}
            direction="column"
            alignItems="right"
            justifyContent="center"
          >
            <Grid
              item
              xs={2}
              container
              spacing={0}
              direction="row"
              alignItems="right"
              justifyContent="right"
            >
              <Stack
                direction="column"
                spacing={1}
                alignItems="right"
                justifyContent="center"
                sx={{ pr: "2px" }}
              >
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="right"
                  justifyContent="center"
                >
                  <LightTooltip title={(props.entity.toLowerCase() == "jobseeker") ? 
                    "Recruiter " + props.fullname + " can no longer communicate with you via JobTarget, nor can they see your Profile." : 
                    "JobSeeker " + props.fullname + " can no longer communicate with you via JobTarget, nor can they see your Profile."}>
                    <InfoOutlinedIcon sx={{ color: "#F98C97" }} />
                  </LightTooltip>
                  <Typography className={css.blockedStatus}>Blocked</Typography>
                </Stack>
              </Stack>

              <Stack
                direction="column"
                spacing={1}
                alignItems="right"
                justifyContent="center"
                // sx={{ pr: "1px" }}
              >
                <UnBlockStatusMenu
                  isConnected={props.isConnected}
                  category="individual"
                  connectionId={props.connectionId}
                  isButton={false}
                />
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <ConnectionEstablished
        entityType={props.entity}
        connectionEstablished={props.connectionEstablished}
      />
    </>
    :
    <Stack
        direction="column"
        sx={{
          width: (windowWidth - 20) / 2,
          padding: 0.5,
        }}
        onClick={(event) => {
          if (isMobile) {
            setGlobalState('personIndividualStatus', true);
            // setid64(props.id64);
            // setfullname(props.fullname);
            // setpositionTitle(props.positionTitle);
            // setemail(props.email);
            // setphone(props.phone ? props.phone : "");
            // setisConnected(props.isConnected);
            // setstatus(props.status);
            // setconnectionEstablished(props.connectionEstablished);
            // setprofileAccess(props.profileAccess);
            // setcontactInfo(props.contactInfo);
            // setconnectionId(props.connectionId);
            // setcompanyId(props.companyId);
            // setentity("jobseeker");
            // setshowProfile(props.showProfile);
            // setshowResume(props.showResume);
            console.log("BLOCCCCCKKKKKK: " + props.id64);

            return <IndividualEntityMobile
            uniqueKey={props.uniqueKey}
            id64={props.id64}
            fullname={props.fullname}
            positionTitle={props.positionTitle}
            email={props.email}
            phone={props.phone}
            isConnected={props.isConnected}
            status={props.status}
            connectionEstablished={props.connectionEstablished}
            profileAccess={props.profileAccess}
            contactInfo={props.contactInfo}
            connectionId={props.connectionId}
            companyId={props.companyId.toString()}
            entity="jobseeker"
            showProfile={props.showProfile}
            showResume={props.showResume}
          ></IndividualEntityMobile>
          }
        }}
      >
        <Box
        sx={{
          border: "1px solid #E4E6EF",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "flex-start",
          padding: "10px",
          backgroundColor: "#F8F9FA",
          borderRadius: '24px'
        }}>
          <Stack
            direction="column"
            spacing={2}
            alignItems="center"
            // justifyContent="space-evenly"
            sx={{ width: "100%", height: "200px", position: "relative" }}
          >
            <Avatar
              src={defaultUser}
              sx={{
                width: "60px",
                height: "60px",
                bgcolor: "primary.main",
              }}
            />
            <Typography
              className={css.primaryName}
              sx={{
                width: (windowWidth - 50) / 2 + "px",
                justifyContent: "center",
              }}
            >
              {props.fullname}
            </Typography>

            <Typography
              // className={
              //   props.isConnected ? css.activeStatus : css.InActiveStatus
              // }
              sx={{ bottom: "10px", my: "20px", position: "absolute" }}
            >
              <Brightness1RoundedIcon
                className={props.status.toLowerCase() == "blocked" ? css.dotDanger : props.status.toLowerCase() == "accepted" ? css.dotSuccess : css.dotGrey}
              />
              {props.status.toLowerCase() == "blocked" ? "Blocked" : props.status.toLowerCase() == "accepted" ? "Connected" : "Disconnected"}
            </Typography>
          </Stack>
        </Box>
      </Stack>
  );
};

export default IndividualBlock;
