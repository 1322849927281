import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import defaultUser from "../../assets/images/default_user.png";
import { useStyles } from "../../style/styles";
import ProfileModal from "../ProfileModal";
import ResumeModal from "../ResumeModal";

interface IStatus {
  fullname: string;
  positionTitle: string;
  email: string;
  phone?: string;
  connectionEstablished: string;
  entity: string;
  connectionId: string;
  isConnected: boolean;
  profileAccessEnabled: boolean;
  showProfile: boolean;
  showResume: boolean;
}
const BasicInfoSection = (props: IStatus) => {
  const css = useStyles();

  const ConnectionEstablished = () => {
    if (props.entity.toLowerCase() == "jobseeker") {
      return (
        <Grid item xs={5}>
          <Stack direction="column" spacing={1}>
            <Typography className={css.primaryName}>
              {props.fullname}
            </Typography>
            {props.positionTitle ? (
              <Typography className={css.normalText400}>
                {props.positionTitle}
              </Typography>
            ) : (
              ""
            )}
            <Typography className={css.normalText400}>
              {props.email} {props.phone ? " | " + props.phone : ""}
            </Typography>
            <Typography className={css.normalText400}>
              Connection Established date:{" "}
              {props.connectionEstablished.slice(
                0,
                props.connectionEstablished.length - 3
              ) +
                props.connectionEstablished.slice(
                  props.connectionEstablished.length - 1
                )}
            </Typography>
          </Stack>
        </Grid>
      );
    }
    return (
      <Grid
        item
        xs={5}
        container
        direction="column"
        spacing={1}
        alignItems="left"
        justifyContent="center"
      >
        {/* <Stack direction="column" spacing={1}> */}
        <Grid
          container
          direction="row"
          alignItems="left"
          justifyContent="left"
          sx={{ pb: 1 }}
        >
          <Typography sx={{ pr: 1 }} className={css.primaryName}>
            {props.fullname}
          </Typography>
          <ProfileModal
            connectionId={props.connectionId}
            isConnected={props.isConnected}
            profileAccessEnabled={props.profileAccessEnabled}
            showProfile={props.showProfile}
          />
          <Typography sx={{ px: 1 }} className={css.normalText400}>
            {" "}
            |{" "}
          </Typography>
          <ResumeModal
            connectionId={props.connectionId}
            isConnected={props.isConnected}
            profileAccessEnabled={props.profileAccessEnabled}
            showResume={props.showResume}
          />
        </Grid>
        {props.positionTitle ? (
          <Typography className={css.normalText400}>
            {props.positionTitle}
          </Typography>
        ) : (
          ""
        )}
        <Typography className={css.normalText400}>
          {props.email} {props.phone ? " | " + props.phone : ""}
        </Typography>
        {/* </Stack> */}
      </Grid>
    );
  };

  return (
    <>
      <Grid
        item
        xs={1}
        container
        spacing={0}
        direction="column"
        alignItems="left"
        justifyContent="center"
      >
        <Avatar
          src={defaultUser}
          sx={{ width: "60px", height: "60px", bgcolor: "primary.main" }}
        />
      </Grid>
      {ConnectionEstablished()}
    </>
  );
};

export default BasicInfoSection;
