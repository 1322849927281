import {
  DsSetting,
  DsSettingsDispatchTypes,
  DSSETTINGS_FAIL,
  DSSETTINGS_LOADING,
  DSSETTINGS_SUCCESS,
} from "../action/DsSettingsActionType";

export interface DefaultStateI {
  loading: boolean;
  dsSettingsReducer?: DsSetting;
}

export const defaultState: DefaultStateI = {
  loading: false,
};

const dsSettingsReducerAction = (
  state: DefaultStateI = defaultState,
  action: DsSettingsDispatchTypes
): DefaultStateI => {
  switch (action.type) {
    case DSSETTINGS_FAIL:
      return {
        loading: false,
      };
    case DSSETTINGS_LOADING:
      return {
        loading: true,
      };
    case DSSETTINGS_SUCCESS:
      return {
        loading: false,
        dsSettingsReducer: action.payload,
      };
    default:
      return state;
  }
};

export default dsSettingsReducerAction;
