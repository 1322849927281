export const UPDATE_DEVICE = "UPDATE_DEVICE";

export type DeviceData = {
    isMobile: boolean;
    isPortrait: boolean;
    windowWidth: number;
};

export interface UpdateDevice {
    type: typeof UPDATE_DEVICE;
}

export type DeviceDispatchTypes =
  | UpdateDevice;
