import {
  Avatar,
  Dialog,
  DialogTitle,
  IconButton,
  Popover,
  Button,
  Typography,
  DialogContent,
  DialogActions,
  Divider,
  Menu,
  MenuItem,
} from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { useStyles } from "../../style/styles";
import BasicInfoSection from "./BasicInfoSection";
import ConnectionEstablished from "./ConnectionEstablished";
import DataSharingSection from "./DataSharingSection";
import StatusSection from "./StatusSection";
import headerImage from "../../assets/images/individual_company_top.png";
import { Dimensions } from "react-native";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useContext, useState } from "react";
import defaultUser from "../../assets/images/default_user.png";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import React from "react";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import Brightness1RoundedIcon from "@mui/icons-material/Brightness1Rounded";
import DataSharingModal from "../DataSharingModal";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../store/Store";
import { EntitiesStoreContext } from "../../store/EntitiesStore";
import { UpdateEntity } from "../../action/EntityAction";
import { ENTITY_FAIL, ENTITY_SUCCESS } from "../../action/EntityActionType";
import { SendEventsByMessage } from "../../action/DsSettingsAction";
import { setGlobalState } from "../../utils/GlobalState";
interface IIndividuals {
  uniqueKey: string;
  id64: string;
  fullname: string;
  positionTitle: string;
  email: string;
  phone?: string;
  connectionEstablished: string;
  profileAccess: boolean;
  contactInfo: boolean;
  isConnected: boolean;
  connectionId: string;
  status: string;
  companyId: string;
  entity: string;
  showProfile: boolean;
  showResume: boolean;
}
const IndividualEntityMobile = (props: IIndividuals) => {
  const css = useStyles();
  const [showBlock, setShowBlock] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [showConnectStatus, setShowConnectStatus] = useState(false);
  const [isOpen, setOpen] = React.useState(false);
  console.log("ENTITY MOBILE ID64: " + props.id64);

  const entityState = useSelector(
    (state: RootStore) => state.entityRootReducer
  );
  const { windowWidth } = useSelector(
    (state: RootStore) => state.deviceReducer.deviceData
  );
  const entityStore = useContext(EntitiesStoreContext);
  const dispatch = useDispatch();

  const handleChange = (stat: string) => {
    let isShow = false;
    let recruiterId = "";
    let recruiterId64 = "";
    let jobseekerId64 = "";
    let profileId = "";
    try {
      if (stat == "Block") {
        setOpen(true);
      } else {
        if (stat == "Connect") {
          isShow = true;
        }

        if (props.connectionId && entityState.entityReducer) {
          let connId = parseInt(props.connectionId);
          if (entityStore.entityType == "jobseeker") {
            recruiterId = entityState.entityReducer.connections[connId].id;
            recruiterId64 = entityState.entityReducer.connections[connId].id64;
            jobseekerId64 = entityState.entityReducer.id64;
            profileId =
              entityState.entityReducer.emails[entityState.entityReducer.email]
                .profileId[0];
          } else if (entityStore.entityType == "recruiter") {
            recruiterId = entityState.entityReducer.id;
            recruiterId64 = entityState.entityReducer.id64;
            jobseekerId64 = entityState.entityReducer.connections[connId].id64;
          }
        }

        const eventsPayload = {
          action: stat.toLowerCase(), // connect/disconnect
          source_entity: recruiterId, // recruiter_id from dynamoDB
          job_seeker_id_64: jobseekerId64, // jobseeker_id from 64recs
          profile_id: profileId, // Empty if recruiter
          isConnected: isShow.toString(), // true or false (true/false is string as requirement of the endpoint)
          isBlocked: "false", // set to false since this condition is for active/inactive (true/false is string as requirement of the endpoint)
          status: isShow ? "accepted" : "declined", // accepted, declined, pending, blocked (this condition is for active/inactive only)
          isPending: "false", // set to false since this condition is for active/inactive (true/false is string as requirement of the endpoint)
          source: recruiterId64, // recruiter_id from 64recs
          caller: "connection", // "connection" for Connections App
          target: jobseekerId64, // jobseeker_id from 64recs
        };

        console.log(eventsPayload);

        dispatch(SendEventsByMessage(eventsPayload));

        // This will update the company to connected
        // once the individual connection is set to connected
        if (isShow && parseInt(props.companyId) != 0) {
          const payloads = {
            id: entityStore.entityId,
            connectionId: props.companyId,
            connect: isShow,
          };
          dispatch(UpdateEntity(payloads));
        }

        // Refresh the entire entity state
        if (entityState.entityReducer) {
          const entity = entityState.entityReducer;

          for (var key in entity.connections) {
            if (
              entity.connections.hasOwnProperty(key) &&
              key == props.connectionId
            ) {
              entity.connections[key].isConnected = isShow;
              entity.connections[key].profileAccessEnabled = false;
              entity.connections[key].contactInfoEnabled = isShow;
              entity.connections[key].dsSettingsSelf.showProfile = false;
              entity.connections[key].dsSettingsSelf.showResume = false;
              entity.connections[key].dsSettingsSelf.showEmail = isShow;
              entity.connections[key].dsSettingsSelf.showAddress = false;
              entity.connections[key].dsSettingsSelf.showPhone = false;

              console.log("EMAIL");
              console.log(entity.connections[key].dsSettingsSelf.showEmail);

              // Company Level
              for (var compKey in entity.companies) {
                const compID = entity.connections[key].companyId;

                if (compKey == compID) {
                  if (stat == "Connect") {
                    entity.connections[key].status = "accepted";
                    entity.companies[compKey].isConnected = true;
                    entity.companies[compKey].dsSettingsSelf.showEmail = true;
                    entity.companies[compKey].contactInfoEnabled = true;
                    // entity.companies[compKey].dsSettingsSelf.showEmail = true;
                    entity.companies[compKey].count.totalActive++;
                    entity.companies[compKey].count.totalInactive--;
                  } else if (stat == "Disconnect") {
                    entity.connections[key].status = "declined";
                    // entity.companies[compKey].isConnected = false;
                    entity.companies[compKey].count.totalActive--;
                    entity.companies[compKey].count.totalInactive++;
                  }
                }
              }
            }
          }
          // This will set the state
          dispatch({
            type: ENTITY_SUCCESS,
            payload: entity,
          });
          console.log("STATUS CONNECTION: " + stat);
        }
      }
    } catch (e) {
      dispatch(ENTITY_FAIL);
    }
    setAnchorEl(null);
  };
  return (
    <div>
      <Dialog
        fullScreen
        aria-labelledby="customized-dialog-title"
        open={true}
        scroll="body"
      >
        <DialogTitle id="customized-dialog-title" sx={{ ml: -3, mt: -3 }}>
          <Box
            sx={{
              background: `url('${headerImage}')`,
              // backgroundSize: `${windowWidth}+px, ${windowHeight}*0.4+px`,
              backgroundSize: `100% 150%`,
              height: 209,
              width: windowWidth,
            }}
          >
            <IconButton
              onClick={() => setGlobalState('personIndividualStatus', false)}
              sx={{
                position: "absolute",
                left: 15,
                top: 15,
                background: "black",
                width: "30px",
                height: "30px",
                opacity: 0.5,
              }}
            >
              <ArrowBackIosIcon
                sx={{
                  color: "white",
                  width: "25px",
                  height: "25px",
                  pl: "10px",
                  opacity: 2,
                }}
              ></ArrowBackIosIcon>
            </IconButton>
            <IconButton
              sx={{
                position: "absolute",
                right: 15,
                top: 15,
                background: "black",
                width: "30px",
                height: "30px",
                opacity: 0.5,
              }}
              onClick={(event) => {
                setAnchorEl(event.currentTarget);
                setShowBlock(true);
              }}
            >
              <MoreHorizIcon
                sx={{
                  color: "white",
                  width: "25px",
                  height: "25px",
                  // pl: "5px",
                  opacity: 2,
                }}
              ></MoreHorizIcon>
            </IconButton>
            {showBlock && (
              <Menu
                open={true}
                onClose={() => {
                  setShowBlock(false);
                }}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
              >
                <MenuItem onClick={() => handleChange("Block")}>
                  <Typography sx={{ m: 1 }}>Block {props.fullname}</Typography>
                </MenuItem>
                {/* <MenuItem>
                  <Typography sx={{ m: 1 }}>Mute {props.fullname}</Typography>
                </MenuItem> */}
              </Menu>
            )}
          </Box>
          <Typography className={css.dataSharingSettingsModal}>
            <Avatar
              src={defaultUser}
              sx={{
                width: "60px",
                height: "60px",
                position: "relative",
                left: 15,
                top: -30,
              }}
            />
            <Grid container direction="column" sx={{ pl: "15px" }}>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                sx={{ position: "relative", top: -20 }}
              >
                {props.isConnected ? (
                  <>
                    {/* <MailOutlineIcon
                      sx={{
                        border: 1,
                        borderRadius: "100%",
                        width: "30px",
                        mr: 1,
                        mt: "7px",
                      }}
                    ></MailOutlineIcon>
                    <NotificationsNoneIcon
                      sx={{
                        border: 1,
                        borderRadius: "100%",
                        width: "30px",
                        mr: 1,
                        mt: "7px",
                      }}
                    ></NotificationsNoneIcon> */}
                    <Button
                      variant="outlined"
                      sx={{
                        color: "#2F86DD",
                        borderColor: "#2F86DD",
                        borderRadius: "25px",
                        textTransform: "none",
                      }}
                      onClick={(event) => {
                        setShowConnectStatus(true);
                        setAnchorEl(event.currentTarget);
                      }}
                    >
                      Connected
                    </Button>
                    {showConnectStatus && (
                      <Popover
                        open={true}
                        onClose={() => {
                          setShowConnectStatus(false);
                        }}
                        anchorEl={anchorEl}
                        sx={{
                          alignItems: "center",
                        }}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                      >
                        <Typography
                          sx={{ m: 2, color: "red" }}
                          onClick={() => {
                            handleChange("Disconnect");
                          }}
                        >
                          Disconnect {props.fullname}
                        </Typography>
                      </Popover>
                    )}
                  </>
                ) : (
                  <Button
                    variant="contained"
                    sx={{
                      color: "white",
                      borderColor: "black",
                      borderRadius: "25px",
                      textTransform: "none",
                    }}
                    onClick={() => {
                      handleChange("Connect");
                      setShowConnectStatus(false);
                    }}
                  >
                    Connect
                  </Button>
                )}
              </Grid>
              <Typography className={css.primaryName} sx={{ pb: "5px" }}>
                {props.fullname}
              </Typography>
              <Typography sx={{ fontSize: 15, fontWeight: "light", pb: "5px" }}>
                {props.fullname.replace(/,(\s+)?$/, "")}
              </Typography>
              {/* <Typography
                sx={{
                  fontSize: 15,
                  fontWeight: "light",
                  pb: "5px",
                }}
              >
                <CalendarTodayIcon
                  sx={{ width: 15, height: 15 }}
                ></CalendarTodayIcon>
                {"  Connected: " +
                  new Date(props.connectionEstablished).toLocaleString(
                    "default",
                    { month: "long" }
                  ) +
                  " " +
                  new Date(props.connectionEstablished).getFullYear()}
              </Typography> */}
              {props.isConnected ? (
                <Typography
                  sx={{
                    fontSize: 15,
                    fontWeight: "light",
                    pb: "5px",
                  }}
                >
                  <CalendarTodayIcon
                    sx={{ width: 15, height: 15 }}
                  ></CalendarTodayIcon>
                  {"  Connected: " +
                    new Date(props.connectionEstablished).toLocaleString(
                      "default",
                      { month: "long" }
                    ) +
                    " " +
                    new Date(props.connectionEstablished).getFullYear()}
                </Typography>
              ) : (
                <Typography
                  sx={{
                    fontSize: 15,
                    fontWeight: "light",
                    pb: "5px",
                  }}
                >
                  Status:
                  <Brightness1RoundedIcon className={css.dotGrey} />
                  Disconnected
                </Typography>
              )}
            </Grid>
          </Typography>
        </DialogTitle>
        <DialogContent dividers sx={{ ml: "-10px" }}>
          <Grid
            container
            // spacing={{ xs: 2, md: 3 }}
            // columns={{ xs: 12, sm: 8, md: 12 }}
          >
            <Grid
              item
              // xs={10}
              container
              spacing={0}
              direction="row"
              alignItems="left"
              justifyContent="left"
            >
              <Stack
                direction="column"
                spacing={1}
                width="100%"
                alignItems="start"
              >
                <Typography className={css.dataSharingSettings}>
                  Contact Info
                </Typography>
                <Typography
                  sx={{ fontSize: 15, fontWeight: "light", pb: "5px" }}
                >
                  {props.email}
                </Typography>
                <Typography
                  sx={{ fontSize: 15, fontWeight: "light", pb: "5px" }}
                >
                  {props.phone}
                </Typography>
              </Stack>
            </Grid>
            {props.isConnected && (
              <>
                <Divider
                  sx={{ width: "116%", ml: "-10%", my: "10px" }}
                ></Divider>
                <Grid
                  item
                  // xs={10}
                  container
                  spacing={0}
                  direction="row"
                  alignItems="left"
                  justifyContent="left"
                >
                  <Stack
                    direction="column"
                    spacing={1}
                    width="100%"
                    alignItems="center"
                  >
                    <Typography
                      className={css.dataSharingSettings}
                      alignSelf="start"
                    >
                      Data Sharing Settings
                    </Typography>

                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                    >
                      <Typography className={css.normalText400}>
                        Profile Access:
                      </Typography>
                      <Typography className={css.normalText400}>
                        <Brightness1RoundedIcon
                          className={
                            props.profileAccess ? css.dotSuccess : css.dotDanger
                          }
                        />
                        {props.profileAccess ? "On" : "Off"}
                      </Typography>
                    </Grid>

                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                    >
                      <Typography className={css.normalText400}>
                        Contact Info:
                      </Typography>
                      <Typography className={css.normalText400}>
                        <Brightness1RoundedIcon
                          className={
                            props.contactInfo ? css.dotSuccess : css.dotDanger
                          }
                        />
                        {props.contactInfo ? "On" : "Off"}
                      </Typography>
                    </Grid>

                    <Button
                      variant="outlined"
                      sx={{
                        textTransform: "none",
                        borderRadius: "25px",
                      }}
                    >
                      <DataSharingModal
                        connectionId={props.connectionId}
                        isConnected={props.isConnected}
                        profileAccess={props.profileAccess}
                        contactInfo={props.contactInfo}
                      />
                    </Button>
                  </Stack>
                </Grid>
              </>
            )}
          </Grid>
        </DialogContent>
        {/* <DialogActions> */}
        {/* <Stack direction="column" spacing={1} width="100%">
            <Typography className={css.primaryName} sx={{ pb: 1, pl: "10px" }}>
              Connections: {props.totalConnPerCompany}
            </Typography>
            <SearchBox PlaceHolder={"Search"} Mobile={true}></SearchBox>
            <TabConnIndividual
              connId={props.connectionId}
              count={props.count}
              totalConnPerCompany={props.totalConnPerCompany}
            />
          </Stack> */}
        {/* </DialogActions> */}
      </Dialog>
    </div>
  );
};

export default IndividualEntityMobile;
