import {
  Avatar,
  Box,
  css,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetEntity } from "../action/EntityAction";
import Header from "../component/Header";
import SearchBox from "../component/SearchBox";
import connLogo from "../assets/svg/Connections_icon.svg";
import NoConnections from "../assets/images/NoConnections.png";
import { EntitiesStoreContext } from "../store/EntitiesStore";
import { RootStore } from "../store/Store";
import { JobSeeker } from "../views/jobseeker/JobSeeker";
import { Recruiter } from "../views/recruiter/Recruiter";
import { useStyles } from "../style/styles";
import Loader from "../component/Loader"
import { Dimensions } from "react-native";
import {PullToRefresh, PullDownContent, ReleaseContent, RefreshContent} from "react-js-pull-to-refresh";
import { setGlobalState } from "../utils/GlobalState";
import { REACT_APP_MOBILE_WIDTH } from "@env";
import { UpdateDevice } from "../action/DeviceActions";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  const css = useStyles();

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const NoConnection = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="60vh"
    >
      <Grid>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Avatar
            src={NoConnections}
            sx={{ width: "400px", height: "400px" }}
          />
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "24px",
              lineHeight: "36px",
            }}
            align="center"
          >
            Oops! You do not have any connections yet.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export const Router = ({ entity }) => {
  const dispatch = useDispatch();
  const entityState = useSelector(
    (state: RootStore) => state.entityRootReducer
  );

  const { isMobile, isPortrait, windowWidth} = useSelector(
    (state: RootStore) => state.deviceReducer.deviceData
  );

  const entityStore = useContext(EntitiesStoreContext);

  // const css = useStyles();
  const [value, setValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  // console.log(searchEntity);

  useEffect(() => {
    detectDeviceResize();
    dispatch(GetEntity(entity));
    setGlobalState("currentPage", "main");

    window.addEventListener("resize", detectDeviceResize);
  }, []);

  function onRefresh() {
    return new Promise(() => {
      dispatch(GetEntity(entity));
    });
  }

  const detectDeviceResize = () => {
    console.log("Device resize detected");

    dispatch(UpdateDevice());
  };

  console.log("isMobile: " + isMobile);
  console.log("isPortrait: " + isPortrait);
  console.log("windowWidth: " + windowWidth);

  if (entityState.entityReducer && entityState.entityReducer.type) {
    entityStore.entityId = entityState.entityReducer.id;
    entityStore.entityId64 = entityState.entityReducer.id64;
    entityStore.entityType = entityState.entityReducer.type.toLowerCase();
    return entityState.entityReducer.type.toLowerCase() == "jobseeker" ? (
      isMobile ? <PullToRefresh
      pullDownContent={<PullDownContent />}
      releaseContent={<ReleaseContent />}
      refreshContent={<></>}
      pullDownThreshold={200}
      onRefresh={onRefresh}
      triggerHeight="auto"
      backgroundColor="white"
      >
        <JobSeeker />
      </PullToRefresh> : <JobSeeker />
    ) : (
      <Recruiter />
    );
  }

  return (
    <Box sx={{ px: 1 }}>
      {/* <Header type="jobseeker" /> */}
      <Grid container spacing={2} sx={{ py: 2 }}>
        <Grid item xs={6}>
        <Header type="jobseeker" />
        </Grid>
      </Grid>
      <Divider />
      <SearchBox PlaceHolder="Search Key" Mobile={false} Person={false} />
      {/* <TabConn /> */}
      <Box>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              sx={{
                fontFamily: "Poppins",
                textTransform: "none",
                fontSize: "14px",
                fontWeight: 500,
                lineHeight: "22px",
                fontStyle: "normal",
                minWidth: 10,
              }}
              label="All Connections"
              {...a11yProps(0)}
            />
            <Tab
              sx={{
                fontFamily: "Poppins",
                textTransform: "none",
                fontSize: "14px",
                fontWeight: 500,
                lineHeight: "22px",
                fontStyle: "normal",
              }}
              label="Connected"
              {...a11yProps(1)}
            />
            {/* <Tab
              sx={{
                fontFamily: "Poppins",
                textTransform: "none",
                fontSize: "14px",
                fontWeight: 500,
                lineHeight: "22px",
                fontStyle: "normal",
              }}
              label="Disconnected"
              {...a11yProps(2)}
            /> */}
        {/* {(entityState.entityReducer && 
            entityState.entityReducer.type && 
            entityState.entityReducer.type.toLowerCase() == "jobseeker" && 
            <Tab
              sx={{
                fontFamily: "Poppins",
                textTransform: "none",
                fontSize: "14px",
                fontWeight: 500,
                lineHeight: "22px",
                fontStyle: "normal",
              }}
              label="Pending"
              {...a11yProps(2)}
            />
          )}
          <Tab
              sx={{
                fontFamily: "Poppins",
                textTransform: "none",
                fontSize: "14px",
                fontWeight: 500,
                lineHeight: "22px",
                fontStyle: "normal",
              }}
              label="Blocked"
              {...a11yProps(2)}
            />*/}
          </Tabs> 
        </Box>
        {!entityState.entityReducer && <Loader />}
        {entityState.entityReducer &&
        <>
          <TabPanel value={value} index={0}>
            <NoConnection></NoConnection>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <NoConnection></NoConnection>
          </TabPanel>
          {/* <TabPanel value={value} index={2}>
            <NoConnection></NoConnection>
          </TabPanel> */}
        </>}
      </Box>
    </Box>
  );
};
