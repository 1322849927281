import { Avatar, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl/FormControl";
import FormLabel from "@mui/material/FormLabel/FormLabel";
import Grid from "@mui/material/Grid";
import { useContext } from "react";
import connLogo from "../assets/svg/Connections_icon.svg";
import { EntitiesStoreContext } from "../store/EntitiesStore";
import { useStyles } from "../style/styles";
import DefaultSettingsModal from "./DefaultSettingsModal";

interface IHeader {
  type: string;
}
const Header = (props: IHeader) => {
  const css = useStyles();
  const entityStore = useContext(EntitiesStoreContext);

  const defaultSetting =
    props.type == "jobseeker" ? (
      <DefaultSettingsModal connectionId={entityStore.entityId64} />
    ) : (
      <></>
    );

  return (
    <Grid container spacing={2} sx={{ py: 2 }}>
      <Grid item xs={6}>
        <Stack
          direction="row"
          spacing={1}
          alignItems="left"
          justifyContent="left"
          // sx={{ pr: "50px" }}
        >
          <Avatar
            src={connLogo}
            sx={{ width: "32px", height: "32px" }}
            variant="square"
          />
          <Stack
            direction="column"
            spacing={1}
            alignItems="right"
            justifyContent="center"
          >
            <FormControl>
              <FormLabel className={css.connHeader}>Connections </FormLabel>
            </FormControl>
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );
};
export default Header;
