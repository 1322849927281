import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import axios from "axios";
import { REACT_APP_MOCK_JS_BACKEND_API } from "@env";

function Chatbox({jobseekerEmail, recruiterEmail}) {
  const [inputValue, setInputValue] = useState('');
  const [messages, setMessages] = useState<any>([]);
  const [isSelected, setIsSelected] = useState(false)
  const [unreadCount, setUnreadCount] = useState(0)
  const inputRef = useRef<any>(null);

  const [recruiter, setRecruiter] = useState(null)
  const [jobseeker, setJobseeker] = useState(null)
  const [channelArn, setChannelArn] = useState("")

  const splitUserArn = (userArn) => {
    if (!userArn) {
      return ""
    }

    const splitStr = userArn.split('/')

    if (!splitStr || splitStr.length == 0) {
      return ""
    }

    return splitStr[splitStr.length -1]
  } 

  const isIndicatorMessage = (messageContent) => 
    messageContent && messageContent.match(/\[Indicator/)

  const isOwnMessage = (sender) =>
    sender && sender.Arn === jobseeker.AppInstanceUserArn

  const getUserIntance = async() => {
    if (recruiterEmail) {    
      await axios.post(`${REACT_APP_MOCK_JS_BACKEND_API}/api/user/instance-arn`,
      JSON.stringify({
        "email": recruiterEmail,
      })).then(res => {
        console.log('/API/USER/INSTANCE-ARN res', res)
        if (res && res.data && res.data.AppInstanceUserArn) {
          setRecruiter(res.data)
        }
      }).catch( err => {
        console.log('/API/USER/INSTANCE-ARN err', err)
      })
    }

    if (jobseekerEmail) {
      await axios.post(`${REACT_APP_MOCK_JS_BACKEND_API}/api/user/instance-arn`,
      JSON.stringify({
        "email": jobseekerEmail,
      })).then(res => {
        console.log('/API/USER/INSTANCE-ARN res', res)
        if (res && res.data && res.data.AppInstanceUserArn) {
          setJobseeker(res.data)
        }
      }).catch( err => {
        console.log('/API/USER/INSTANCE-ARN err', err)
      })
    }
  }

  const checkUnreadCount = async() => {
    const userId = splitUserArn(jobseeker.AppInstanceUserArn)
    if (!userId) {
      return
    }

    const messages = await listChannelMessages()
    axios.post(`${REACT_APP_MOCK_JS_BACKEND_API}/api/channel/list-membership`, {
        userId: userId
    })
    .then(response => {
        console.log('LIST-MEMBERSHIP response', response)
        if (response && response.data && response.data.ChannelMemberships) {
          let listChannelMembership = response.data.ChannelMemberships
          let readMarkerDateStamp:any = null
          if (listChannelMembership && channelArn) {
            listChannelMembership = listChannelMembership.filter((channelData, index) => 
              channelData.ChannelSummary.ChannelArn === channelArn)   
              if (listChannelMembership && listChannelMembership[0] &&
                listChannelMembership[0].ChannelSummary && listChannelMembership[0].ChannelSummary.Metadata) {
                const metaData = listChannelMembership[0].ChannelSummary.Metadata
                const jsonData = JSON.parse(metaData)
                if (jsonData && userId in jsonData && 
                    jsonData[userId]) {
                  let markerTimeStampTemp = null
                  if (jsonData[userId].ReadMarkerTimestamp) {
                    markerTimeStampTemp= jsonData[userId].ReadMarkerTimestamp
                  }
                  
                  if (markerTimeStampTemp) {
                    readMarkerDateStamp = (new Date(markerTimeStampTemp)).getTime()
                  }
                }
              }
          }
          
          if (messages) {
            messages.map((message, index) => {
              if (message.Sender.Arn !== jobseeker.AppInstanceUserArn && 
                !isIndicatorMessage(message.Content)) {
                const messageDateStamp = (new Date(message.CreatedTimestamp)).getTime()
                if (!readMarkerDateStamp || (readMarkerDateStamp && readMarkerDateStamp < messageDateStamp)) {
                  setUnreadCount(unreadCount + 1)
                }
              }
            })
          }
        }
    })
    .catch(error => {
        console.log('LIST-MEMBERSHIP', error)
    });
  }
  
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const userId = splitUserArn(jobseeker.AppInstanceUserArn)
    if (!userId) {
      return
    }

    const newMessage = inputValue.trim();
    if (newMessage !== '') {
      setMessages([...messages, newMessage]);
      setInputValue('');
    }

    axios.post(`${REACT_APP_MOCK_JS_BACKEND_API}/api/channel/send-message`, {
        channelArn: channelArn,
        userId: userId,
        messageContent: newMessage,
        persistence : "PERSISTENT",
        type : "STANDARD"
    })
    .then(response => {
        console.log('SEND MESSAGE Success', response.data)
        listChannelMessages()
    })
    .catch(error => {
        console.log('SEND MESSAGE Error', error)
    })
  };

  const listChannelMessages = async() => {
    const userId = splitUserArn(jobseeker.AppInstanceUserArn)
    if (!userId) {
      return
    }

    return axios.post(`${REACT_APP_MOCK_JS_BACKEND_API}/api/channel/list-messages`, {
        channelArn: channelArn,
        userId: userId
    })
    .then(response => {
        console.log('LIST-MESSAGES response', response)
        const messagesList = response.data
        messagesList.sort(function (a, b) {
            return a.CreatedTimestamp < b.CreatedTimestamp
            ? -1
            : a.CreatedTimestamp > b.CreatedTimestamp
            ? 1
            : 0;
        });
        const messages:any = [];
        for (let i = 0; i < messagesList.length; i++) {
            const message = messagesList[i];
            messages.push(message);
            // setMessages(message)
        }
        setMessages(messages);

        return messagesList;
    })
    .catch(error => {
        console.log('LIST-MESSAGES', error)
    });
  }

  const handleInputFocused = () => {
    const userId = splitUserArn(jobseeker.AppInstanceUserArn)
    const adminId = splitUserArn(recruiter.AppInstanceUserArn)

    if (unreadCount > 0 && userId) {
      axios.post(`${REACT_APP_MOCK_JS_BACKEND_API}/api/channel/update-marker`, {
        channelArn: channelArn,
        userId: userId,
        adminId: adminId,
        marker: "read",
        isNull: false
    })
      .then(response => {
          console.log('READ-MARKER Success', response.data)
          setUnreadCount(0)
      })
      .catch(error => {
          console.log('READ-MARKER Error', error)
      })
    }
  }

  const checkDeliveredMarker = async() => {
    const userId = splitUserArn(jobseeker.AppInstanceUserArn)
    if (!userId) {
      return
    }

    const messages = await listChannelMessages()
    axios.post(`${REACT_APP_MOCK_JS_BACKEND_API}/api/channel/list-membership`, {
        userId: userId
    })
    .then(response => {
        console.log('LIST-MEMBERSHIP response', response)
        if (response && response.data && response.data.ChannelMemberships) {
          let listChannelMembership = response.data.ChannelMemberships
          let deliveredMarkerDateStamp:any = null
          let unreadCountPerDeliveredMarker = 0

          if (listChannelMembership && channelArn) {
            listChannelMembership = listChannelMembership.filter((channelData, index) => 
              channelData.ChannelSummary.ChannelArn === channelArn)   
            if (listChannelMembership && listChannelMembership[0] &&
              listChannelMembership[0].ChannelSummary && listChannelMembership[0].ChannelSummary.Metadata) {
              const metaData = listChannelMembership[0].ChannelSummary.Metadata
              const jsonData = JSON.parse(metaData)
              if (jsonData && jobseeker.AppInstanceUserArn in jsonData && 
                  jsonData[jobseeker.AppInstanceUserArn] && jsonData[jobseeker.AppInstanceUserArn].DeliveredMarkStamp) {
                const deliveredMarkStamp= jsonData[jobseeker.AppInstanceUserArn].DeliveredMarkStamp
                if (deliveredMarkStamp) {
                  deliveredMarkerDateStamp = (new Date(deliveredMarkStamp)).getTime()
                }
              }
            }
          }
          
          console.log("%c Line:225 🥤 deliveredMarkerDateStamp", "color:#4fff4B", deliveredMarkerDateStamp);
          if (messages) {
            messages.map((message, index) => {
              if (message.Sender.Arn !== jobseeker.AppInstanceUserArn && 
                !isIndicatorMessage(message.Content)) {
                  const messageDateStamp = (new Date(message.CreatedTimestamp)).getTime()
                console.log("%c Line:235 🍢 message", "color:#6ec1c2", message);
                if (!deliveredMarkerDateStamp || (deliveredMarkerDateStamp && deliveredMarkerDateStamp < messageDateStamp)) {
                  unreadCountPerDeliveredMarker += 1
                }
              }
            })
          }

          if (unreadCountPerDeliveredMarker > 0) {
            handleCallDeliveredMarker()
          }
        }
    })
    .catch(error => {
        console.log('LIST-MEMBERSHIP', error)
    });
  }

  const getChannelArn = async() => {
    const userId = splitUserArn(jobseeker.AppInstanceUserArn)
    if (!userId) {
      return
    }
    axios.post(`${REACT_APP_MOCK_JS_BACKEND_API}/api/channel/list-membership`, {
        userId: userId
    })
    .then(response => {
        console.log('LIST-MEMBERSHIP response', response)
        if (response && response.data && response.data.ChannelMemberships) {
          response.data.ChannelMemberships.map((membership, index) => {
            if (membership.ChannelSummary &&
              membership.ChannelSummary.Name.includes(splitUserArn(recruiter.AppInstanceUserArn)) ) {
              setChannelArn(membership.ChannelSummary.ChannelArn)
            }
          })
        }
    })
    .catch(error => {
        console.log('LIST-MEMBERSHIP', error)
    });
  }

  const handleCallDeliveredMarker = () => {
    const userId = splitUserArn(jobseeker.AppInstanceUserArn)
    const adminId = splitUserArn(recruiter.AppInstanceUserArn)

    if (!(userId && adminId)) {
      return
    }

    axios.post(`${REACT_APP_MOCK_JS_BACKEND_API}/api/channel/update-marker`, {
          channelArn: channelArn,
          userId: userId,
          adminId: adminId,
          marker: "delivered",
          isNull: false
      })
      .then(response => {
          console.log('UPDATE-MARKER Success', response.data)
      })
      .catch(error => {
          console.log('UPDATE-MARKER Error', error)
      })
  }

  const handleChannelSelect = () => {
    setIsSelected(true)
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  }
  
  useEffect(() => {
    getUserIntance()
  }, []);

  useEffect(() => {
    if (recruiter && jobseeker) {
      getChannelArn()
    }    
  }, [recruiter, jobseeker]);

  useEffect(() => {
    if (recruiter && jobseeker && channelArn) {
      checkUnreadCount()
      checkDeliveredMarker()
    }    
  }, [recruiter, jobseeker, channelArn]);

  return (
    <div className="App">
      <div className="App-container">
        <div className="Sidebar">
          <h3>Recruiter</h3>
          <ul className="channel">
            {recruiter && <li className="list-item" onClick={handleChannelSelect}>
              {recruiter.firstName + ' ' + recruiter.lastName} {unreadCount > 0 && (<span style={{ color: 'red'}}>{'(' + unreadCount + ')'}</span>)}
              </li>}
          </ul>
        </div>
        <div className="Chatbox">
          <header className="App-header">
            <div className="Chatbox-body">
              {isSelected && messages.filter((message, index) => !isIndicatorMessage(message.Content)).map((message, index) => (
                isOwnMessage(message.Sender) ? (
                  <div key={index} className="Chatbox-message owner">
                    {message.Content} {/* Display the 'Content' property */}
                  </div>
                ) : (
                  <div key={index} className="Chatbox-message sender">
                    {message.Content} {/* Display the 'Content' property */}
                  </div>
                )
              ))}
            </div>
            {isSelected && (
              <form onSubmit={handleSubmit} className="Chatbox-form">
                <input
                ref={inputRef}
                  type="text"
                  value={inputValue}
                  onChange={handleInputChange}
                  onFocus={handleInputFocused}
                  placeholder="Type your message..."
                  className="Chatbox-input"
                />
                <button type="submit" className="Chatbox-submit">
                  Send
                </button>
              </form>
            )}
          </header>
        </div>
      </div>
    </div>
  );
}

export default Chatbox;
