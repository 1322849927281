import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { useSelector } from "react-redux";
import axiosInstance from "../helpers/axios";
import { RootStore } from "../store/Store";
import { useStyles } from "../style/styles";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

interface IProfile {
  connectionId: string;
  isConnected: boolean;
  profileAccessEnabled: boolean;
  showProfile: boolean;
}

const ProfileModal = (props: IProfile) => {
  const css = useStyles();
  const [showProfileModal, setSHowProfileModal] = useState(false);
  const [profile, setProfile] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);

  const entityState = useSelector(
    (state: RootStore) => state.entityRootReducer
  );

  const handleShowModal = async () => {
    setSHowProfileModal(true);
    setIsLoading(true);
    try {
      const { data } = await axiosInstance.get(
        `/profile/api/lookup?searchKey=${
          entityState.entityReducer?.connections[props.connectionId].email
        }`
      );
      const profileData = data.profile;
      setProfile(profileData);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setSHowProfileModal(false);
  };

  return (
    <>
      <Link
        onClick={handleShowModal}
        href="#"
        underline="none"
        className={
          props.showProfile ? css.enabledLink : css.disabledLink
        }
      >
        Show Profile
      </Link>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={showProfileModal}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              minWidth: "1158px",
              // Set your width here
            },
          },
        }}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <Typography className={css.dataSharingSettingsModal}>
            Show Profile
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent dividers className={css.profileDialogContent}>
          {!isLoading ? (
            <>
              <Grid container className={css.profileDialogContainer}>
                <Grid item>
                  <Avatar
                    alt={profile?.firstName + " " + profile?.lastName}
                    src="/static/images/avatar/1.jpg"
                    sx={{ width: "120px", height: "120px" }}
                  />
                </Grid>
                <Grid item>
                  <Grid className={css.profileDialog_A}>
                    <div className={css.profileDialogHeader}>
                      {profile.firstName} {profile.lastName}
                    </div>
                    <div className={css.profileDialogDefaultText}>
                      {profile.title}
                    </div>
                  </Grid>

                  <Grid
                    className={css.profileDialogDefaultPadding}
                    alignItems="start"
                  >
                    <Grid
                      container
                      direction="row"
                      alignItems="start"
                      className={css.profileDialog_B}
                    >
                      <Stack
                        direction="row"
                        alignItems="center"
                        className={css.profileDialogB_Item_Custom}
                      >
                        <Grid item>
                          <svg
                            className={`${css.profileDialogIcon} ${css.profileDialogB_Item_Icon_Custom}`}
                            width="16"
                            height="21"
                            viewBox="0 0 16 21"
                          >
                            <path
                              d="M8 3.9375C5.79437 3.9375 4 5.70384 4 7.875C4 10.0462 5.79437 11.8125 8 11.8125C10.2056 11.8125 12 10.0462 12 7.875C12 5.70384 10.2056 3.9375 8 3.9375ZM8 10.5C6.52958 10.5 5.33333 9.32244 5.33333 7.875C5.33333 6.42756 6.52958 5.25 8 5.25C9.47042 5.25 10.6667 6.42756 10.6667 7.875C10.6667 9.32244 9.47042 10.5 8 10.5ZM8 0C3.58171 0 0 3.52574 0 7.875C0 11.0501 1.12375 11.9368 7.17783 20.5763C7.57508 21.1412 8.42488 21.1413 8.82221 20.5763C14.8763 11.9368 16 11.0501 16 7.875C16 3.52574 12.4183 0 8 0ZM8 19.4386C2.19604 11.1763 1.33333 10.5203 1.33333 7.875C1.33333 6.12207 2.02679 4.47411 3.28596 3.23462C4.54513 1.99512 6.21925 1.3125 8 1.3125C9.78075 1.3125 11.4549 1.99512 12.714 3.23462C13.9732 4.47411 14.6667 6.12207 14.6667 7.875C14.6667 10.5201 13.8045 11.1755 8 19.4386Z"
                              fill="#2F86DD"
                            />
                          </svg>
                        </Grid>
                        <Grid item className={css.profileDialogDefaultText}>
                          {profile.city} {profile.state ? "," : ""}{" "}
                          {profile.state}
                        </Grid>
                      </Stack>

                      <Stack
                        direction="row"
                        alignItems="center"
                        className={css.profileDialogIcon}
                      >
                        <Grid item>
                          <svg
                            className={css.profileDialogIcon}
                            width="21"
                            height="21"
                            viewBox="0 0 21 21"
                          >
                            <path
                              d="M20.0074 0.988729L15.8731 0.0330767C15.2702 -0.106375 14.6509 0.20534 14.4048 0.77545L12.4976 5.22559C12.272 5.74648 12.4237 6.36171 12.8626 6.72264L15.0733 8.53141C13.6788 11.3697 11.3697 13.6829 8.52731 15.0774L6.71854 12.8667C6.35761 12.4278 5.74238 12.2761 5.22149 12.5017L0.77545 14.4089C0.20534 14.655 -0.106375 15.2702 0.0330767 15.8731L0.984627 20.0033C1.11998 20.5898 1.63677 21 2.23559 21C12.5796 21 21 12.6247 21 2.23559C21 1.63677 20.5898 1.11998 20.0074 0.988729ZM2.2602 19.6875L1.31685 15.6024L5.72187 13.7157L8.16227 16.7016C12.4114 14.7083 14.7124 12.3991 16.6975 8.16637L13.7116 5.72597L15.5983 1.32095L19.6834 2.2643C19.6752 11.8823 11.8782 19.6752 2.2602 19.6875Z"
                              fill="#2F86DD"
                            />
                          </svg>
                        </Grid>
                        <Grid item className={css.profileDialogDefaultText}>
                          {profile.phone}
                        </Grid>
                      </Stack>
                    </Grid>

                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      className={`${css.profileDialog_B} ${css.profileDialogB_Item}`}
                    >
                      <Stack
                        direction="row"
                        alignItems="start"
                        className={css.profileDialogIcon}
                      >
                        <Grid item>
                          <svg
                            className={css.profileDialogIcon}
                            xmlns="http://www.w3.org/2000/svg"
                            width="22"
                            height="17"
                            viewBox="0 0 22 17"
                          >
                            <path
                              d="M19.9375 0H2.0625C0.923828 0 0 0.951823 0 2.125V14.875C0 16.0482 0.923828 17 2.0625 17H19.9375C21.0762 17 22 16.0482 22 14.875V2.125C22 0.951823 21.0762 0 19.9375 0ZM2.0625 1.41667H19.9375C20.3156 1.41667 20.625 1.73542 20.625 2.125V3.95781C19.684 4.77682 18.3391 5.90573 14.1539 9.32786C13.4277 9.92109 11.9969 11.351 11 11.3333C10.0031 11.351 8.56797 9.92109 7.84609 9.32786C3.66094 5.90573 2.31602 4.77682 1.375 3.95781V2.125C1.375 1.73542 1.68438 1.41667 2.0625 1.41667ZM19.9375 15.5833H2.0625C1.68438 15.5833 1.375 15.2646 1.375 14.875V5.79948C2.35469 6.62734 3.90156 7.90677 6.99102 10.4346C7.87187 11.1607 9.42734 12.7589 11 12.75C12.5641 12.7633 14.1066 11.1784 15.009 10.4346C18.0984 7.90677 19.6453 6.62734 20.625 5.79948V14.875C20.625 15.2646 20.3156 15.5833 19.9375 15.5833Z"
                              fill="#2F86DD"
                            />
                          </svg>
                        </Grid>
                        <Grid item className={css.profileDialogDefaultText}>
                          {profile.email}
                        </Grid>
                      </Stack>
                    </Grid>

                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      className={css.profileDialog_C}
                    >
                      <Stack direction="column" alignItems="start">
                        <Grid
                          item
                          className={`${css.profileDialogDefaultText} ${css.profileDialog_C_item}`}
                        >
                          <span className={css.profileDialogSubHeader}>
                            Overview:{" "}
                          </span>
                          <span>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Ut enim ad minim veniam, quis
                            nostrud exercitation ullamco laboris nisi ut aliq
                          </span>
                        </Grid>
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container direction="column">
                <Grid item>
                  <div className={css.profileDialogHeader}>Previous Jobs</div>
                </Grid>
                {profile &&
                  profile.jobs?.map((value, index) => {
                    return (
                      <>
                        <Grid
                          item
                          className={css.profileDialog_D_item}
                          key={index}
                        >
                          {value.company.location.locality && (
                            <div className={css.profileDialogSubHeader}>
                              {value.company.name}
                            </div>
                          )}
                          <Grid
                            container
                            justifyContent="space-between"
                            direction="row"
                            className={css.profileDialogDefaultText}
                          >
                            <div
                              className={`${
                                value.company.name &&
                                index === 0 &&
                                css.profileDialog_D
                              }`}
                            >
                              {!value.company.location.locality
                                ? value.company.name
                                : value.company.location.locality}
                            </div>
                            {value.company.name && (
                              <div
                                className={`${
                                  value.company.name &&
                                  index === 0 &&
                                  css.profileDialog_D
                                }`}
                              >
                                {value.startDate} {value.endDate && "-"}{" "}
                                {value.endDate}
                              </div>
                            )}
                          </Grid>
                        </Grid>
                      </>
                    );
                  })}
              </Grid>

              <div className={css.profileDialog_divider} />

              <Grid
                container
                direction="column"
                className={css.profileDialog_D}
              >
                <Grid item>
                  <div
                    className={`${css.profileDialogHeader} ${css.profileDialog_D_header}`}
                  >
                    Education
                  </div>
                </Grid>
                {profile.educations?.map((value, index) => {
                  return (
                    <>
                      <Grid
                        item
                        className={css.profileDialog_D_item}
                        key={index}
                      >
                        <div className={css.profileDialogSubHeader}>
                          {value.degrees}
                        </div>
                        <Grid
                          container
                          justifyContent="space-between"
                          direction="row"
                          className={css.profileDialogDefaultText}
                        >
                          <div>{value.school.name}</div>
                          <div>
                            {value.startDate} {value.endDate && "-"}{" "}
                            {value.endDate}
                          </div>
                        </Grid>
                      </Grid>
                    </>
                  );
                })}
              </Grid>

              <div className={css.profileDialog_divider} />

              <Grid
                container
                direction="column"
                className={css.profileDialog_E}
              >
                <Grid item>
                  <div className={css.profileDialogHeader}>Skills</div>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "flex-start",
                      marginTop: "8px",
                    }}
                  >
                    {profile.skills?.map((value, index) => {
                      return (
                        <div className={css.profileDialog_E_Items} key={index}>
                          {value}
                        </div>
                      );
                    })}
                  </Box>
                </Grid>
              </Grid>
            </>
          ) : (
            <div className={css.dialogLoadingProgress}>
              <CircularProgress />
            </div>
          )}
        </DialogContent>
      </BootstrapDialog>
    </>
  );
};

export default ProfileModal;
