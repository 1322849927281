import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { useContext, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import IndividualEntity from "../../component/entity/IndividualEntity";
import { EntitiesStoreContext } from "../../store/EntitiesStore";
import { RootStore } from "../../store/Store";
import { useStyles } from "../../style/styles";
// import { useGlobalState } from "../../utils/GlobalState";
import IndividualBlock from "../../component/entity/IndividualBlock";
import IndividualPending from "./IndividualPending";
import { Dimensions } from "react-native";
import { useGlobalState } from "../../utils/GlobalState";
import IndividualEntityMobile from "../../component/entity/individualEntityMobile";

interface IIndivList {
  connId: string;
  tabIndex: number;
  totalCount: number;
}

const IndividualList = (props: IIndivList) => {
  const css = useStyles();
  const {entityRootReducer, companyReducer} = useSelector(
    (state: RootStore) => state
  );
  const { isMobile, isPortrait } = useSelector(
    (state: RootStore) => state.deviceReducer.deviceData
  );
  const entityStore = useContext(EntitiesStoreContext);
  const searchEntityMobile = useGlobalState("searchEntityMobile");
  const currentPage = useGlobalState("currentPage");

  const setConnectionListData = () => {
    if ( (!isMobile || currentPage[0] == "main") && entityRootReducer.entityReducer) {
      return entityRootReducer.entityReducer.connections;
    } else {
      return companyReducer.companyInfo?.connections;
    }
  };

  const [connectionList, setConnectionList] = useState(setConnectionListData());

  return (
    // <List sx={{ width: "100%" }}>
    //   <ListItem key={props.connId} disablePadding>
        <Grid container direction={isMobile ? "row" : "column"}>
          {connectionList &&
            Object.entries(connectionList)
              .filter(([key, conn]) => {
                if (searchEntityMobile[0].toLowerCase() == "") {
                  return conn;
                } else if (
                  conn.firstName
                    .concat(" ", conn.lastName)
                    .toLowerCase()
                    .includes(searchEntityMobile[0].toLowerCase())
                ) {
                  return conn;
                }
              })
              .map(([k, conn], indx) => {
                if (parseInt(props.connId) == conn.companyId && !conn.isBlockedLink) {
                  entityStore.connectionId = k;

                  if (
                    props.tabIndex == 1 &&
                    props.totalCount > 0 &&
                    conn.isConnected &&
                    conn.status.toLowerCase() == "accepted"
                  ) {
                    return (
                      <Box
                        sx={{
                          borderTop: !isMobile ? 1 : 0,
                          borderColor: "#E4E6EF",
                          width: !isMobile ? "100%" : 
                            isPortrait ? "50%"
                            : "240px",
                        }}
                        key={indx}
                      >
                        <IndividualEntity
                          uniqueKey={conn.id64}
                          id64={conn.id64}
                          fullname={conn.firstName.concat(" ", conn.lastName)}
                          positionTitle={conn.title}
                          email={
                            conn.dsSettingsLink.showEmail
                              ? conn.email
                              : "*************************"
                          }
                          phone={
                            conn.dsSettingsLink.showPhone
                              ? conn.phone
                              : "*** *** ****"
                          }
                          isConnected={conn.isConnected}
                          status={conn.status}
                          isBlocked={conn.isBlocked}
                          connectionEstablished={conn.date}
                          profileAccess={conn.profileAccessEnabled}
                          contactInfo={conn.contactInfoEnabled}
                          connectionId={
                            conn.dsSettingsSelf
                              ? conn.dsSettingsSelf.connectionId
                              : conn.dsSettingsSelf
                          }
                          companyId={conn.companyId.toString()}
                          entity="jobseeker"
                          showProfile={conn.dsSettingsLink.showProfile}
                          showResume={conn.dsSettingsLink.showResume}
                        />
                      </Box>
                    );
                  }

                  if (
                    props.tabIndex == 2 &&
                    props.totalCount > 0 &&
                    !conn.isConnected &&
                    !conn.isBlocked &&
                    conn.status.toLowerCase() == "declined"
                  ) {
                    return (
                      <Box
                        sx={{
                          borderTop: !isMobile ? 1 : 0,
                          borderColor: "#E4E6EF",
                          width: !isMobile ? "100%" : 
                            isPortrait ? "50%"
                            : "240px",
                        }}
                        key={indx}
                      >
                        <IndividualEntity
                          uniqueKey={conn.id64}
                          id64={conn.id64}
                          fullname={conn.firstName.concat(" ", conn.lastName)}
                          positionTitle={conn.title}
                          email={
                            conn.dsSettingsLink.showEmail
                              ? conn.email
                              : "*************************"
                          }
                          phone={
                            conn.dsSettingsLink.showPhone
                              ? conn.phone
                              : "*** *** ****"
                          }
                          isConnected={conn.isConnected}
                          status={conn.status}
                          isBlocked={conn.isBlocked}
                          connectionEstablished={conn.date}
                          profileAccess={conn.profileAccessEnabled}
                          contactInfo={conn.contactInfoEnabled}
                          connectionId={
                            conn.dsSettingsSelf
                              ? conn.dsSettingsSelf.connectionId
                              : conn.dsSettingsSelf
                          }
                          companyId={conn.companyId.toString()}
                          entity="jobseeker"
                          showProfile={conn.dsSettingsLink.showProfile}
                          showResume={conn.dsSettingsLink.showResume}
                        />
                      </Box>
                    );
                  }

                  /************************************************= PENDING TAB =************************************************/
                  if (
                    props.tabIndex == 3 &&
                    props.totalCount > 0 &&
                    conn.status.toLowerCase() == "pending" &&
                    !conn.isConnected
                  ) {
                    return (
                      <Box
                        sx={{
                          borderTop: !isMobile ? 1 : 0,
                          borderColor: "#E4E6EF",
                          width: !isMobile ? "100%" : 
                            isPortrait ? "50%"
                            : "240px",
                          backgroundColor: !isMobile ? "#FFF4DE" : "",
                        }}
                        key={indx+k+indx}
                      >
                        {!isMobile ? <IndividualPending
                            uniquekey={conn.id64}
                            id64={conn.id64}
                            fullname={conn.firstName.concat(" ", conn.lastName)}
                            positionTitle={conn.title}
                            contact={conn.email.concat(
                              " ",
                              conn.phone ? "| " + conn.phone : ""
                            )}
                            isConnected={conn.isConnected}
                            status={conn.status}
                            connectionEstablished={conn.date}
                            profileAccess={conn.profileAccessEnabled}
                            contactInfo={conn.contactInfoEnabled}
                            connectionId={
                              conn.dsSettingsSelf
                                ? conn.dsSettingsSelf.connectionId
                                : conn.dsSettingsSelf
                            }
                          />
                          :
                          <IndividualEntity
                            uniqueKey={k}
                            id64={conn.id64}
                            fullname={conn.firstName.concat(" ", conn.lastName)}
                            positionTitle={conn.title}
                            email={conn.email}
                            phone={conn.phone}
                            isConnected={conn.isConnected}
                            status={conn.status}
                            isBlocked={conn.isBlocked}
                            connectionEstablished={conn.date}
                            profileAccess={conn.profileAccessEnabled}
                            contactInfo={conn.contactInfoEnabled}
                            connectionId={
                              conn.dsSettingsSelf
                                ? conn.dsSettingsSelf.connectionId
                                : conn.dsSettingsSelf
                            }
                            companyId={conn.companyId.toString()}
                            entity="jobseeker"
                            showProfile={conn.dsSettingsLink.showProfile}
                            showResume={conn.dsSettingsLink.showResume}
                          />
                        }
                      </Box>
                    );
                  }

                  /************************************************= BLOCKED TAB =************************************************/
                  if (
                    props.tabIndex == 4 &&
                    props.totalCount > 0 &&
                    conn.isBlocked
                  ) {
                    return (
                      <Box
                        sx={{
                          borderTop: !isMobile ? 1 : 0,
                          borderColor: "#E4E6EF",
                          width: !isMobile ? "100%" : 
                            isPortrait ? "50%"
                            : "240px",
                        }}
                        key={indx}
                      >
                       {!isMobile ? <IndividualBlock
                          backgroundColor="FFF4DE"
                          uniqueKey={conn.id64}
                          id64={conn.id64}
                          fullname={conn.firstName.concat(" ", conn.lastName)}
                          email={conn.email}
                          phone={conn.phone}
                          positionTitle={conn.title}
                          contact={conn.email.concat(
                            " ",
                            conn.phone ? "| " + conn.phone : ""
                          )}
                          isConnected={conn.isConnected}
                          status={conn.status}
                          connectionEstablished={conn.date}
                          profileAccess={conn.profileAccessEnabled}
                          contactInfo={conn.contactInfoEnabled}
                          connectionId={
                            conn.dsSettingsSelf
                              ? conn.dsSettingsSelf.connectionId
                              : conn.dsSettingsSelf
                          }
                          companyId={conn.companyId}
                          entity="jobseeker"
                          showProfile={conn.dsSettingsLink.showProfile}
                          showResume={conn.dsSettingsLink.showResume}
                        />
                        :
                        <IndividualEntity
                            uniqueKey={k}
                            id64={conn.id64}
                            fullname={conn.firstName.concat(" ", conn.lastName)}
                            positionTitle={conn.title}
                            email={
                              conn.dsSettingsLink.showEmail
                                ? conn.email
                                : "*************************"
                            }
                            phone={
                              conn.dsSettingsLink.showPhone
                                ? conn.phone
                                : "*** *** ****"
                            }
                            isConnected={conn.isConnected}
                            status={conn.status}
                            isBlocked={conn.isBlocked}
                            connectionEstablished={conn.date}
                            profileAccess={conn.profileAccessEnabled}
                            contactInfo={conn.contactInfoEnabled}
                            connectionId={
                              conn.dsSettingsSelf
                                ? conn.dsSettingsSelf.connectionId
                                : conn.dsSettingsSelf
                            }
                            companyId={conn.companyId.toString()}
                            entity="jobseeker"
                            showProfile={conn.dsSettingsLink.showProfile}
                            showResume={conn.dsSettingsLink.showResume}
                          />
                        }
                      </Box>
                    );
                  }

                  /************************************************= ALL TAB =************************************************/
                  if (
                    props.tabIndex == 0 &&
                    props.totalCount > 0 &&
                    conn.isConnected &&
                    conn.status.toLowerCase() == "accepted"
                  ) {
                    return (
                      <>
                        <Box
                          sx={{
                            borderTop: !isMobile ? 1 : 0,
                            borderColor: "#E4E6EF",
                            width: !isMobile ? "100%" : 
                              isPortrait ? "50%"
                              : "240px",
                          }}
                          key={indx+k}
                        >
                          <IndividualEntity
                            uniqueKey={conn.id64}
                            id64={conn.id64}
                            fullname={conn.firstName.concat(" ", conn.lastName)}
                            positionTitle={conn.title}
                            email={
                              conn.dsSettingsLink.showEmail
                                ? conn.email
                                : "*************************"
                            }
                            phone={
                              conn.dsSettingsLink.showPhone
                                ? conn.phone
                                : "*** *** ****"
                            }
                            isConnected={conn.isConnected}
                            status={conn.status}
                            isBlocked={conn.isBlocked}
                            connectionEstablished={conn.date}
                            profileAccess={conn.profileAccessEnabled}
                            contactInfo={conn.contactInfoEnabled}
                            connectionId={
                              conn.dsSettingsSelf
                                ? conn.dsSettingsSelf.connectionId
                                : conn.dsSettingsSelf
                            }
                            companyId={conn.companyId.toString()}
                            entity="jobseeker"
                            showProfile={conn.dsSettingsLink.showProfile}
                            showResume={conn.dsSettingsLink.showResume}
                          />
                        </Box>
                      </>
                    );
                  } else if (
                    props.tabIndex == 0 &&
                    props.totalCount > 0 &&
                    !conn.isConnected &&
                    !conn.isBlocked &&
                    conn.status.toLowerCase() == "declined"
                  ) {
                    return (
                      <Box
                        sx={{
                          borderTop: !isMobile ? 1 : 0,
                          borderColor: "#E4E6EF",
                          width: !isMobile ? "100%" : 
                            isPortrait ? "50%"
                            : "240px",
                        }}
                        key={k+indx}
                      >
                        <IndividualEntity
                          uniqueKey={k}
                          id64={conn.id64}
                          fullname={conn.firstName.concat(" ", conn.lastName)}
                          positionTitle={conn.title}
                          email={
                            conn.dsSettingsLink.showEmail
                              ? conn.email
                              : "*************************"
                          }
                          phone={
                            conn.dsSettingsLink.showPhone
                              ? conn.phone
                              : "*** *** ****"
                          }
                          isConnected={conn.isConnected}
                          status={conn.status}
                          isBlocked={conn.isBlocked}
                          connectionEstablished={conn.date}
                          profileAccess={conn.profileAccessEnabled}
                          contactInfo={conn.contactInfoEnabled}
                          connectionId={
                            conn.dsSettingsSelf
                              ? conn.dsSettingsSelf.connectionId
                              : conn.dsSettingsSelf
                          }
                          companyId={conn.companyId.toString()}
                          entity="jobseeker"
                          showProfile={conn.dsSettingsLink.showProfile}
                          showResume={conn.dsSettingsLink.showResume}
                        />
                      </Box>
                    );
                  } else if (
                    props.tabIndex == 0 &&
                    conn.status.toLowerCase() == "pending" &&
                    !conn.isConnected
                  ) {
                    return (
                      <Box
                        sx={{
                          borderTop: !isMobile ? 1 : 0,
                          borderColor: "#E4E6EF",
                          width: !isMobile ? "100%" : 
                            isPortrait ? "50%" 
                            : "240px",
                          backgroundColor: !isMobile ? "#FFF4DE" : "",
                        }}
                        key={indx+k+indx}
                      >
                        {!isMobile ? <IndividualPending
                            uniquekey={conn.id64}
                            id64={conn.id64}
                            fullname={conn.firstName.concat(" ", conn.lastName)}
                            positionTitle={conn.title}
                            contact={conn.email.concat(
                              " ",
                              conn.phone ? "| " + conn.phone : ""
                            )}
                            isConnected={conn.isConnected}
                            status={conn.status}
                            connectionEstablished={conn.date}
                            profileAccess={conn.profileAccessEnabled}
                            contactInfo={conn.contactInfoEnabled}
                            connectionId={
                              conn.dsSettingsSelf
                                ? conn.dsSettingsSelf.connectionId
                                : conn.dsSettingsSelf
                            }
                          />
                          :
                          <IndividualEntity
                            uniqueKey={k}
                            id64={conn.id64}
                            fullname={conn.firstName.concat(" ", conn.lastName)}
                            positionTitle={conn.title}
                            email={conn.email}
                            phone={conn.phone}
                            isConnected={conn.isConnected}
                            status={conn.status}
                            isBlocked={conn.isBlocked}
                            connectionEstablished={conn.date}
                            profileAccess={conn.profileAccessEnabled}
                            contactInfo={conn.contactInfoEnabled}
                            connectionId={
                              conn.dsSettingsSelf
                                ? conn.dsSettingsSelf.connectionId
                                : conn.dsSettingsSelf
                            }
                            companyId={conn.companyId.toString()}
                            entity="jobseeker"
                            showProfile={conn.dsSettingsLink.showProfile}
                            showResume={conn.dsSettingsLink.showResume}
                          />
                        }
                      </Box>
                    );
                  } else if (
                    props.tabIndex == 0 &&
                    conn.isBlocked &&
                    !conn.isConnected
                  ) {
                    return (
                      <Box
                        sx={{
                          borderTop: !isMobile ? 1 : 0,
                          borderColor: "#E4E6EF",
                          width: !isMobile ? "100%" : 
                            isPortrait ? "50%" 
                            : "240px",
                        }}
                        key={k+indx+k}
                      >
                       {!isMobile ? <IndividualBlock
                          backgroundColor="FFF4DE"
                          uniqueKey={conn.id64}
                          id64={conn.id64}
                          fullname={conn.firstName.concat(" ", conn.lastName)}
                          email={conn.email}
                          phone={conn.phone}
                          positionTitle={conn.title}
                          contact={conn.email.concat(
                            " ",
                            conn.phone ? "| " + conn.phone : ""
                          )}
                          isConnected={conn.isConnected}
                          status={conn.status}
                          connectionEstablished={conn.date}
                          profileAccess={conn.profileAccessEnabled}
                          contactInfo={conn.contactInfoEnabled}
                          connectionId={
                            conn.dsSettingsSelf
                              ? conn.dsSettingsSelf.connectionId
                              : conn.dsSettingsSelf
                          }
                          companyId={conn.companyId}
                          entity="jobseeker"
                          showProfile={conn.dsSettingsLink.showProfile}
                          showResume={conn.dsSettingsLink.showResume}
                        />
                        : 
                        <IndividualEntity
                            uniqueKey={k+indx+k}
                            id64={conn.id64}
                            fullname={conn.firstName.concat(" ", conn.lastName)}
                            positionTitle={conn.title}
                            email={
                              conn.dsSettingsLink.showEmail
                                ? conn.email
                                : "*************************"
                            }
                            phone={
                              conn.dsSettingsLink.showPhone
                                ? conn.phone
                                : "*** *** ****"
                            }
                            isConnected={conn.isConnected}
                            status={conn.status}
                            isBlocked={conn.isBlocked}
                            connectionEstablished={conn.date}
                            profileAccess={conn.profileAccessEnabled}
                            contactInfo={conn.contactInfoEnabled}
                            connectionId={
                              conn.dsSettingsSelf
                                ? conn.dsSettingsSelf.connectionId
                                : conn.dsSettingsSelf
                            }
                            companyId={conn.companyId.toString()}
                            entity="jobseeker"
                            showProfile={conn.dsSettingsLink.showProfile}
                            showResume={conn.dsSettingsLink.showResume}
                          />
                        }
                      </Box>
                    );
                  }
                }
              })}
        </Grid>
      // </ListItem>
    // </List>
  );
};

export default IndividualList;
