import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { useStyles } from "../../style/styles";
import BasicInfoSection from "./BasicInfoSection";
import ConnectionEstablished from "./ConnectionEstablished";
import DataSharingSection from "./DataSharingSection";
import StatusSection from "./StatusSection";
import { Dimensions } from "react-native";
import IndividualEntityMobile from "./individualEntityMobile";
import { useContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../store/Store";
import { EntitiesStoreContext } from "../../store/EntitiesStore";
import Avatar from "@mui/material/Avatar";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import defaultUser from "../../assets/images/default_user.png";
import Brightness1RoundedIcon from "@mui/icons-material/Brightness1Rounded";
import { setGlobalState, useGlobalState } from "../../utils/GlobalState";
import { SendEventsByMessage } from "../../action/DsSettingsAction";
import { CompanyLevelIsBlocked, UpdateEntity } from "../../action/EntityAction";
import { ENTITY_FAIL, ENTITY_SUCCESS } from "../../action/EntityActionType";
import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import headerImage from "../../assets/svg/individual_cover.svg";
import IconButton from "@mui/material/IconButton";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";
import DataSharingModal from "../DataSharingModal";
import StatusMenu from "../StatusMenu";
import { TransitionProps } from '@mui/material/transitions';
import Slide from "@mui/material/Slide";
import CompanyDetails from "../../views/jobseeker/CompanyDetails";
import UnBlockStatusMenu from "../UnBlockStatusMenu";
import { RECRUITER_SUCCESS } from "../../action/RecruiterActionTypes";
import { formatConnectionDsSettings, getCompanyConnection, getEntityCompanyConnection, handleConnectionUpdate } from "../../utils/EntityFunctions";
import { COMPANY_SUCCESS } from "../../action/CompanyActionTypes";
import RoundedNameLogo from "../RoundedNameLogo";


const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface IIndividuals {
  uniqueKey: string;
  id64: string;
  fullname: string;
  positionTitle: string;
  email: string;
  phone?: string;
  connectionEstablished: string;
  profileAccess: boolean;
  contactInfo: boolean;
  isConnected: boolean;
  connectionId: string;
  status: string;
  isBlocked?: boolean;
  companyId: string;
  entity: string;
  showProfile: boolean;
  showResume: boolean;
}
const IndividualEntity = (props: IIndividuals) => {
  const css = useStyles();
  const [showIndividual, setshowIndividual] = useState(false);
  // const [selectedPersonKey, setSelectedPersonKey] = useState("");
  // const [clickedPerson, setClickedPerson] = useState(Object);
  const {entityRootReducer, companyReducer, recruiterReducer} = useSelector(
    (state: RootStore) => state
  );
  const { isMobile, isPortrait, windowWidth } = useSelector(
    (state: RootStore) => state.deviceReducer.deviceData
  );
  const entityStore = useContext(EntitiesStoreContext);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [showConnectStatus, setShowConnectStatus] = useState(false);
  const currentPage = useGlobalState("currentPage");

  const personIndividualStatusHandler = () => {
    console.log("personIndividualStatusHandler");
    setGlobalState("currentPage", "recruiter");

    const recruiter  = {};
    recruiter[props.connectionId] = Object.assign({},
      companyReducer.companyInfo?.connections[props.connectionId]);
    
    dispatch({
      type: RECRUITER_SUCCESS,
      payload: recruiter,
    });

    // setRecruiterData(recruiter);
    setshowIndividual(true);
  };

  const handleChange = (stat: string) => {
    console.log("handleChange");

    let isShow = false;
    let recruiterId = "";
    let recruiterId64 = "";
    let jobseekerId64 = "";
    let profileId = "";
    try {
      if (stat == "Connect") {
        isShow = true;
      }

      if (props.connectionId && entityRootReducer.entityReducer) {
        let connId = parseInt(props.connectionId);
        if (entityStore.entityType == "jobseeker") {
          recruiterId = entityRootReducer.entityReducer.connections[connId].id;
          recruiterId64 = entityRootReducer.entityReducer.connections[connId].id64;
          jobseekerId64 = entityRootReducer.entityReducer.id64;
          profileId =
            entityRootReducer.entityReducer.emails[entityRootReducer.entityReducer.email]
              .profileId[0];
        } else if (entityStore.entityType == "recruiter") {
          recruiterId = entityRootReducer.entityReducer.id;
          recruiterId64 = entityRootReducer.entityReducer.id64;
          jobseekerId64 = entityRootReducer.entityReducer.connections[connId].id64;
        }
      }

      const eventsPayload = {
        action: stat.toLowerCase(), // connect/disconnect
        source_entity: recruiterId, // recruiter_id from dynamoDB
        job_seeker_id_64: jobseekerId64, // jobseeker_id from 64recs
        profile_id: profileId, // Empty if recruiter
        isConnected: isShow.toString(), // true or false (true/false is string as requirement of the endpoint)
        isBlocked: "false", // set to false since this condition is for active/inactive (true/false is string as requirement of the endpoint)
        status: isShow ? "accepted" : "declined", // accepted, declined, pending, blocked (this condition is for active/inactive only)
        isPending: "false", // set to false since this condition is for active/inactive (true/false is string as requirement of the endpoint)
        source: recruiterId64, // recruiter_id from 64recs
        caller: "connection", // "connection" for Connections App
        target: jobseekerId64, // jobseeker_id from 64recs
      };

      console.log(eventsPayload);
      dispatch(SendEventsByMessage(eventsPayload));

      // Update cache here...
      if ( (!isMobile || currentPage[0] == "main") && entityRootReducer.entityReducer) {
        const entity = entityRootReducer.entityReducer;
        const connection = {};
        connection[props.connectionId] = entity.connections[props.connectionId];
        formatConnectionDsSettings(isShow, 
          props.connectionId, connection);
        
        const companyId = entity.connections[props.connectionId].companyId;
        const [company, connections] = getEntityCompanyConnection(entity, 
          companyId)
        handleConnectionUpdate(companyId, company, connections);

        // This will set the state with changes
        dispatch({
          type: ENTITY_SUCCESS,
          payload: entity,
        });
      } else {
        if (recruiterReducer && recruiterReducer.connection) {
          formatConnectionDsSettings(isShow, props.connectionId, 
            recruiterReducer.connection);
          
          dispatch({
            type: RECRUITER_SUCCESS,
            payload: recruiterReducer.connection,
          });
        }
      }
    } catch (e) {
      dispatch(ENTITY_FAIL);
    }
    setAnchorEl(null);
  };

  const handleBackButtonClicked = () => {
    console.log("handleBackButtonClicked");
    
    const companyInfo = companyReducer.companyInfo;
    if (recruiterReducer && recruiterReducer.connection && companyInfo)
    {
      companyInfo.connections[props.connectionId] = 
        recruiterReducer.connection[props.connectionId];

      const companyId = recruiterReducer.connection[props.connectionId].companyId;
      const [company, connections] = getCompanyConnection(companyInfo, companyId);
      
      handleConnectionUpdate(companyId, company, 
        connections);
      
      dispatch({
        type: COMPANY_SUCCESS,
        payload: companyInfo,
      });
    }
  }

  return !isMobile ? (
    <>
      <Box
        display="flex"
        sx={{
          p: 2,
          border:
            props.entity.toLowerCase() == "recruiter"
              ? "1px solid #E4E6EF"
              : "none",
        }}
      >
        <Stack direction="column" sx={{ width: "100%" }}>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
            sx={{ py: "10px", px: "0px" }}
          >
            <BasicInfoSection
              fullname={props.fullname}
              positionTitle={props.positionTitle}
              email={props.email}
              phone={props.phone}
              connectionEstablished={props.connectionEstablished}
              entity={props.entity}
              connectionId={props.connectionId}
              isConnected={props.isConnected}
              profileAccessEnabled={props.profileAccess}
              showProfile={props.showProfile}
              showResume={props.showResume}
            />
            <DataSharingSection
              profileAccess={props.profileAccess}
              contactInfo={props.contactInfo}
              isConnected={props.isConnected}
              connectionId={props.connectionId}
              entity={props.entity}
            />
            <StatusSection
              isConnected={props.isConnected}
              status={
                props.status.toLowerCase() == "accepted"
                  ? "Connected"
                  : "Disconnected"
              }
              connectionId={props.connectionId}
              companyId={props.companyId}
              entity={props.entity}
              fullname={props.fullname}
            />
          </Grid>
        </Stack>
      </Box>
      <ConnectionEstablished
        entityType={props.entity}
        connectionEstablished={props.connectionEstablished}
      />
    </>
  ) : showIndividual ?
    <div>
      <Dialog
        fullScreen
        aria-labelledby="customized-dialog-title"
        open={true}
        // scroll="body"
        TransitionComponent={Transition}
        key={props.uniqueKey}
      >
        <DialogTitle id="customized-dialog-title" sx={{ ml: -3, mt: -3 }}>
          <Box
            sx={{
              background: `url('${headerImage}')`,
              // backgroundSize: `${windowWidth}+px, ${windowHeight}*0.4+px`,
              backgroundSize: isPortrait ? `100% 100%` : `cover`,
              height: 230,
              width: windowWidth,
            }}
          >
            <IconButton
              onClick={() => {
                  setGlobalState("currentPage", "company");
                  handleBackButtonClicked();
                  setshowIndividual(false);
                }
              }
              sx={{
                position: "absolute",
                left: 15,
                top: 15,
                background: "black",
                width: "30px",
                height: "30px",
                opacity: 0.5,
              }}
            >
              <ArrowBackIosIcon
                sx={{
                  color: "white",
                  width: "25px",
                  height: "25px",
                  pl: "10px",
                  opacity: 2,
                }}
              ></ArrowBackIosIcon>
            </IconButton>
            <IconButton
              sx={{
                position: "absolute",
                right: 15,
                top: 15,
                background: "black",
                width: "30px",
                height: "30px",
                opacity: 0.5,
              }}
              onClick={(event) => {
                setAnchorEl(event.currentTarget);
                // showisBlock = true
              }}
            >
              <MoreHorizIcon
                sx={{
                  color: "white",
                  width: "25px",
                  height: "25px",
                  // pl: "5px",
                  opacity: 2,
                }}
              ></MoreHorizIcon>
            </IconButton>
            {
              recruiterReducer.connection && (
                recruiterReducer.connection[props.connectionId].isBlocked ?
                <UnBlockStatusMenu
                    isConnected={recruiterReducer.connection[props.connectionId].isConnected}
                    category="individual"
                    connectionId={props.connectionId}
                    recruiterName={props.fullname}
                    isButton={false}
                  />
                :
                <StatusMenu
                    isConnected={recruiterReducer.connection[props.connectionId].isConnected}
                    category="individual"
                    connectionId={props.connectionId}
                    isBlocked={recruiterReducer.connection[props.connectionId].isBlocked}
                    fullname={props.fullname}
                  />
              )
            }
          </Box>
          <Box className={css.dataSharingSettingsModal}>
            <Avatar
              src={defaultUser}
              sx={{
                width: "60px",
                height: "60px",
                position: "relative",
                left: 15,
                top: -30,
              }}
            />
            {
              recruiterReducer.connection && (
                recruiterReducer.connection[props.connectionId].isBlocked || 
                recruiterReducer.connection[props.connectionId].status.toLowerCase() == "pending" ?
                    <Grid container direction="column" sx={{ pl: "15px" }}>
                      <Typography className={css.primaryName} sx={{ pb: "5px" }}>
                      {props.fullname}
                    </Typography>
                    <Typography
                      sx={{ fontSize: 15, fontWeight: "light", pb: "5px" }}
                    >
                      {/* {props.compLocation.replace(/,(\s+)?$/, "")} */}
                      {""}
                    </Typography>
                      <Typography
                        sx={{
                          fontSize: 15,
                          fontWeight: "light",
                          pb: "5px",
                        }}
                      >
                          <Brightness1RoundedIcon className={recruiterReducer.connection[props.connectionId].isBlocked ? css.dotDanger : 
                            (recruiterReducer.connection[props.connectionId].status.toLowerCase() == "pending" ? css.dotWarning : css.dotGrey)} 
                            sx={{marginLeft:"0px !important"}}/>
                          {recruiterReducer.connection[props.connectionId].isBlocked ? "Blocked" : 
                            (recruiterReducer.connection[props.connectionId].status.toLowerCase() == "pending" ? "Pending" : "Unknown")}
                        </Typography>
                    </Grid>
                    :
                    <Grid container direction="column" sx={{ pl: "15px" }}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    sx={{ position: "relative", top: -20 }}
                  >
                    {recruiterReducer.connection[props.connectionId].isConnected ? (
                      <>
                        <Button
                          variant="outlined"
                          sx={{
                            color: "#2F86DD",
                            borderColor: "#2F86DD",
                            borderRadius: "25px",
                            textTransform: "none",
                          }}
                          onClick={(event) => {
                            setShowConnectStatus(true);
                            setAnchorEl(event.currentTarget);
                          }}
                        >
                          Connected
                        </Button>
                        {showConnectStatus && (
                          <Popover
                            open={true}
                            onClose={() => {
                              setShowConnectStatus(false);
                            }}
                            anchorEl={anchorEl}
                            sx={{
                              alignItems: "center",
                            }}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left",
                            }}
                          >
                            <Typography
                              sx={{ m: 2, color: "red" }}
                              onClick={() => {
                                handleChange("Disconnect");
                              }}
                            >
                          Disconnect {props.fullname}
                        </Typography>
                      </Popover>
                    )}
                  </>
                ) : (
                  <Button
                    variant="contained"
                    sx={{
                      color: "white",
                      borderColor: "black",
                      borderRadius: "25px",
                      textTransform: "none",
                    }}
                    onClick={() => {
                      handleChange("Connect");
                      setShowConnectStatus(false);
                    }}
                  >
                    Connect
                  </Button>
                )}
              </Grid>
              <Typography className={css.primaryName} sx={{ pb: "5px" }}>
                {props.fullname}
              </Typography>
              <Typography sx={{ fontSize: 15, fontWeight: "light", pb: "5px" }}>
                {/* {props.fullname.replace(/,(\s+)?$/, "")} */}
                {""}
              </Typography>
                  {recruiterReducer.connection[props.connectionId].isConnected ? (
                    <Typography
                      sx={{
                        fontSize: 15,
                        fontWeight: "light",
                        // pb: "5px",
                      }}
                    >
                      <CalendarTodayIcon
                        sx={{ width: 15, height: 15 }}
                      ></CalendarTodayIcon>
                      {"  Connected: " +
                    new Date(props.connectionEstablished).toLocaleString(
                      "default",
                      { month: "long" }
                    ) +
                    " " +
                    new Date(props.connectionEstablished).getFullYear()}
                </Typography>
              ) : (
                <Typography
                  sx={{
                    fontSize: 15,
                    fontWeight: "light",
                    // pb: "5px",
                  }}
                >
                  <Brightness1RoundedIcon className={css.dotGrey} />
                  Disconnected
                </Typography>
              )}
            </Grid>
                )
              }
          </Box>
        </DialogTitle>
        <DialogContent sx={{ ml: "-10px", overflow: `unset` }}>
        <hr />
        {
          recruiterReducer.connection && (
            recruiterReducer.connection[props.connectionId].isBlocked 
            ?
              <Grid
                container
                direction="column"
                justifyContent="flex-end"
                sx={{ position: "relative", top: 10 }}
              >
                <Grid
                      container
                      direction="row"
                      justifyContent="flex"
                      sx={{ position: "relative", pl: 2 }}
                    >
                              <Typography>Recruiter {props.fullname} can no longer communicate with you via JobTarget, nor can they see your Profile</Typography>
                          </Grid>
                          <UnBlockStatusMenu
                      isConnected={recruiterReducer.connection[props.connectionId].isConnected}
                      category="individual"
                            connectionId={props.connectionId}
                            recruiterName={props.fullname}
                            isButton={true}
                          /> 
              </Grid>
            :
              <Grid
                container
                // spacing={{ xs: 2, md: 3 }}
                // columns={{ xs: 12, sm: 8, md: 12 }}
              >
                <Grid
                  item
                  // xs={10}
                  container
                  spacing={0}
                  direction="row"
                  alignItems="left"
                  justifyContent="left"
                >
                  <Stack
                    direction="column"
                    spacing={1}
                    width="100%"
                    alignItems="start"
                  >
                    <Typography className={css.dataSharingSettings}>
                      Contact Info
                    </Typography>
                    <Typography
                      sx={{ fontSize: 15, fontWeight: "light", pb: "5px" }}
                    >
                      {!(recruiterReducer.connection[props.connectionId].status.toLowerCase() == "pending") ? 
                        props.email 
                        : 
                        (recruiterReducer.connection[props.connectionId].profileAccessEnabled || 
                          recruiterReducer.connection[props.connectionId].status.toLowerCase() == "pending") ? 
                            props.email :  "*************************"}
                    </Typography>
                    <Typography
                      sx={{ fontSize: 15, fontWeight: "light", pb: "5px" }}
                    >
                      {!(recruiterReducer.connection[props.connectionId].status.toLowerCase() == "pending") ? 
                        props.phone 
                        : 
                        (recruiterReducer.connection[props.connectionId].contactInfoEnabled || 
                          recruiterReducer.connection[props.connectionId].status.toLowerCase() == "pending") ? 
                            props.phone :  "*** *** ****"}
                    </Typography>
                  </Stack>
                </Grid>
                {recruiterReducer.connection[props.connectionId].isConnected && (
                  <>
                    <Divider
                      sx={{ width: "116%", ml: "-10%", my: "10px" }}
                    ></Divider>
                    <Grid
                      item
                      // xs={10}
                      container
                      spacing={0}
                      direction="row"
                      alignItems="left"
                      justifyContent="left"
                    >
                      <Stack
                        direction="column"
                        spacing={1}
                        width="100%"
                        alignItems="center"
                      >
                        <Typography
                          className={css.dataSharingSettings}
                          alignSelf="start"
                        >
                          Data Sharing Settings
                        </Typography>
    
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                        >
                          <Typography className={css.normalText400}>
                            Profile Access:
                          </Typography>
                          <Typography className={css.normalText400}>
                            {recruiterReducer.connection[props.connectionId].profileAccessEnabled ? "On" : "Off"}
                            <Brightness1RoundedIcon
                              className={
                                recruiterReducer.connection[props.connectionId].profileAccessEnabled ? css.dotSuccess : css.dotDanger
                              }
                            />
                          </Typography>
                        </Grid>
    
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                        >
                          <Typography className={css.normalText400}>
                            Contact Info:
                          </Typography>
                          <Typography className={css.normalText400}>
                            {recruiterReducer.connection[props.connectionId].contactInfoEnabled ? "On" : "Off"}
                            <Brightness1RoundedIcon
                              className={
                                recruiterReducer.connection[props.connectionId].contactInfoEnabled ? css.dotSuccess : css.dotDanger
                              }
                            />
                          </Typography>
                        </Grid>
                        <Grid
                          container
                        >
                          <DataSharingModal
                            connectionId={props.connectionId}
                            isConnected={recruiterReducer.connection[props.connectionId].isConnected}
                            profileAccess={recruiterReducer.connection[props.connectionId].profileAccessEnabled}
                            contactInfo={recruiterReducer.connection[props.connectionId].contactInfoEnabled}
                          />
                        </Grid>
                      </Stack>
                    </Grid>
                    <Divider
                      sx={{ width: "116%", ml: "-10%", my: "10px" }}
                    ></Divider>
                  </>
                )}
              </Grid>
          )
        }
      { recruiterReducer.connection && 
        recruiterReducer?.connection[props.connectionId].status.toLowerCase() == "pending" && isMobile &&
        <>
          <hr />
          <Box
          sx={{
            border: "1px solid #FFA800",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "flex-start",
            padding: "10px",
            borderRadius: '24px',
          }}>
            <Grid
              m={2}
              container
              direction="column"
              justifyContent="flex-end"
              sx={{ position: "relative"}}
            >
              <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  sx={{ position: "relative"}}
                >
                    <Typography>
                      Recruiter {props.fullname} is trying to connect with you.
                    </Typography>
                </Grid>
                <Stack 
                  spacing={2} 
                  direction="row"
                  pt={2}>
                    <Button
                      variant="contained"
                      sx={{
                        color: "white",
                        borderColor: "black",
                        borderRadius: "25px",
                        textTransform: "none",
                        width: "100%",
                      }}
                      onClick={() => {
                        handleChange("Connect");
                      }}
                    >
                      Accept
                    </Button>
                    <Button
                      variant="outlined"
                      sx={{
                        color: "#2F86DD",
                        borderColor: "#2F86DD",
                        borderRadius: "25px",
                        textTransform: "none",
                        width: "100%",
                      }}
                      onClick={() => {
                        handleChange("Disconnect");
                      }}
                    >
                      Decline
                    </Button>
                </Stack>
            </Grid>
          </Box>
        </>
      }
      </DialogContent>
      </Dialog>
    </div>
  : (
    <>
      <Stack
        direction="column"
        sx={{width: !isPortrait ? 240 : (windowWidth - 20) / 2, padding: 0.5}}
        onClick={personIndividualStatusHandler}
      >
        <Box
        sx={{
          border: "1px solid #E4E6EF",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          padding: (props.fullname.length <= 17)  ? "24px 16px" : "14px 16px",
          backgroundColor: "#F8F9FA",
          borderRadius: '24px',
          height: '220px',
          width: '100%'
        }}>
          <Stack
            direction="column"
            spacing={2}
            alignItems="center"
            sx={{ width: "100%", height: "200px", position: "relative" }}
          >
            {/* <Avatar
              src={defaultUser}
              sx={{
                width: "60px",
                height: "60px",
                bgcolor: "primary.main",
                mt: 5
              }}
            /> */}
            <RoundedNameLogo name={companyReducer?.companyInfo?.connections[props.connectionId].firstName + " " +
                companyReducer?.companyInfo?.connections[props.connectionId].lastName}/>
            <Typography
              className={css.primaryName}
              sx={{
                width: (windowWidth - 50) / 2 + "px",
                justifyContent: "center",
              }}
            >
              {props.fullname}
            </Typography>
            {
              companyReducer.companyInfo && <>
                <Typography 
                sx={{ 
                  bottom: "10px", 
                  my: "10px", 
                  position: "absolute",
                  fontSize: "15px",
                }}>
                  <Brightness1RoundedIcon
                    className={companyReducer.companyInfo.connections[props.connectionId].isBlocked ? css.dotDanger : 
                      (companyReducer.companyInfo.connections[props.connectionId].status == "pending" ? css.dotWarning : 
                        (companyReducer.companyInfo.connections[props.connectionId].isConnected ? css.dotSuccess : css.dotGrey))}
                  />
                  {
                    companyReducer.companyInfo.connections[props.connectionId].isBlocked ? "Blocked" : 
                    companyReducer.companyInfo.connections[props.connectionId].status.toLowerCase() == "pending" ? "Pending" :
                    companyReducer.companyInfo.connections[props.connectionId].isConnected ? "Connected" : "Disconnected"
                  }
                </Typography>
              </>
            }
          </Stack>
        </Box>
      </Stack>
      <ConnectionEstablished
        entityType={props.entity}
        connectionEstablished={companyReducer.companyInfo?.company[props.companyId].connectionEstablished}
      />
    </>
  );
};

export default IndividualEntity;
