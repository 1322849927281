import AddCircleOutline from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useSelector } from "react-redux";
import { RootStore } from "../store/Store";
import { useStyles } from "../style/styles";
import TabConnIndividual from "./TabConnIndividual";

// const css = useStyles();

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    // expandIcon={
    //   expanded ? (
    //     <RemoveCircleOutlineIcon
    //       sx={{ width: "23.25px", height: "23.25px", color: "#2F86DD" }}
    //     />
    //   ) : (
    //     <AddCircleOutline
    //       sx={{ width: "23.25px", height: "23.25px", color: "#2F86DD" }}
    //     />
    //   )
    // }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "#EAF3FC", //"rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
  height: "40px",
  minHeight: "40px",
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

// interface IConnection {
//   connId: string;
// }

export default function CustomizedAccordions(props) {
  const css = useStyles();
  const [expanded, setExpanded] = React.useState<string | false>(false); // Change "panel1" inside the parenthesis to expand all the accordion by default page load.
  const entityState = useSelector(
    (state: RootStore) => state.entityRootReducer
  );
  // console.log(entity.companyName);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
      // console.log(expanded);
    };

  return (
    <div>
      {entityState.entityReducer && (
        <>
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
            sx={{marginBottom: "15px"}}
            key={props.connId}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              expandIcon={
                expanded ? (
                  <RemoveCircleOutlineIcon
                    sx={{
                      width: "23.25px",
                      height: "23.25px",
                      color: "#2F86DD",
                    }}
                  />
                ) : (
                  <AddCircleOutline
                    sx={{
                      width: "23.25px",
                      height: "23.25px",
                      color: "#2F86DD",
                    }}
                  />
                )
              }
            >
              <Stack direction="row" spacing={1}>
                <Typography className={css.accordionIndiv}>
                  Individual Connections ({props.hideCountBlocked ? parseInt(props.totalConnPerCompany) - props.hideCountBlocked : props.totalConnPerCompany})
                </Typography>
              </Stack>
            </AccordionSummary>
            <AccordionDetails className={css.accordionContent} key={props.connId}>
              <TabConnIndividual
                connId={props.connId}
                count={props.count}
                totalConnPerCompany={props.totalConnPerCompany}
                hideCountBlocked={props.hideCountBlocked}
              />
            </AccordionDetails>
          </Accordion>
        </>
      )}
    </div>
  );
}
