import { observable } from "mobx";
import { createContext } from "react";

class EntitiesStore {
  @observable entityId: string = "";
  @observable entityId64: string = "";
  @observable entityType: string = "";
  @observable connectionId: string = "";
}
export const EntitiesStoreContext = createContext(new EntitiesStore());
