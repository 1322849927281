import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import defaultUser from "../../assets/images/default_user.png";
import { useStyles } from "../../style/styles";

const IndividualPending = (props) => {
  const css = useStyles();
  var color = props.backgroundColor == "#FFF4DE" ? "#FFF4DE" : "#FFFFFF";

  return (
    <Paper
      className={css.paperBox}
      variant="outlined"
      square
      sx={{ backgroundColor: color, mb: 1 }}
    >
      <Grid container key={props.uniquekey} sx={{ py: "10px", px: "12px" }}>
        <Grid
          item
          xs={1}
          container
          spacing={0}
          direction="column"
          alignItems="left"
          justifyContent="center"
        >
          <Avatar
            src={defaultUser}
            sx={{ width: "60px", height: "60px", bgcolor: "primary.main" }}
          />
        </Grid>
        <Grid item xs={5}>
          <Stack direction="column" spacing={1}>
            <Typography className={css.primaryName}>
              {props.fullname}
            </Typography>
            {props.positionTitle ? (
              <Typography className={css.normalText400}>
                {props.positionTitle}
              </Typography>
            ) : (
              ""
            )}
            {/* <Typography className={css.normalText400}>
              {props.contact}
            </Typography> */}
            {/* <Typography className={css.normalText400}>
              Connection Established date: {props.connectionEstablished}
            </Typography> */}
          </Stack>
        </Grid>
        <Grid
          item
          xs={6}
          container
          columnSpacing={0}
          direction="row"
          alignItems="right"
          justifyContent="right"
        >
          <Grid
            item
            xs={3}
            container
            spacing={0}
            direction="column"
            alignItems="right"
            justifyContent="right"
          >
            <Stack
              direction="row"
              spacing={1}
              alignItems="right"
              justifyContent="right"
              sx={{ pr: "30px" }}
            >
              <Typography className={css.normalText400}>
              Waiting for jobseeker to accept your connection request.
              </Typography>
            </Stack>

            <Stack
              spacing={2}
              direction="row"
              alignItems="right"
              justifyContent="right"
              sx={{ pr: "30px", pt: "10px" }}
            >
              <Typography
                className={
                  props.isConnected ? css.activeStatus : css.InActiveStatus
                }
              >
                Pending
              </Typography>
              {/* <Button
                variant="text"
                className={css.cancelTextBtn}
                //   onClick={handleClose}
              >
                Decline
              </Button>
              <Button variant="contained" className={css.saveBtn}>
                Accept Connection
              </Button> */}
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default IndividualPending;
