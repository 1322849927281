import { Grid } from '@mui/material';
import React from 'react';
import { useStyles } from "../style/styles";

const RoundedNameLogo = ({name}) => {
  const css = useStyles();
  const initials = name
    .split(' ')
    .map(word => word.charAt(0))
    .join('')
    .toUpperCase();
  
  const randomColor = () => {
    const colors = ['#E91E63', '#9C27B0', '#673AB7', '#3F51B5', '#2196F3', 
    '#009688', '#4CAF50', '#FFC107', '#FF5722', '#795548'];

    const lighColors = ['#f6a5c1', '#cf71df', '#b299df', '#a0a9de', '#b4dcfb', 
    '#00ebd5' , '#addcaf', '#ffe69a', '#ffd2c4', '#cdb4ab'];
    const ranNum = Math.floor(Math.random() * colors.length);
    return [colors[ranNum], lighColors[ranNum]];
  }

  const [borderColor, backgroundColor] = randomColor();
  
  return (
    <Grid className={css.roundedNamelogo} sx= {{
        background: backgroundColor,
        border: 2,
        borderColor: borderColor,
        }}>
      <span style={{color: borderColor}}>{initials}</span>
    </Grid>
  );
}

export default RoundedNameLogo;
