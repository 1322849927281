import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useContext, useState } from "react";
import { Dimensions } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { GetDsSettings, UpdateDsSettings } from "../action/DsSettingsAction";
import { ENTITY_SUCCESS } from "../action/EntityActionType";
import { RECRUITER_SUCCESS } from "../action/RecruiterActionTypes";
import { EntitiesStoreContext } from "../store/EntitiesStore";
import { RootStore } from "../store/Store";
import { useStyles } from "../style/styles";
import { formatConnectionDsSettings, getEntityCompanyConnection, handleConnectionUpdate } from "../utils/EntityFunctions";
import { useGlobalState } from "../utils/GlobalState";
import { AntSwitch } from "./Switch";
import { Box, CssBaseline, Divider, SwipeableDrawer } from "@mui/material";
import { grey } from "@mui/material/colors";
import { Global } from "@emotion/react";

const Puller = styled(Box)(({ theme }) => ({
  width: '50%',
  height: 4,
  backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
  borderRadius: 3,
  position: 'absolute',
  top: 8,
  left: 'calc(50% / 2)',
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800],
}));

const drawerBleeding = 56;

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const DrawerAutoHeight = styled(SwipeableDrawer)(({ theme }) => ({
  "& .MuiPaper-root": {
    height: 'auto',
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

interface IDataSharing {
  connectionId: string;
  isConnected: boolean;
  profileAccess: boolean;
  contactInfo: boolean;
}

const DataSharingModal = (props: IDataSharing) => {
  const css = useStyles();
  const entityStore = useContext(EntitiesStoreContext);
  const dispatch = useDispatch();

  const handleClose = () => {
    setShowDsModal(false);
  };

  const {entityRootReducer, companyReducer, recruiterReducer} = useSelector(
    (state: RootStore) => state
  );

  const { isMobile, isPortrait, windowWidth } = useSelector(
    (state: RootStore) => state.deviceReducer.deviceData
  );

  const [showDsModal, setShowDsModal] = useState(false);
  const [showResume, setshowResume] = useState(false);
  const [showProfile, setshowProfile] = useState(false);
  const [showAddress, setshowAddress] = useState(false);
  const [showEmail, setshowEmail] = useState(false);
  const [showPhone, setshowPhone] = useState(false);
  
  const currentPage = useGlobalState("currentPage");

  const getCompanyData = () => {
    if ((!isMobile || currentPage[0] == "main") && entityRootReducer.entityReducer &&
        props.connectionId.toString() in entityRootReducer.entityReducer.companies)
      return entityRootReducer.entityReducer?.companies[props.connectionId];
    else if (companyReducer.companyInfo &&
        props.connectionId.toString() in companyReducer.companyInfo.company)
      return companyReducer.companyInfo?.company[props.connectionId];

    return {};
  };

  const getRecruiterData = () => {
    if ((!isMobile || currentPage[0] == "main") && entityRootReducer.entityReducer &&
        props.connectionId.toString() in entityRootReducer.entityReducer.connections)
      return entityRootReducer.entityReducer?.connections[props.connectionId];
    else if (recruiterReducer && recruiterReducer.connection &&
        props.connectionId.toString() in recruiterReducer.connection)
      return recruiterReducer?.connection[props.connectionId];

    return {};
  };

  // const [companyData, setCompanyData] = useState(getCompanyData());
  const [recruiterData, setrecruiterData] = useState(getRecruiterData());

  const changeShowResumeProfile = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setshowResume(checked);
    setshowProfile(checked);
  };
  const changeShowAddress = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setshowAddress(checked);
  };
  const changeShowEmail = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setshowEmail(checked);
  };
  const changeShowPhone = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setshowPhone(checked);
  };

  const handleShowModal = () => {
    // const dsSettingsSelfCompany = companyData?.dsSettingsSelf;
    const dsSettingsSelfIndividual = recruiterData?.dsSettingsSelf;

    // Company Data Sharing
    // if (dsSettingsSelfCompany) {
    //   console.log(dsSettingsSelfCompany);
    //   setshowResume(dsSettingsSelfCompany.showResume);
    //   setshowProfile(dsSettingsSelfCompany.showProfile);
    //   setshowAddress(dsSettingsSelfCompany.showAddress);
    //   setshowEmail(dsSettingsSelfCompany.showEmail);
    //   setshowPhone(dsSettingsSelfCompany.showPhone);
    //   console.log(
    //     "MODAL COMPANY: EMAIL " +
    //       showEmail +
    //       " DESSETTINGS EMAIL: " +
    //       dsSettingsSelfCompany.showEmail
    //   );
    // } else
    if (dsSettingsSelfIndividual) {
      console.log("MODAL INDIVIDUAL");
      // Individual level from entity response
      setshowResume(dsSettingsSelfIndividual.showResume);
      setshowProfile(dsSettingsSelfIndividual.showProfile);
      setshowAddress(dsSettingsSelfIndividual.showAddress);
      setshowEmail(dsSettingsSelfIndividual.showEmail);
      setshowPhone(dsSettingsSelfIndividual.showPhone);
    } else {
      dispatch(GetDsSettings(entityStore.entityId, props.connectionId)); // indvidual level from data sharing endpoint response (need to check this response)
    }
    setShowDsModal(true);
  };

  const handleSave = () => {
    // const dsSettingsSelfCompany = companyData?.dsSettingsSelf;
    const dsSettingsSelfIndividual = recruiterData?.dsSettingsSelf;
    let isNeedConnectionUpdate = false;

    const payloads = {
      entityId: entityStore.entityId,
      connectionId: props.connectionId,
      showEmail: showEmail,
      showAddress: showAddress,
      showPhone: showPhone,
      showResume: showResume,
      showProfile: showProfile,
    };
    
    if (dsSettingsSelfIndividual) {
      console.log("DSSETTING PAYLOAD: ");
      console.log(payloads);
        // Update the DSSettings
      dispatch(UpdateDsSettings(payloads));

      console.log("INDIVIDUAL");
      // It will update the entity state
      if (recruiterData) {
        recruiterData.profileAccessEnabled = showProfile;
        recruiterData.contactInfoEnabled = false; 
        if (
          showEmail ||
          showPhone ||
          showAddress ||
          (showResume && showProfile)
        ) {
          if (showEmail ||
              showPhone ||
              showAddress) {
              recruiterData.contactInfoEnabled = true;
          }

          recruiterData.isConnected = true;
          recruiterData.status = "accepted";
          recruiterData.dsSettingsSelf.showProfile = showProfile;
          recruiterData.dsSettingsSelf.showResume = showResume;
          recruiterData.dsSettingsSelf.showAddress = showAddress;
          recruiterData.dsSettingsSelf.showEmail = showEmail;
          recruiterData.dsSettingsSelf.showPhone = showPhone;
          isNeedConnectionUpdate = true;
        } else {
          recruiterData.isConnected = false;
          recruiterData.status = "declined";
          isNeedConnectionUpdate = true;
        }
      }

      if (isNeedConnectionUpdate) {
        recruiterData.dsSettingsLink.showEmail = recruiterData.isConnected;
        recruiterData.dsSettingsLink.showProfile = false;
        recruiterData.dsSettingsLink.showResume = false;
        recruiterData.dsSettingsLink.showAddress = false;
        recruiterData.dsSettingsLink.showPhone = false;
      }

      if (isMobile) {        
        dispatch({
          type: RECRUITER_SUCCESS,
          payload: recruiterReducer.connection,
        });
      } else if (entityRootReducer.entityReducer) {
        entityRootReducer.entityReducer.connections[props.connectionId] = recruiterData;

        const [company, connections] = getEntityCompanyConnection(entityRootReducer.entityReducer, 
          recruiterData.companyId);
        handleConnectionUpdate(recruiterData.companyId, company, connections);
      }

      dispatch({
        type: ENTITY_SUCCESS,
        payload: entityRootReducer.entityReducer,
      });
    }

    if (!isMobile || !isPortrait)
      setShowDsModal(false);
  };

  React.useEffect(() => {
    isMobile && isPortrait && showDsModal && handleSave();
  },[showEmail, showPhone, showAddress, showResume,showProfile]);

  return (
    <>
      {
        !isMobile ? 
        <Link
          href="#"
          onClick={event => {event.preventDefault(); handleShowModal();}}
          className={props.isConnected ? css.enabledLink : css.disabledLink}
          underline="none"
        >
          <Typography sx={{ fontWeight: "normal", fontFamily: 'Poppins' }}>
            View / Manage Settings
          </Typography>
        </Link>
        :
        <Button
          onClick={event => {event.preventDefault(); handleShowModal();}}
          className={props.isConnected ? css.enabledLink : css.disabledLink}
          variant="outlined"
          sx={{
            borderRadius: "25px",
            textTransform: "none",
          }}
          fullWidth={true}
        >
          <Typography sx={{ fontWeight: "normal", fontFamily: 'Poppins' }}>
            Manage Settings
          </Typography>
        </Button>
      }
      {!isMobile || !isPortrait ? 
      <>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={showDsModal}
          // sx={{ minWidth: "498px" }}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          >
            <Typography className={css.dataSharingSettingsModal}>
              Data Sharing Settings
            </Typography>
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 12, sm: 8, md: 12 }}
            >
              <Grid
                item
                xs={10}
                container
                spacing={0}
                direction="row"
                alignItems="left"
                justifyContent="left"
                display={
                  entityRootReducer.entityReducer?.type.toLowerCase() == "jobseeker"
                    ? "display"
                    : "none"
                }
              >
                <Typography className={css.normalText400} sx={{}}>
                  Resume / Profile Access
                </Typography>
              </Grid>
              <Grid
                item
                xs={2}
                container
                spacing={0}
                direction="row"
                alignItems="right"
                justifyContent="right"
                display={
                  entityRootReducer.entityReducer?.type.toLowerCase() == "jobseeker"
                    ? "display"
                    : "none"
                }
              >
                <AntSwitch
                  inputProps={{ "aria-label": "ant design" }}
                  checked={showProfile}
                  onChange={changeShowResumeProfile}
                />
              </Grid>
              <Grid
                item
                xs={10}
                container
                spacing={0}
                direction="row"
                alignItems="left"
                justifyContent="left"
              >
                <Typography className={css.normalText500}>
                  Specific Contact Info
                </Typography>
              </Grid>
              <Grid
                item
                xs={2}
                container
                spacing={0}
                direction="row"
                alignItems="right"
                justifyContent="right"
              ></Grid>
              <Grid
                item
                xs={10}
                container
                spacing={0}
                direction="row"
                alignItems="left"
                justifyContent="left"
              >
                <Typography className={css.normalText400} sx={{ pl: "20px" }}>
                  Address
                </Typography>
              </Grid>
              <Grid
                item
                xs={2}
                container
                spacing={0}
                direction="row"
                alignItems="right"
                justifyContent="right"
              >
                {/* <CustomizedSwitches
                  switchKey="2"
                  check={showAddress}
                  changeState={changeShowAddress}
                /> */}
                <AntSwitch
                  inputProps={{ "aria-label": "ant design" }}
                  // key={switchKey}
                  checked={showAddress}
                  onChange={changeShowAddress}
                />
              </Grid>
              <Grid
                item
                xs={10}
                container
                spacing={0}
                direction="row"
                alignItems="left"
                justifyContent="left"
              >
                <Typography className={css.normalText400} sx={{ pl: "20px" }}>
                  Email
                </Typography>
              </Grid>
              <Grid
                item
                xs={2}
                container
                spacing={0}
                direction="row"
                alignItems="right"
                justifyContent="right"
              >
                {/* <CustomizedSwitches
                  switchKey="3"
                  check={showEmail}
                  changeState={changeShowEmail}
                /> */}
                <AntSwitch
                  inputProps={{ "aria-label": "ant design" }}
                  // key={switchKey}
                  checked={showEmail}
                  onChange={changeShowEmail}
                />
              </Grid>
              <Grid
                item
                xs={10}
                container
                spacing={0}
                direction="row"
                alignItems="left"
                justifyContent="left"
              >
                <Typography className={css.normalText400} sx={{ pl: "20px" }}>
                  Phone
                </Typography>
              </Grid>
              <Grid
                item
                xs={2}
                container
                spacing={0}
                direction="row"
                alignItems="right"
                justifyContent="right"
              >
                {/* <CustomizedSwitches
                  switchKey="4"
                  check={showPhone}
                  changeState={changeShowPhone}
                /> */}
                <AntSwitch
                  inputProps={{ "aria-label": "ant design" }}
                  // key={switchKey}
                  checked={showPhone}
                  onChange={changeShowPhone}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            {/* <Button autoFocus onClick={handleClose}>
              Save changes
            </Button> */}
            <Stack spacing={2} direction="row">
              <Button
                variant="text"
                className={css.cancelTextBtn}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                className={css.saveBtn}
                onClick={handleSave}
              >
                Save
              </Button>
            </Stack>
          </DialogActions>
        </BootstrapDialog>
      </> : 
      <>
        <CssBaseline />
        <Global
          styles={{
            '.MuiDrawer-root > .MuiPaper-root': {
              height: `calc(50% - ${drawerBleeding}px)`,
              overflow: 'visible',
            },
          }}
        />
        <DrawerAutoHeight
          anchor="bottom"
          open={showDsModal}
          onClose={handleClose}
          onOpen={handleShowModal}
          swipeAreaWidth={drawerBleeding}
          disableSwipeToOpen={false}
          sx={{ zIndex: 1300 }}
        >
          <StyledBox
            sx={{
              position: 'absolute',
              top: -drawerBleeding,
              borderTopLeftRadius: 30,
              borderTopRightRadius: 30,
              visibility: 'visible',
              right: 0,
              left: 0,
            }}
          >
            <Puller />
            <Typography 
            align="center" 
            className={css.dataSharingSettingsModal} 
            sx={{ px: 4, pt: 3, pb: 1}}>
              Data Sharing Settings
            </Typography>
            <Typography
            align="center">
              Choose the contact information you would like to share with recruiters.
              <Divider variant="middle" />
            </Typography>
          </StyledBox>
          <StyledBox
            sx={{
              px: 2,
              pb: 2,
            }}
          >
            <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 12, sm: 8, md: 12 }}
                sx={{ pt: 10}}
              >
              <Grid
                item
                xs={10}
                container
                spacing={0}
                direction="row"
                alignItems="left"
                justifyContent="left"
                display={
                  entityRootReducer.entityReducer?.type.toLowerCase() == "jobseeker"
                    ? "display"
                    : "none"
                }
              >
                <Typography className={css.normalText400} sx={{}}>
                  Resume / Profile Access
                </Typography>
              </Grid>
              <Grid
                item
                xs={2}
                container
                spacing={0}
                direction="row"
                alignItems="right"
                justifyContent="right"
                display={
                  entityRootReducer.entityReducer?.type.toLowerCase() == "jobseeker"
                    ? "display"
                    : "none"
                }
              >
                <AntSwitch
                  inputProps={{ "aria-label": "ant design" }}
                  checked={showProfile}
                  onChange={changeShowResumeProfile}
                />
              </Grid>
              <Grid
                item
                xs={10}
                container
                spacing={0}
                direction="row"
                alignItems="left"
                justifyContent="left"
              >
                <Typography className={css.normalText500}>
                  Specific Contact Info
                </Typography>
              </Grid>
              <Grid
                item
                xs={2}
                container
                spacing={0}
                direction="row"
                alignItems="right"
                justifyContent="right"
              ></Grid>
              <Grid
                item
                xs={10}
                container
                spacing={0}
                direction="row"
                alignItems="left"
                justifyContent="left"
              >
                <Typography className={css.normalText400} sx={{ pl: "20px" }}>
                  Address
                </Typography>
              </Grid>
              <Grid
                item
                xs={2}
                container
                spacing={0}
                direction="row"
                alignItems="right"
                justifyContent="right"
              >
                {/* <CustomizedSwitches
                  switchKey="2"
                  check={showAddress}
                  changeState={changeShowAddress}
                /> */}
                <AntSwitch
                  inputProps={{ "aria-label": "ant design" }}
                  // key={switchKey}
                  checked={showAddress}
                  onChange={changeShowAddress}
                />
              </Grid>
              <Grid
                item
                xs={10}
                container
                spacing={0}
                direction="row"
                alignItems="left"
                justifyContent="left"
              >
                <Typography className={css.normalText400} sx={{ pl: "20px" }}>
                  Email
                </Typography>
              </Grid>
              <Grid
                item
                xs={2}
                container
                spacing={0}
                direction="row"
                alignItems="right"
                justifyContent="right"
              >
                {/* <CustomizedSwitches
                  switchKey="3"
                  check={showEmail}
                  changeState={changeShowEmail}
                /> */}
                <AntSwitch
                  inputProps={{ "aria-label": "ant design" }}
                  // key={switchKey}
                  checked={showEmail}
                  onChange={changeShowEmail}
                />
              </Grid>
              <Grid
                item
                xs={10}
                container
                spacing={0}
                direction="row"
                alignItems="left"
                justifyContent="left"
              >
                <Typography className={css.normalText400} sx={{ pl: "20px" }}>
                  Phone
                </Typography>
              </Grid>
              <Grid
                item
                xs={2}
                container
                spacing={0}
                direction="row"
                alignItems="right"
                justifyContent="right"
              >
                {/* <CustomizedSwitches
                  switchKey="4"
                  check={showPhone}
                  changeState={changeShowPhone}
                /> */}
                <AntSwitch
                  inputProps={{ "aria-label": "ant design" }}
                  // key={switchKey}
                  checked={showPhone}
                  onChange={changeShowPhone}
                />
              </Grid>
            </Grid>
          </StyledBox>
        </DrawerAutoHeight>
      </>}
    </>
  );
};

export default DataSharingModal;
