import {
    CompanyInfo,
    CompanyDispatchTypes,
    COMPANY_SUCCESS,
    COMPANY_FAIL,
    COMPANY_LOADING
 } from "../action/CompanyActionTypes";

export interface DefaultStateI {
  loading: boolean;
  companyInfo?: CompanyInfo;
}

export const defaultState: DefaultStateI = {
  loading: false,
};

const companyReducer = (
  state: DefaultStateI = defaultState,
  action: CompanyDispatchTypes
): DefaultStateI => {
  switch (action.type) {
    case COMPANY_FAIL:
      return {
        loading: false,
      };
    case COMPANY_LOADING:
      return {
        loading: true,
      };
    case COMPANY_SUCCESS:
      return {
        loading: false,
        companyInfo: action.payload,
      };
    default:
      return state;
  }
};

export default companyReducer;
