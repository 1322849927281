import MoreVertIcon from "@mui/icons-material/MoreVert";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import * as React from "react";
import { useContext } from "react";
import { Dimensions } from "react-native";
import { connect, useDispatch, useSelector } from "react-redux";
import { UpdateIsBlocked } from "../action/DsSettingsAction";
import { CompanyLevelIsBlocked, UpdateEntity } from "../action/EntityAction";
import { ENTITY_FAIL, ENTITY_SUCCESS } from "../action/EntityActionType";
import { EntitiesStoreContext } from "../store/EntitiesStore";
import { RootStore } from "../store/Store";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { setGlobalState, useGlobalState } from "../utils/GlobalState";
// import changeStatus from "../utils/StateSetter";

import { 
  formatConnectionDsSettings,
  getCompanyConnection,
  getEntityCompanyConnection,
  handleConnectionUpdate,
  handleStatusChange,
} from "../utils/EntityFunctions";
import { COMPANY_SUCCESS } from "../action/CompanyActionTypes";
import { RECRUITER_SUCCESS } from "../action/RecruiterActionTypes";

const options = ["Unblock"];

const ITEM_HEIGHT = 48;

interface IStatusMenu {
  isConnected: boolean;
  category: string;
  connectionId?: string;
  recruiterName?: string;
  companyId?: string;
  companyName?: string;
  isButton: boolean;
}

const UnBlockStatusMenu = (props: IStatusMenu) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const entityStore = useContext(EntitiesStoreContext);
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = React.useState(false);

  const {entityRootReducer , companyReducer, recruiterReducer} = useSelector(
    (state: RootStore) => state
  );

  const { isMobile } = useSelector(
    (state: RootStore) => state.deviceReducer.deviceData
  );

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleChange = (stat: string) => {
    console.log("handleChange - stat: " + stat);

    if (props.category == "individual") {
      if (stat == "Unblock") {
        const isBlockedPayload = {
          entityId: entityStore.entityId,
          connectionId: props.connectionId,
          isBlocked: false,
        };

        dispatch(UpdateIsBlocked(isBlockedPayload));

        // update cache here...
        const recruiter = {};
        const entity = entityRootReducer.entityReducer;
        if (!isMobile) {
          if (props.connectionId  && entity) {
            recruiter[props.connectionId] = 
            entity.connections[props.connectionId];

            handleStatusChange(stat, false,
                {}, {}, recruiter);
            formatConnectionDsSettings(false, props.connectionId, recruiter);
            
            const companyId = recruiter[props.connectionId].companyId;
            const [company, connections] = getEntityCompanyConnection(entity, companyId);
            handleConnectionUpdate(companyId, company, connections);

            // This will reset the entityrootreducer state with changes
            dispatch({
              type: ENTITY_SUCCESS,
              payload: entity,
            });
          }
        } else {
          if (props.connectionId && recruiterReducer && recruiterReducer.connection) {
            handleStatusChange(stat, false,
              {}, {}, recruiterReducer.connection);
          
            dispatch({
              type: RECRUITER_SUCCESS,
              payload: recruiterReducer.connection,
            });
          }
        }
      }
    } else if (props.category == "company") {
      if (stat == "Unblock") {
      const isBlockedPayload = {
        entityId: entityStore.entityId,
        connectionId: props.companyId,
        isBlocked: false,
      };
  
      dispatch(CompanyLevelIsBlocked(isBlockedPayload));

      if (!isMobile && props.companyId) {
        const companyId = props.companyId.toString();
        const [company, connections] = getEntityCompanyConnection(entityRootReducer.entityReducer, 
          companyId);
        handleStatusChange(stat, true,
          company, connections, {}, companyId);
        
        for (var connId in connections) {
          const connData = {};
          connData[connId] = connections[connId];
          formatConnectionDsSettings(false, connId, connData);
        }
        handleConnectionUpdate(companyId, company, connections);
        // This will reset the entityrootreducer state with changes
        dispatch({
          type: ENTITY_SUCCESS,
          payload: entityRootReducer.entityReducer,
        });
      } else {
        if (props.companyId) {
          const companyId = props.companyId.toString();
          const [company, connections] = getCompanyConnection(companyReducer.companyInfo, 
            companyId);
          handleStatusChange(stat, true,
            company, connections, {}, companyId);
          handleConnectionUpdate(companyId, company, connections);
          
          // This will set the companyreducer state
          dispatch({
            type: COMPANY_SUCCESS,
            payload: companyReducer.companyInfo,
          });

          // This will reset the entityrootreducer state with no changes
          dispatch({
            type: ENTITY_SUCCESS,
            payload: entityRootReducer.entityReducer,
          });
        }
      }
    }
    setAnchorEl(null);
  }
  };

  return (
   props.isButton ? 
   <Grid
      container
      direction="row"
      justifyContent="flex-end"
      sx={{ position: "relative", top: 10, p: 2 }}
    >
        <Button
          variant="contained"
          sx={{
            color: "white",
            borderColor: "black",
            borderRadius: "25px",
            textTransform: "none",
            width: "100%",
          }}
          onClick={() => {
            handleChange("Unblock")
          }}
        >
          Unblock
        </Button>
    </Grid>
    :
    <div>
      {
        isMobile ?
        <>
          <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? "long-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={() => {
              handleClick;
              setIsOpen(true);
            }
          }
          sx= {{
            position: "absolute",
            right: 15,
            top: 15,
            background: "black",
            width: "30px",
            height: "30px",
            opacity: 2,
          }}
        >
          <MoreHorizIcon
            sx={{
              color: "white",
              width: "25px",
              height: "25px",
              opacity: 2,
            }}
          />
        </IconButton>
        <Popover
          open={isOpen}
          onClose={() => {
            setIsOpen(false);
          }}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          sx={{mt:4}}
        >
          <Button 
          variant="text"
          onClick={() => {
            handleChange("Unblock");
          }}
          sx={{textTransform: 'unset', color: '#000000'}}
          >
            <Typography sx={{ m: 1 }}>
              Unblock {props.category == "company" ? props.companyName : props.recruiterName}
            </Typography>
          </Button>
        </Popover>
      </>
      :
      <>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => {
            setIsOpen(false);
            setAnchorEl(null);
          }}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option}
            onClick={() => handleChange(option)}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
      </>
    }
    </div>
  );
};

export default UnBlockStatusMenu;
