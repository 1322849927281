import { combineReducers } from "redux";
import dsSettingsReducerAction from "./DsSettingReducer";
import mainReducer from "./EntityReducer";
import companyReducer from "./CompanyReducer";
import recruiterReducer from "./RecruiterReducer";
import deviceReducer from "./DeviceReducer";

const RootReducer = combineReducers({
  entityRootReducer: mainReducer,
  dsSettingsRootReducer: dsSettingsReducerAction,
  companyReducer: companyReducer,
  recruiterReducer: recruiterReducer,
  deviceReducer: deviceReducer,
});

export default RootReducer;
