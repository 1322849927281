import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SendEventsByMessage } from "../../action/DsSettingsAction";
import { ENTITY_SUCCESS } from "../../action/EntityActionType";
import { EntitiesStoreContext } from "../../store/EntitiesStore";
import { RootStore } from "../../store/Store";
import { useStyles } from "../../style/styles";
import { formatConnectionDsSettings, getEntityCompanyConnection, handleConnectionUpdate } from "../../utils/EntityFunctions";

const IndividualPending = (props) => {
  const css = useStyles();
  const entityStore = useContext(EntitiesStoreContext);
  const entityState = useSelector(
    (state: RootStore) => state.entityRootReducer
  );
  const dispatch = useDispatch();
  let isShow = false;
  let recruiterId = "";
  let recruiterId64 = "";
  let jobseekerId64 = "";
  let profileId = "";

  const handleClick = (stat: string) => {
    if (stat == "Connect") isShow = true;

    // const dspayloads = {
    //   entityId: entityStore.entityId,
    //   connectionId: props.connectionId,
    //   showEmail: isShow,
    //   showAddress: false,
    //   showPhone: false,
    //   showResume: false,
    //   showToRecruiters: false,
    //   showProfile: false,
    //   showReferences: false,
    //   showBackgroundCheck: false,
    // };
    // dispatch(UpdateDsSettings(dspayloads));

    // Update DSSettings

    // ChangeStatus(props.connectionId, isShow, dspayloads, stat);

    if (props.connectionId && entityState.entityReducer) {
      let connId = parseInt(props.connectionId);
      if (entityStore.entityType == "jobseeker") {
        recruiterId = entityState.entityReducer.connections[connId].id;
        recruiterId64 = entityState.entityReducer.connections[connId].id64;
        jobseekerId64 = entityState.entityReducer.id64;
        profileId =
          entityState.entityReducer.emails[entityState.entityReducer.email]
            .profileId[0];
      } else if (entityStore.entityType == "recruiter") {
        recruiterId = entityState.entityReducer.id;
        recruiterId64 = entityState.entityReducer.id64;
        jobseekerId64 = entityState.entityReducer.connections[connId].id64;
      }
    }
    const eventsPayload = {
      action: stat.toLowerCase(), // connect/disconnect
      source_entity: recruiterId, // recruiter_id from dynamoDB
      job_seeker_id_64: jobseekerId64, // jobseeker_id from 64recs
      profile_id: profileId, // Empty if recruiter
      isConnected: isShow.toString(), // true or false (true/false is string as requirement of the endpoint)
      isBlocked: "false", // set to false since this condition is for active/inactive (true/false is string as requirement of the endpoint)
      status: isShow ? "accepted" : "declined", // accepted, declined, pending, blocked (this condition is for pending only)
      isPending: "false", // set to true since this condition is for pending only (true/false is string as requirement of the endpoint)
      source: recruiterId64, // recruiter_id from 64recs
      caller: "connection", // "connection" for Connections App
      target: jobseekerId64, // jobseeker_id from 64recs
    };

    console.log(eventsPayload);

    dispatch(SendEventsByMessage(eventsPayload));

    // Refresh the entire entity state
    if (entityState.entityReducer) {
      const entity = entityState.entityReducer;
      const connection = {};
      connection[props.connectionId] = entity.connections[props.connectionId];
      formatConnectionDsSettings(isShow, 
        props.connectionId, connection);
      
      const companyId = entity.connections[props.connectionId].companyId;
      const [company, connections] = getEntityCompanyConnection(entity, 
        companyId)
      handleConnectionUpdate(companyId, company, connections);

      dispatch({
        type: ENTITY_SUCCESS,
        payload: entity,
      });
    }
  };

  return (
    // <Box
    //   sx={{
    //     borderTop: 1,
    //     borderColor: "divider",
    //     width: "100%",
    //   }}
    //   key={props.uniquekey}
    // >
    <Grid container sx={{ py: "20px", px: "12px" }}>
      <Grid
        item
        xs={1}
        container
        spacing={0}
        direction="column"
        alignItems="left"
        justifyContent="center"
      >
        <Avatar
          alt="Remy Sharp"
          src="/static/images/avatar/1.jpg"
          sx={{ width: "60px", height: "60px" }}
        />
      </Grid>
      <Grid item xs={5}>
        <Stack direction="column" spacing={1}>
          <Typography className={css.primaryName}>{props.fullname}</Typography>
          {props.positionTitle ? (
            <Typography className={css.normalText400}>
              {props.positionTitle}
            </Typography>
          ) : (
            ""
          )}
          {/* <Typography className={css.normalText400}>{props.contact}</Typography>
          <Typography className={css.normalText400}>
            Connection Established date: {props.connectionEstablished}
          </Typography> */}
        </Stack>
      </Grid>
      <Grid
        item
        xs={6}
        container
        spacing={0}
        direction="column"
        alignItems="right"
      >
        <Grid
          item
          xs={2}
          container
          spacing={0}
          direction="column"
          alignItems="right"
          justifyContent="right"
        >
          <Stack
            direction="row"
            spacing={1}
            alignItems="right"
            justifyContent="right"
            sx={{ pr: "50px" }}
          >
            <Typography className={css.normalText400}>
              A Recruiter is trying to connect to you
            </Typography>
          </Stack>

          <Stack
            spacing={2}
            direction="row"
            alignItems="right"
            justifyContent="right"
            sx={{ pr: "50px", pt: "10px" }}
          >
            <Button
              variant="text"
              className={css.cancelTextBtn}
              onClick={() => handleClick("Disconnect")}
            >
              Decline
            </Button>
            <Button
              variant="contained"
              className={css.saveBtn}
              onClick={() => handleClick("Connect")}
            >
              Accept Connection
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Grid>
    // </Box>
  );
};

export default IndividualPending;
