export const ENTITY_LOADING = "ENTITY_LOADING";
export const ENTITY_FAIL = "ENTITY_FAIL";
export const ENTITY_SUCCESS = "ENTITY_SUCCESS";
export const DSSETTINGS_SUCCESS = "DSSETTINGS_SUCCESS";
export const DSSETTINGS_UPDATE = "DSSETTINGS_UPDATE";
export const PUBLISHER_SUCCESS = "PUBLISHER_SUCCESS";

export type EntityInfo = {
  entity: IndividualEntity;
  connections: Connections;
  companies: Companies;
  id: string;
  id64: string;
  type: string;
  profileId: string;
  firstName: string;
  lastName: string;
  title: string;
  phone: string;
  email: string;
  emails: emails;
  isActive: boolean;
  companyName: string;
  connectionIds: string[];
};

export type emails = {
  email: {
    profileId: string[];
    isConnected: boolean;
    isBlocked: boolean;
  };
};

export type IndividualEntity = {
  id: string;
  id64: string;
  profileId: string;
  firstName: string;
  lastName: string;
  title: string;
  phone: string;
  email: string;
  isActive: boolean;
  companyIds: string[];
};

export type Connections = {
  connectionsId: {
    firstName: string;
    lastName: string;
    companyId: number;
    title: string;
    phone?: string;
    email: string;
    isConnected: boolean;
    isBlocked: boolean;
    isBlockedLink: boolean;
    id64: string;
    status: string;
    date: string;
    timestamp: string;
    contactInfoEnabled: boolean;
    profileAccessEnabled: boolean;
    dsSettingsLink: DsSetting;
    dsSettingsSelf: DsSetting;
  };
};

export type Companies = {
  companyId: {
    companyName: string;
    addressLine1?: string;
    addressLine2?: string;
    city?: string;
    state: string;
    country: string;
    zipCode?: number;
    email: string;
    date?: string;
    url: string;
    connections?: string[];
    count: Count;
    contactInfoEnabled: boolean;
    profileAccessEnabled: boolean;
    isBlocked: boolean;
    isConnected: boolean;
    dsSettingsSelf: DsSetting;
  };
};

export type Count = {
  totalActive: number;
  totalInactive: number;
  totalPending: number;
  totalBlocked: number;
};

export type DsSetting = {
  entityId: string;
  id?: string;
  connectionId: string;
  date: string;
  showEmail: boolean;
  showAddress: boolean;
  showPhone: boolean;
  showResume: boolean;
  showToRecruiters: boolean;
  showProfile: boolean;
  showReferences: boolean;
  showBackgroundCheck: boolean;
};

export type publisher = {
  message: string;
};

export interface EntityLoading {
  type: typeof ENTITY_LOADING;
}

export interface EntityFail {
  type: typeof ENTITY_FAIL;
}

export interface EntitySuccess {
  type: typeof ENTITY_SUCCESS;
  payload: EntityInfo;
}

export interface PublisherSuccess {
  type: typeof PUBLISHER_SUCCESS;
  payload: publisher;
}

export type EntityDispatchTypes =
  | EntityLoading
  | EntityFail
  | EntitySuccess
  | PublisherSuccess;
