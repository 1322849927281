import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useStyles } from "../../style/styles";

interface IConnEstablished {
  connectionEstablished: string;
  entityType: string;
}

const ConnectionEstablished = (props: IConnEstablished) => {
  const css = useStyles();

  if (props.entityType.toLowerCase() == "recruiter") {
    return (
      <Paper
        className={css.connectionEstablishedBox}
        variant="outlined"
        square
        sx={{ mb: 2 }}
      >
        <Typography className={css.normalText400}>
          Connection Established date:{" "}
          {props.connectionEstablished.slice(
            0,
            props.connectionEstablished.length - 3
          ) +
            props.connectionEstablished.slice(
              props.connectionEstablished.length - 1
            )}
        </Typography>
      </Paper>
    );
  } else {
    return <></>;
  }
};

export default ConnectionEstablished;
