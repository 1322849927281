import Brightness1RoundedIcon from "@mui/icons-material/Brightness1Rounded";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useStyles } from "../../style/styles";
import DataSharingModal from "../DataSharingModal";

interface IDataSharing {
  profileAccess: boolean;
  contactInfo: boolean;
  isConnected: boolean;
  connectionId: string;
  entity: string;
}
const DataSharingSection = (props: IDataSharing) => {
  const css = useStyles();
  const ProfileAccess = () => {
    if (props.entity.toLowerCase() == "jobseeker") {
      return (
        <Typography className={css.normalText400}>
          Profile Access:
          <Brightness1RoundedIcon
            className={props.profileAccess ? css.dotSuccess : css.dotDanger}
          />
          {props.profileAccess ? "On" : "Off"}
        </Typography>
      );
    }
    return <></>;
  };

  return (
    <Grid item xs={3}>
      <Stack direction="column" spacing={1}>
        <Typography className={css.dataSharingSettings}>
          Data Sharing Settings
        </Typography>
        {ProfileAccess()}
        <Typography className={css.normalText400}>
          Contact Info:
          <Brightness1RoundedIcon
            className={props.contactInfo ? css.dotSuccess : css.dotDanger}
          />
          {props.contactInfo ? "On" : "Off"}
        </Typography>
        {/* Customize Dialog box for Data Sharing */}
        <DataSharingModal
          connectionId={props.connectionId}
          isConnected={props.isConnected}
          profileAccess={props.profileAccess}
          contactInfo={props.contactInfo}
        />
      </Stack>
    </Grid>
  );
};

export default DataSharingSection;
