import "dotenv/config";
import { Dispatch } from "redux";
import axiosInstance from "../helpers/axios";
import axiosPublisher from "../helpers/publisher";
import {
  EntityDispatchTypes,
  ENTITY_FAIL,
  ENTITY_LOADING,
  ENTITY_SUCCESS,
  PUBLISHER_SUCCESS,
} from "./EntityActionType";

export const GetEntity =
  (entity: string) => async (dispatch: Dispatch<EntityDispatchTypes>) => {
    try {
      dispatch({
        type: ENTITY_LOADING,
      });

      await axiosInstance
        .get(`/entity/network/api/lookup?searchKey=${entity}`)
        .then((response) => {
          dispatch({
            type: ENTITY_SUCCESS,
            payload: response.data.data.entity,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (e) {
      dispatch({
        type: ENTITY_FAIL,
      });
    }
  };

// export const GetEntities = (entity: string) => {
//   try {
//     const { data } = useSWR(
//       `${baseURL}/entity/network/api/lookup?searchKey=${entity}`
//     );
//     return data.data.entity;
//   } catch (e) {}
// };

export const SearchEntity =
  (payloads) => async (dispatch: Dispatch<EntityDispatchTypes>) => {
    try {
      dispatch({
        type: ENTITY_LOADING,
      });

      // const res = await axiosInstance.get(
      //   `/entity/network/api/lookup?searchKey=${searchKey}`
      // );

      dispatch({
        type: ENTITY_SUCCESS,
        payload: payloads,
      });
    } catch (e) {
      dispatch({
        type: ENTITY_FAIL,
      });
    }
  };

export const UpdateEntity =
  (payloads: object) => async (dispatch: Dispatch<EntityDispatchTypes>) => {
    try {
      dispatch({
        type: ENTITY_LOADING,
      });

      await axiosInstance
        // .put(`/entity/network/api/connection/update`, payloads)
        .put(`/connections/api/company/update`, payloads)
        .then((response) => {
          // dispatch({
          //   type: ENTITY_SUCCESS,
          //   payload: response.data.data.entity,
          // });
          console.log(payloads);
          console.log(response.data.data);
          // return response.data.data.entity;
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (e) {
      dispatch({
        type: ENTITY_FAIL,
      });
    }
  };

export const UpdateConnectionSourcingPublisher =
  (payloads: object) => async (dispatch: Dispatch<EntityDispatchTypes>) => {
    try {
      dispatch({
        type: ENTITY_LOADING,
      });

      const res = await axiosPublisher.post(
        `/dev/src-conn-events/publish`,
        payloads
      );

      dispatch({
        type: PUBLISHER_SUCCESS,
        payload: res.data.data.message,
      });
      return Promise.resolve();
    } catch (e) {
      dispatch({
        type: ENTITY_FAIL,
      });
    }
  };

  export const CompanyLevelIsBlocked =
  (payloads: object) => async (dispatch: Dispatch<EntityDispatchTypes>) => {
    try {
      dispatch({
        type: ENTITY_LOADING,
      });

      await axiosInstance
        .put(`/connections/api/company/block`, payloads)
        .then((response) => {
          
          return response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (e) {
      console.log(e);
      dispatch({
        type: ENTITY_FAIL,
      });
    }
  };