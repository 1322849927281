import {
  EntityDispatchTypes,
  EntityInfo,
  ENTITY_FAIL,
  ENTITY_LOADING,
  ENTITY_SUCCESS,
} from "../action/EntityActionType";

export interface DefaultStateI {
  loading: boolean;
  entityReducer?: EntityInfo;
}

export const defaultState: DefaultStateI = {
  loading: false,
};

const mainReducer = (
  state: DefaultStateI = defaultState,
  action: EntityDispatchTypes
): DefaultStateI => {
  switch (action.type) {
    case ENTITY_FAIL:
      return {
        loading: false,
      };
    case ENTITY_LOADING:
      return {
        loading: true,
      };
    case ENTITY_SUCCESS:
      return {
        loading: false,
        entityReducer: action.payload,
      };
    default:
      return state;
  }
};

export default mainReducer;
