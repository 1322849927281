import { REACT_APP_CONN_API, REACT_APP_AUTH_TOKEN } from "@env";
import axios from "axios";

var domainUrl = `https://link.jobtarget.com:8080`; // New QA  process.env.REACT_APP_CONN_API;

if (REACT_APP_CONN_API && REACT_APP_CONN_API != "") 
  domainUrl = REACT_APP_CONN_API
// export const baseURL = `https://jobseeker.qa-aws.jobtarget.com:8080`; // Old QA
// export const baseURL = REACT_APP_CONN_API; //`https://qa-connnetworkmiddleware.jobtarget.com`; // New QA  process.env.REACT_APP_CONN_API;
export const baseURL = domainUrl;

const axiosInstance = axios.create({
  baseURL: baseURL,
});
axiosInstance.defaults.headers.common['Authorization'] = REACT_APP_AUTH_TOKEN;

export default axiosInstance;
