import SearchIcon from "@mui/icons-material/Search";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import InputBase from "@mui/material/InputBase";
import { alpha, styled } from "@mui/material/styles";
import { Dimensions } from "react-native";
import { useStyles } from "../style/styles";
import { setGlobalState } from "../utils/GlobalState";
import { useSelector } from "react-redux";
import { RootStore } from "../store/Store";

const SearchInput = styled(InputBase, {
  shouldForwardProp: (prop) => prop !== "isMobile" && prop !== "wWidth"
})(({ isMobile, wWidth, theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 20,
    border: "1px solid #E4E6EF",
    position: "relative",
    backgroundColor: "#FFFFFF",
    fontSize: 12,
    fontWeight: 400,
    lineHeight: "22px",
    width: isMobile ? wWidth - 45 + "px" : "200px",
    padding: isMobile ? "8px 10px" : "4px 10px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: ["Poppins", "sans-serif"].join(","),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

interface ISearch {
  PlaceHolder: string;
  Mobile: boolean;
  Person: boolean;
}

const SearchBox = (props: ISearch) => {
  const css = useStyles();
  const { isMobile, windowWidth } = useSelector(
    (state: RootStore) => state.deviceReducer.deviceData
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGlobalState(
      !props.Person ? "searchEntity" : "searchEntityMobile",
      event.target.value
    );
  };

  return (
    <Box sx={{ height: 32, pb: !isMobile ? 8 : 1 }}>
      <FormControl>
        <FormLabel>
          {!isMobile ? (
            <>
              <FormLabel className={css.search}>Search:</FormLabel>
              <SearchInput
                placeholder={props.PlaceHolder}
                onChange={handleChange}
              />
            </>
          ) : (
            <Grid container alignItems="center" justifyContent="center">
              <SearchInput
                placeholder={props.PlaceHolder}
                onChange={handleChange}
                isMobile={isMobile}
                wWidth={windowWidth}
              />

              <SearchIcon
                sx={{ marginInlineStart: -3.5, zIndex: 1 }}
              ></SearchIcon>
            </Grid>
          )}
        </FormLabel>
      </FormControl>
    </Box>
  );
};
export default SearchBox;
