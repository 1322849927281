import { Companies, Connections } from "./EntityActionType";

export const COMPANY_LOADING = "COMPANY_LOADING";
export const COMPANY_FAIL = "COMPANY_FAIL";
export const COMPANY_SUCCESS = "COMPANY_SUCCESS";
export const COMPANY_UPDATE = "COMPANY_UPDATE";

export type CompanyInfo = {
    connections: Connections;
    company: Companies;
};

export interface CompanyLoading {
  type: typeof COMPANY_LOADING;
}

export interface CompanyFail {
  type: typeof COMPANY_FAIL;
}

export interface CompanySuccess {
  type: typeof COMPANY_SUCCESS;
  payload: CompanyInfo;
}

export interface CompanyUpdate {
  type: typeof COMPANY_UPDATE;
  payload: CompanyInfo;
}

export type CompanyDispatchTypes =
  | CompanyLoading
  | CompanyFail
  | CompanySuccess
  | CompanyUpdate;
