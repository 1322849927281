import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { useContext } from "react";
import { useSelector } from "react-redux";
import IndividualBlock from "../../component/entity/IndividualBlock";
import IndividualEntity from "../../component/entity/IndividualEntity";
import { EntitiesStoreContext } from "../../store/EntitiesStore";
import { RootStore } from "../../store/Store";
import { useStyles } from "../../style/styles";
import { useGlobalState } from "../../utils/GlobalState";
import IndividualPending from "./IndividualPending";
import { useState, useEffect } from "react";
import Skeleton from "@mui/material/Skeleton";

const IndividualList = (props: { tabIndex: number }) => {
  const css = useStyles();
  const entityState = useSelector(
    (state: RootStore) => state.entityRootReducer
  );
  const entityStore = useContext(EntitiesStoreContext);
  const searchEntity = useGlobalState("searchEntity");
  const allDataWithoutBlockedLink = entityState.entityReducer
  ? Object.entries(entityState.entityReducer.connections)
    .filter(([key, value]) =>  
    !value.isBlockedLink) : [];
  const allDataByIndex = allDataWithoutBlockedLink.filter(([key, value]) => 
      (props.tabIndex == 1 && value.isConnected) || 
      // (props.tabIndex == 2 && !value.isConnected && !value.isBlocked &&
      //   !(value.status.toLowerCase() == "pending")) ||
      (props.tabIndex == 2 && value.status.toLowerCase() == "pending") ||
      (props.tabIndex == 3 && value.isBlocked) ||
      (props.tabIndex == 0));
  const [isFetching, setIsFetching] = useState(false);
  const totalShowLenth = 10;
  const [dataList, setDataList] = useState(getNextList(0, totalShowLenth));
  const [isCanLoadMore, setIsCanLoadMore] = useState(true);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  },[]);

  useEffect(() => {
    if (!isFetching) return;
    scrollMoreDataList();
  }, [isFetching]);

  useEffect(() => {
    if (dataList && dataList.length == 
      allDataByIndex.length) 
      setIsCanLoadMore(false);
  }, [dataList]);

  function handleScroll() {
    const footerEl = document.querySelector('jt-footer');
    if ((!isBottom(footerEl) || isFetching) && !isCanLoadMore) return;
    setIsFetching(true);
  }

  function getNextList(from, to) {
    let totalLength = to;
    if (to > allDataByIndex.length)
      totalLength = allDataByIndex.length;
    return entityState.entityReducer && 
    allDataByIndex
        .slice(from, totalLength);
  }
  
  function scrollMoreDataList() {
    setTimeout(() => {
      setDataList(prevState => ([...prevState, ...getNextList(prevState?.length, 
        totalShowLenth + (prevState ? prevState?.length + 1 : 0))]));
      setIsFetching(false);
    }, 2000);
  }

  function isBottom(el) {
    return Math.round(el.getBoundingClientRect().bottom) <= window.innerHeight;
  }

  const updateDataList = () => {
    setDataList((currentList) => currentList
                        .filter(([key, value]) => 
                        (props.tabIndex == 1 && value.isConnected) || 
                        // (props.tabIndex == 2 && !value.isConnected && !value.isBlocked &&
                        //   !(value.status.toLowerCase() == "pending")) ||
                        (props.tabIndex == 2 && value.status.toLowerCase() == "pending") ||
                        (props.tabIndex == 3 && value.isBlocked) ||
                        (props.tabIndex == 0)));
  };

  useEffect(() => {
    props.tabIndex != 0 && updateDataList();
  },[entityState]);

  return (
    <>
    <List sx={{ width: "100%" }} disablePadding>
      <ListItem disablePadding>
        <Grid container direction="row">
          {(dataList && searchEntity[0].toLowerCase() == "") ?
            dataList
            .map(([k, conn], indx) => {
                // if (parseInt(props.connId) == conn.companyId) {
                entityStore.connectionId = k;
                // return ( <div> HEAD: { props.tabIndex } -- {conn.firstName.concat(" ", conn.lastName)} -- isConnected? { conn.isConnected } -- isBlocked? { conn.isBlocked } -- status? { conn.status} </div>)
                if (props.tabIndex == 1 &&
                    conn.isConnected && !conn.isBlocked &&
                    conn.status == "accepted"
                ) {
                  return (
                    <Box
                      sx={{
                        // borderTop: activethick,
                        borderColor: "divider",
                        width: "100%",
                      }}
                      key={k}
                    >
                      <IndividualEntity
                        uniqueKey={k}
                        id64={conn.id64}
                        fullname={conn.firstName.concat(" ", conn.lastName)}
                        positionTitle={conn.title}
                        email={
                          conn.dsSettingsLink.showEmail
                            ? conn.email
                            : "*************************"
                        }
                        phone={
                          conn.dsSettingsLink.showPhone
                            ? conn.phone
                            : "*** *** ****"
                        }
                        isConnected={conn.isConnected}
                        status={conn.status}
                        connectionEstablished={conn.date}
                        profileAccess={conn.profileAccessEnabled}
                        contactInfo={conn.contactInfoEnabled}
                        connectionId={
                          conn.dsSettingsSelf
                            ? conn.dsSettingsSelf.connectionId
                            : conn.dsSettingsSelf
                        }
                        companyId="0"
                        entity="recruiter"
                        showProfile={conn.dsSettingsLink.showProfile}
                        showResume={conn.dsSettingsLink.showResume}
                      />
                    </Box>
                  );
                }

                /*else if (props.tabIndex == 2 &&
                    conn.isConnected && !conn.isBlocked &&
                    conn.status.toLowerCase() == "declined"
                ) {
                  return (
                    <Box
                      sx={{
                        // borderTop: activethick,
                        borderColor: "divider",
                        width: "100%",
                      }}
                      key={k}
                    >
                      <IndividualEntity
                        uniqueKey={k}
                        id64={conn.id64}
                        fullname={conn.firstName.concat(" ", conn.lastName)}
                        positionTitle={conn.title}
                        email={
                          conn.dsSettingsLink.showEmail
                            ? conn.email
                            : "*************************"
                        }
                        phone={
                          conn.dsSettingsLink.showPhone
                            ? conn.phone
                            : "*** *** ****"
                        }
                        isConnected={conn.isConnected}
                        status={conn.status}
                        connectionEstablished={conn.date}
                        profileAccess={conn.profileAccessEnabled}
                        contactInfo={conn.contactInfoEnabled}
                        connectionId={
                          conn.dsSettingsSelf
                            ? conn.dsSettingsSelf.connectionId
                            : conn.dsSettingsSelf
                        }
                        companyId="0"
                        entity="recruiter"
                        showProfile={conn.dsSettingsLink.showProfile}
                        showResume={conn.dsSettingsLink.showResume}
                      />
                    </Box>
                  );
                } */

                /* PENDING TAB (tabIndex-1 since Disconnected Tab is currently disabled)*/
                else if (
                  props.tabIndex == 2 &&
                  !conn.isConnected && !conn.isBlocked &&
                  conn.status.toLowerCase() == "pending"
                ) {
                  return (
                    <Box
                      sx={{
                        // borderTop: pendingthick,
                        borderColor: "divider",
                        width: "100%",
                      }}
                      key={k}
                    >
                      <IndividualPending
                        backgroundColor="#FFF4DE"
                        uniquekey={k}
                        id64={conn.id64}
                        fullname={conn.firstName.concat(" ", conn.lastName)}
                        positionTitle={conn.title}
                        contact={conn.email.concat(
                          " ",
                          conn.phone ? "| " + conn.phone : ""
                        )}
                        isConnected={conn.isConnected}
                        status={conn.status}
                        connectionEstablished={conn.date}
                        profileAccess={conn.profileAccessEnabled}
                        contactInfo={conn.contactInfoEnabled}
                        connectionId={
                          conn.dsSettingsSelf
                            ? conn.dsSettingsSelf.connectionId
                            : conn.dsSettingsSelf
                        }
                      />
                    </Box>
                  );
                }

                /* BLOCKED TAB (tabIndex-1 since Disconnected Tab is currently disabled)*/
                else if (
                  props.tabIndex == 3 &&
                  !conn.isConnected &&
                  conn.status.toLowerCase() == "blocked"
                ) {
                  return (
                    <Box
                      sx={{
                        // borderTop: blockedthick,
                        borderColor: "divider",
                        width: "100%",
                      }}
                      key={k}
                    >
                      <IndividualBlock
                        backgroundColor="#FFFFFF"
                        uniqueKey={k}
                        id64={conn.id64}
                        fullname={conn.firstName.concat(" ", conn.lastName)}
                        email={conn.email}
                        positionTitle={conn.title}
                        contact={conn.email.concat(
                          " ",
                          conn.phone ? "| " + conn.phone : ""
                        )}
                        isConnected={conn.isConnected}
                        status={conn.status}
                        connectionEstablished={conn.date}
                        profileAccess={conn.profileAccessEnabled}
                        contactInfo={conn.contactInfoEnabled}
                        connectionId={
                          conn.dsSettingsSelf
                            ? conn.dsSettingsSelf.connectionId
                            : conn.dsSettingsSelf
                        }
                        companyId={0}
                        entity="recruiter"
                        showProfile={conn.dsSettingsLink.showProfile}
                        showResume={conn.dsSettingsLink.showResume}
                      />
                    </Box>
                  );
                }
                /* REMOVE ALL BLOCKED INDIVIDUALS IN ALL CONNECTIONS TAB */
                /*if (
                  props.tabIndex == 0 &&
                  !conn.isConnected && conn.isBlocked &&
                  conn.status.toLowerCase() == "blocked"
                ) {
                  return (
                    <Box
                      sx={{
                        // borderTop: allthick,
                        borderColor: "divider",
                        width: "100%",
                      }}
                      key={k}
                    >
                      <IndividualBlock
                        backgroundColor="#FFFFFF"
                        uniqueKey={k}
                        id64={conn.id64}
                        fullname={conn.firstName.concat(" ", conn.lastName)}
                        email={conn.email}
                        positionTitle={conn.title}
                        contact={conn.email.concat(
                          " ",
                          conn.phone ? "| " + conn.phone : ""
                        )}
                        isConnected={conn.isConnected}
                        status={conn.status}
                        connectionEstablished={conn.date}
                        profileAccess={conn.profileAccessEnabled}
                        contactInfo={conn.contactInfoEnabled}
                        connectionId={
                          conn.dsSettingsSelf
                            ? conn.dsSettingsSelf.connectionId
                            : conn.dsSettingsSelf
                        }
                        companyId={0}
                        entity="recruiter"
                        showProfile={conn.dsSettingsLink.showProfile}
                        showResume={conn.dsSettingsLink.showResume}
                      />
                    </Box>
                  );
                }*/
                if (
                  (props.tabIndex == 0 &&
                    conn.status.toLowerCase() == "accepted")
                    // || conn.status.toLowerCase() == "declined" // Remove all disconnected individuals from All Connections Tab
                ) {
                  return (
                    <Box
                      sx={{
                        // borderTop: activethick,
                        borderColor: "divider",
                        width: "100%",
                      }}
                      key={k}
                    >
                      {/* ads {conn.firstName} / { conn.status } - { conn.isBlocked } - { conn.isConnected} */}
                      <IndividualEntity
                        uniqueKey={k}
                        id64={conn.id64}
                        fullname={conn.firstName.concat(" ", conn.lastName)}
                        positionTitle={conn.title}
                        email={
                          conn.dsSettingsLink.showEmail
                            ? conn.email
                            : "*************************"
                        }
                        phone={
                          conn.dsSettingsLink.showPhone
                            ? conn.phone
                            : "*** *** ****"
                        }
                        isConnected={conn.isConnected}
                        status={conn.status}
                        connectionEstablished={conn.date}
                        profileAccess={conn.profileAccessEnabled}
                        contactInfo={conn.contactInfoEnabled}
                        connectionId={
                          conn.dsSettingsSelf
                            ? conn.dsSettingsSelf.connectionId
                            : conn.dsSettingsSelf
                        }
                        companyId="0"
                        entity="recruiter"
                        showProfile={conn.dsSettingsLink.showProfile}
                        showResume={conn.dsSettingsLink.showResume}
                      />
                    </Box>
                  );
                }
                if (props.tabIndex == 0 && conn.status == "pending") {
                  return (
                    <Box
                      sx={{
                        // borderTop: inactivethick,
                        borderColor: "divider",
                        width: "100%",
                      }}
                      key={k}
                    >
                      <IndividualPending
                        backgroundColor="#FFF4DE"
                        uniquekey={k}
                        id64={conn.id64}
                        fullname={conn.firstName.concat(" ", conn.lastName)}
                        positionTitle={conn.title}
                        contact={conn.email.concat(
                          " ",
                          conn.phone ? "| " + conn.phone : ""
                        )}
                        isConnected={conn.isConnected}
                        status={conn.status}
                        connectionEstablished={conn.date}
                        profileAccess={conn.profileAccessEnabled}
                        contactInfo={conn.contactInfoEnabled}
                        connectionId={
                          conn.dsSettingsSelf
                            ? conn.dsSettingsSelf.connectionId
                            : conn.dsSettingsSelf
                        }
                      />
                    </Box>
                  );
                }
                // }
              })
            : !(searchEntity[0].toLowerCase() == "") ? 
              allDataByIndex
              .filter(([key, value]) => {
                if (searchEntity[0].toLowerCase() == "") {
                  return value;
                } else if (
                  value.firstName
                    .toLowerCase()
                    .includes(searchEntity[0].toLowerCase()) ||
                  value.lastName
                    .toLowerCase()
                    .includes(searchEntity[0].toLowerCase())
                ) {
                  return value;
                }
              })
              .map(([k, conn], indx) => {
                // if (parseInt(props.connId) == conn.companyId) {
                entityStore.connectionId = k;

                if (props.tabIndex == 1 &&
                    conn.isConnected && !conn.isBlocked &&
                    conn.status == "accepted"
                ) {
                  return (
                    <Box
                      sx={{
                        // borderTop: activethick,
                        borderColor: "divider",
                        width: "100%",
                      }}
                      key={k}
                    >
                      <IndividualEntity
                        uniqueKey={k}
                        id64={conn.id64}
                        fullname={conn.firstName.concat(" ", conn.lastName)}
                        positionTitle={conn.title}
                        email={
                          conn.dsSettingsLink.showEmail
                            ? conn.email
                            : "*************************"
                        }
                        phone={
                          conn.dsSettingsLink.showPhone
                            ? conn.phone
                            : "*** *** ****"
                        }
                        isConnected={conn.isConnected}
                        status={conn.status}
                        connectionEstablished={conn.date}
                        profileAccess={conn.profileAccessEnabled}
                        contactInfo={conn.contactInfoEnabled}
                        connectionId={
                          conn.dsSettingsSelf
                            ? conn.dsSettingsSelf.connectionId
                            : conn.dsSettingsSelf
                        }
                        companyId="0"
                        entity="recruiter"
                        showProfile={conn.dsSettingsLink.showProfile}
                        showResume={conn.dsSettingsLink.showResume}
                      />
                    </Box>
                  );
                }

                // else if (props.tabIndex == 2 &&
                //     conn.isConnected && !conn.isBlocked &&
                //     conn.status.toLowerCase() == "declined"
                // ) {
                //   return (
                //     <Box
                //       sx={{
                //         // borderTop: activethick,
                //         borderColor: "divider",
                //         width: "100%",
                //       }}
                //       key={k}
                //     >
                //       <IndividualEntity
                //         uniqueKey={k}
                //         id64={conn.id64}
                //         fullname={conn.firstName.concat(" ", conn.lastName)}
                //         positionTitle={conn.title}
                //         email={
                //           conn.dsSettingsLink.showEmail
                //             ? conn.email
                //             : "*************************"
                //         }
                //         phone={
                //           conn.dsSettingsLink.showPhone
                //             ? conn.phone
                //             : "*** *** ****"
                //         }
                //         isConnected={conn.isConnected}
                //         status={conn.status}
                //         connectionEstablished={conn.date}
                //         profileAccess={conn.profileAccessEnabled}
                //         contactInfo={conn.contactInfoEnabled}
                //         connectionId={
                //           conn.dsSettingsSelf
                //             ? conn.dsSettingsSelf.connectionId
                //             : conn.dsSettingsSelf
                //         }
                //         companyId="0"
                //         entity="recruiter"
                //         showProfile={conn.dsSettingsLink.showProfile}
                //         showResume={conn.dsSettingsLink.showResume}
                //       />
                //     </Box>
                //   );
                // }

                else if (
                  props.tabIndex == 2 &&
                  !conn.isConnected && !conn.isBlocked &&
                  conn.status.toLowerCase() == "pending"
                ) {
                  return (
                    <Box
                      sx={{
                        // borderTop: pendingthick,
                        borderColor: "divider",
                        width: "100%",
                      }}
                      key={k}
                    >
                      <IndividualPending
                        backgroundColor="#FFF4DE"
                        uniquekey={k}
                        id64={conn.id64}
                        fullname={conn.firstName.concat(" ", conn.lastName)}
                        positionTitle={conn.title}
                        contact={conn.email.concat(
                          " ",
                          conn.phone ? "| " + conn.phone : ""
                        )}
                        isConnected={conn.isConnected}
                        status={conn.status}
                        connectionEstablished={conn.date}
                        profileAccess={conn.profileAccessEnabled}
                        contactInfo={conn.contactInfoEnabled}
                        connectionId={
                          conn.dsSettingsSelf
                            ? conn.dsSettingsSelf.connectionId
                            : conn.dsSettingsSelf
                        }
                      />
                    </Box>
                  );
                }

                else if (
                  props.tabIndex == 3 &&
                  !conn.isConnected &&
                  conn.status.toLowerCase() == "blocked"
                ) {
                  return (
                    <Box
                      sx={{
                        // borderTop: blockedthick,
                        borderColor: "divider",
                        width: "100%",
                      }}
                      key={k}
                    >
                      <IndividualBlock
                        backgroundColor="#FFFFFF"
                        uniqueKey={k}
                        id64={conn.id64}
                        fullname={conn.firstName.concat(" ", conn.lastName)}
                        email={conn.email}
                        positionTitle={conn.title}
                        contact={conn.email.concat(
                          " ",
                          conn.phone ? "| " + conn.phone : ""
                        )}
                        isConnected={conn.isConnected}
                        status={conn.status}
                        connectionEstablished={conn.date}
                        profileAccess={conn.profileAccessEnabled}
                        contactInfo={conn.contactInfoEnabled}
                        connectionId={
                          conn.dsSettingsSelf
                            ? conn.dsSettingsSelf.connectionId
                            : conn.dsSettingsSelf
                        }
                        companyId={0}
                        entity="recruiter"
                        showProfile={conn.dsSettingsLink.showProfile}
                        showResume={conn.dsSettingsLink.showResume}
                      />
                    </Box>
                  );
                }

                if (
                  props.tabIndex == 0 &&
                  !conn.isConnected && conn.isBlocked &&
                  conn.status.toLowerCase() == "blocked"
                ) {
                  return (
                    <Box
                      sx={{
                        // borderTop: allthick,
                        borderColor: "divider",
                        width: "100%",
                      }}
                      key={k}
                    >
                      <IndividualBlock
                        backgroundColor="#FFFFFF"
                        uniqueKey={k}
                        id64={conn.id64}
                        fullname={conn.firstName.concat(" ", conn.lastName)}
                        email={conn.email}
                        positionTitle={conn.title}
                        contact={conn.email.concat(
                          " ",
                          conn.phone ? "| " + conn.phone : ""
                        )}
                        isConnected={conn.isConnected}
                        status={conn.status}
                        connectionEstablished={conn.date}
                        profileAccess={conn.profileAccessEnabled}
                        contactInfo={conn.contactInfoEnabled}
                        connectionId={
                          conn.dsSettingsSelf
                            ? conn.dsSettingsSelf.connectionId
                            : conn.dsSettingsSelf
                        }
                        companyId={0}
                        entity="recruiter"
                        showProfile={conn.dsSettingsLink.showProfile}
                        showResume={conn.dsSettingsLink.showResume}
                      />
                    </Box>
                  );
                }
                if (
                  (props.tabIndex == 0 &&
                    conn.status.toLowerCase() == "accepted")
                    // || conn.status.toLowerCase() == "declined"
                ) {
                  return (
                    <Box
                      sx={{
                        // borderTop: activethick,
                        borderColor: "divider",
                        width: "100%",
                      }}
                      key={k}
                    >
                      <IndividualEntity
                        uniqueKey={k}
                        id64={conn.id64}
                        fullname={conn.firstName.concat(" ", conn.lastName)}
                        positionTitle={conn.title}
                        email={
                          conn.dsSettingsLink.showEmail
                            ? conn.email
                            : "*************************"
                        }
                        phone={
                          conn.dsSettingsLink.showPhone
                            ? conn.phone
                            : "*** *** ****"
                        }
                        isConnected={conn.isConnected}
                        status={conn.status}
                        connectionEstablished={conn.date}
                        profileAccess={conn.profileAccessEnabled}
                        contactInfo={conn.contactInfoEnabled}
                        connectionId={
                          conn.dsSettingsSelf
                            ? conn.dsSettingsSelf.connectionId
                            : conn.dsSettingsSelf
                        }
                        companyId="0"
                        entity="recruiter"
                        showProfile={conn.dsSettingsLink.showProfile}
                        showResume={conn.dsSettingsLink.showResume}
                      />
                    </Box>
                  );
                }
                if (props.tabIndex == 0 && conn.status == "pending") {
                  return (
                    <Box
                      sx={{
                        // borderTop: inactivethick,
                        borderColor: "divider",
                        width: "100%",
                      }}
                      key={k}
                    >
                      <IndividualPending
                        backgroundColor="#FFF4DE"
                        uniquekey={k}
                        id64={conn.id64}
                        fullname={conn.firstName.concat(" ", conn.lastName)}
                        positionTitle={conn.title}
                        contact={conn.email.concat(
                          " ",
                          conn.phone ? "| " + conn.phone : ""
                        )}
                        isConnected={conn.isConnected}
                        status={conn.status}
                        connectionEstablished={conn.date}
                        profileAccess={conn.profileAccessEnabled}
                        contactInfo={conn.contactInfoEnabled}
                        connectionId={
                          conn.dsSettingsSelf
                            ? conn.dsSettingsSelf.connectionId
                            : conn.dsSettingsSelf
                        }
                      />
                    </Box>
                  );
                }
                // }
              })
            : <Skeleton variant="rectangular" width={210} height={118} />
          }
        </Grid>
      </ListItem>
    </List>
    {isFetching && isCanLoadMore && "Loading more list items..."}
    </>
  );
};

export default IndividualList;
