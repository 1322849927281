import { Connections } from "./EntityActionType";

export const RECRUITER_LOADING = "RECRUITER_LOADING";
export const RECRUITER_FAIL = "RECRUITER_FAIL";
export const RECRUITER_SUCCESS = "RECRUITER_SUCCESS";
export const RECRUITER_UPDATE = "RECRUITER_UPDATE";

export interface RecruiterLoading {
  type: typeof RECRUITER_LOADING;
}

export interface RecruiterFail {
  type: typeof RECRUITER_FAIL;
}

export interface RecruiterSuccess {
  type: typeof RECRUITER_SUCCESS;
  payload: Connections;
}

export interface RecruiterUpdate {
  type: typeof RECRUITER_UPDATE;
  payload: Connections;
}

export type RecruiterDispatchTypes =
  | RecruiterLoading
  | RecruiterFail
  | RecruiterSuccess
  | RecruiterUpdate;
