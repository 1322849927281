import axios from "axios";
import { REACT_APP_CONN_API, REACT_APP_AUTH_TOKEN } from "@env";

var domainUrl = `https://qa-connnetworkmiddleware.jobtarget.com`; // New QA  process.env.REACT_APP_CONN_API;
if (REACT_APP_CONN_API && REACT_APP_CONN_API != "") 
  domainUrl = REACT_APP_CONN_API
// export const baseURL = `https://jikwc78tci.execute-api.us-east-1.amazonaws.com`; // Dev
export const baseURL = domainUrl;  // QA

const axiosPublisher = axios.create({
  baseURL: baseURL,
});
axiosPublisher.defaults.headers.common['Authorization'] = REACT_APP_AUTH_TOKEN;

export default axiosPublisher;
