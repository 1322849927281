import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { useSelector } from "react-redux";
import axiosInstance from "../helpers/axios";
import { RootStore } from "../store/Store";
import { useStyles } from "../style/styles";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

interface IResume {
  connectionId: string;
  isConnected: boolean;
  profileAccessEnabled: boolean;
  showResume: boolean;
}

const ResumeModal = (props: IResume) => {
  const css = useStyles();
  const [showResumeModal, setSHowResumeModal] = useState(false);
  const [resume, setResume] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);

  const entityState = useSelector(
    (state: RootStore) => state.entityRootReducer
  );

  const handleShowModal = async () => {
    setSHowResumeModal(true);
    setIsLoading(true);
    try {
      const { data } = await axiosInstance.get(
        `/profile/api/lookup?searchKey=${
          entityState.entityReducer?.connections[props.connectionId].email
        }`
      );
      const resumeData = data.profile.resumes;
      setResume(resumeData);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setSHowResumeModal(false);
  };

  const handleResume = (link) => {
    const extension = link.split(".").pop();
    switch (extension) {
      case "doc":
        return `https://view.officeapps.live.com/op/view.aspx?src=${link}&wdOrigin=BROWSELINK`;
      case "docx":
        return `https://view.officeapps.live.com/op/view.aspx?src=${link}&wdOrigin=BROWSELINK`;
      default:
        return link;
    }
  };

  return (
    <div>
      <Link
        onClick={handleShowModal}
        href="#"
        underline="none"
        className={props.showResume ? css.enabledLink : css.disabledLink}
      >
        Show Resume
      </Link>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={showResumeModal}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "1138px",
              // Set your width here
            },
          },
        }}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <Typography className={css.dataSharingSettingsModal}>
            {resume ? "Show Resume" : "No Resume Available"}
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent dividers className={css.resumeDialogContent}>
          {!isLoading ? (
            <>
              {resume && (
                <iframe
                  className={css.resumeIframe}
                  src={
                    resume && resume.length
                      ? handleResume(resume[resume.length - 1].link)
                      : ""
                  }
                  title="resume"
                ></iframe>
              )}
            </>
          ) : (
            <div className={css.dialogLoadingProgress}>
              <CircularProgress />
            </div>
          )}
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
};

export default ResumeModal;
