import { createGlobalState } from "react-hooks-global-state";

const { setGlobalState, useGlobalState } = createGlobalState({
  searchEntity: "",
  searchEntityMobile: "",
  personIndividualStatus: false,
  currentPage: "",
});

export { setGlobalState, useGlobalState };
