import { Avatar, BottomNavigation, BottomNavigationAction, Box, Container, Divider, Link, Paper } from "@mui/material";
import Header from "../../component/Header";
import SearchBox from "../../component/SearchBox";
import TabConn from "../../component/TabConn";
import { Dimensions, ScrollView } from "react-native";
import React from "react";
import SwipeableViews from 'react-swipeable-views'
import { useSelector } from "react-redux";
import { RootStore } from "../../store/Store";

export const JobSeeker = () => {
  const [value, setValue] = React.useState(0);
  const { isMobile } = useSelector(
    (state: RootStore) => state.deviceReducer.deviceData
  );
  
  return !isMobile ? (
    <Box sx={{ px: 2 }}>
      <Header type="jobseeker" />
      <Divider />
      <SearchBox
        PlaceHolder="Enter Company Name"
        Mobile={isMobile}
        Person={false}
      />
      <TabConn hideCountBlocked={0}/>
    </Box>
  ) : (
    <SwipeableViews axis="y">
    <Box sx={{pl: "10px"}}>
      {/* <AppBar position="fixed" sx={{height: "53px", backgroundColor: "#2F86DD"}}>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Avatar
              src={JTLogo}
              sx={{ width: "152px", height: "31.43px", margin: "10px" }}
              variant="square"
            />  
        </Box>  
      </AppBar> */}
      <Header type="jobseeker" />
      <div>
        Manage your connections with recruiters and hiring managers here.
      </div>
      <Divider sx={{ margin: 1 }} />
      <SearchBox PlaceHolder="Search" Mobile={isMobile} Person={false} />
      <TabConn hideCountBlocked={0} />
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
      {/* <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
            console.log(newValue);
            if (newValue == 0) {
              <Link href="#" underline="none">
                {'underline="none"'}
              </Link>
            }
          }}
          sx={{backgroundColor: "#2F86DD", height: "80px", paddingBottom: "10px"}}
        >
          <BottomNavigationAction label="Home" icon={<HomeIcon style={{color: "#FFFFFF"}} />} />
          <BottomNavigationAction disabled label="|" />
          <BottomNavigationAction label="Settings" icon={<SettingsIcon style={{color: "#FFFFFF"}} />} />
      </BottomNavigation> */}
      </Paper>
    </Box>
    </SwipeableViews>
  );
};
