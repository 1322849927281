import spinnerGif from "../assets/images/spinner.gif"
import { useStyles } from "../style/styles";
import {
    Box,
    Grid,
  } from "@mui/material";

const Loader = () => {
    const css = useStyles();

    return (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="60vh"
        >
          <Grid>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
                <img src={spinnerGif} />
            </Grid>
          </Grid>
        </Box>
      );
};

export default Loader;
