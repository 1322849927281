import Brightness1RoundedIcon from "@mui/icons-material/Brightness1Rounded";
import {
  Avatar,
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Popover,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { useContext, useState, useEffect } from "react";
import { Dimensions, ScrollView } from "react-native";
import defaultCompanyImage from "../../assets/images/DefaultCompanyLogo.png";
import headerImage from "../../assets/svg/company_cover.svg";
import CustomizedAccordions from "../../component/Accordion";
import DataSharingModal from "../../component/DataSharingModal";
import StatusMenu from "../../component/StatusMenu";
import { useStyles } from "../../style/styles";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { textAlign } from "@mui/system";
import TabConnIndividual from "../../component/TabConnIndividual";
import SearchBox from "../../component/SearchBox";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../store/Store";
import { EntitiesStoreContext } from "../../store/EntitiesStore";
import { SendEventsByMessage } from "../../action/DsSettingsAction";
import { UpdateEntity } from "../../action/EntityAction";
import { ENTITY_SUCCESS, Connections } from "../../action/EntityActionType";
import Tooltip, { tooltipClasses, TooltipProps } from "@mui/material/Tooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import UnBlockStatusMenu from "../../component/UnBlockStatusMenu";
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { PowerInputSharp } from "@mui/icons-material";
import SwipeableViews from 'react-swipeable-views'
import {
  getCompanyConnection,
  getEntityCompanyConnection,
  handleConnectionUpdate,
  handleStatusChange
} from "../../utils/EntityFunctions";

import { COMPANY_SUCCESS } from "../../action/CompanyActionTypes";
import { setGlobalState, useGlobalState } from "../../utils/GlobalState";

interface ICompanyDetails {
  companyId: string;
  compName: string;
  compLocation: string;
  compEmail: string;
  isConnected: boolean;
  isBlocked: boolean;
  uniqueKey: string;
  connections?: string[];
  connectionId: string;
  totalConnPerCompany: number;
  profileAccess: boolean;
  contactInfo: boolean;
  count: any;
  connectionEstablished?: string;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const CompanyDetails = (props: ICompanyDetails) => {
  const css = useStyles();
  const [showIndividualCompany, setShowIndividualCompany] = useState(false);
  const [showConnectStatus, setShowConnectStatus] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const {entityRootReducer, companyReducer} = useSelector(
    (state: RootStore) => state
    );
    const entityStore = useContext(EntitiesStoreContext);
    const dispatch = useDispatch();
    let hideCountBlocked = 0;
    
    const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
      <Tooltip {...props} classes={{ popper: className }} placement="top" arrow />
      ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: "rgba(0, 0, 0, 0.20)",
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));
  const { isMobile, isPortrait, windowWidth } = useSelector(
    (state: RootStore) => state.deviceReducer.deviceData
  );
  // const [entityData, setEntityData] = useState(entityRootReducer.entityReducer);
  // const [companyData, setCompanyData] = useState(companyReducer.companyInfo);

  const handlerIndividualCompany = () => {
    console.log("handlerIndividualCompany");
    setGlobalState("currentPage", "company");

    const entity = entityRootReducer.entityReducer;
    const [company, connections] = getEntityCompanyConnection(entity, props.companyId.toString());

    dispatch({
      type: COMPANY_SUCCESS,
      payload: {company, connections},
    });

    setShowIndividualCompany(true);
  };

  const handleChange = (stat: string) => {
    console.log ("handleChange - stat: " + stat);
    let isShow = false;

    if (stat == "Connect") {
      isShow = true;
    }

    const payload = {
      id: entityStore.entityId,
      connectionId: props.companyId,
      connect: isShow,
    }

    // Call Update entity api
    console.log("Call update entity api");
    dispatch(UpdateEntity(payload));
    
    // update cache here...
    if (companyReducer.companyInfo) {
      const companyId = props.companyId.toString();
      handleStatusChange(stat, true,
        companyReducer.companyInfo.company, 
        companyReducer.companyInfo.connections, {}, companyId);
      handleConnectionUpdate(companyId, 
        companyReducer.companyInfo.company, 
        companyReducer.companyInfo.connections)

      // This will set the companyreducer state
      dispatch({
        type: COMPANY_SUCCESS,
        payload: companyReducer.companyInfo,
      });

      // This will reset the entityrootreducer state  with no changes
      dispatch({
        type: ENTITY_SUCCESS,
        payload: entityRootReducer.entityReducer,
      });
    }
    
    setAnchorEl(null);
  };

  const handleBackButtonClicked = () => {
    console.log("handleBackButtonClicked");

    const entity = entityRootReducer.entityReducer;
    const companyInfo = companyReducer.companyInfo;
    const [updatedCompany, updatedConnections] = getCompanyConnection(companyInfo, props.companyId.toString());

    if (updatedCompany && entity && entity.companies) {
      entity.companies[props.companyId] = updatedCompany[props.companyId];
    }

    if (updatedConnections && entity && entity.connections) {
      for (var key in updatedConnections) {
        entity.connections[key] = updatedConnections[key];
      }
    }

    dispatch({
      type: ENTITY_SUCCESS,
      payload: entity,
    });
  }
  
  return (
    <>
      <Box display="flex" sx={{ mb: 0.1 }}>
        {!isMobile ? (
          <Stack direction="column" sx={{ width: "100%" }}>
            <Paper className={css.companyBox} variant="outlined">
              <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
              >
                <Grid
                  item
                  xs={6}
                  container
                  spacing={0}
                  direction="row"
                  alignItems="left"
                  justifyContent="top"
                >
                  <Grid
                    item
                    xs={2}
                    container
                    spacing={0}
                    direction="column"
                    alignItems="left"
                    justifyContent="center"
                    sx={{ pb: 3 }}
                  >
                    <Stack direction="column" spacing={1}>
                      <Avatar
                        src={defaultCompanyImage}
                        sx={{ width: "60px", height: "60px" }}
                      />
                    </Stack>
                  </Grid>
                  <Grid
                    item
                    xs={10}
                    container
                    spacing={1}
                    direction="column"
                    alignItems="left"
                    justifyContent="center"
                    sx={{ pl: "10px" }}
                  >
                    <Stack direction="column" spacing={1}>
                      <Typography className={css.primaryName}>
                        {props.compName}
                      </Typography>
                      <Typography className={css.normalText400}>
                        {props.compLocation.replace(/,(\s+)?$/, "")}
                      </Typography>
                      <Typography className={css.normalText400}>
                        {props.compEmail}
                      </Typography>
                      {/* <Typography>xs=4</Typography> */}
                    </Stack>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={3}
                  container
                  spacing={0}
                  direction="column"
                  alignItems="left"
                  justifyContent="center"
                >
                </Grid>

                <Grid
                  item
                  xs={3}
                  container
                  spacing={0}
                  direction="row"
                  alignItems="center"
                  justifyContent="right"
                >
                  <Stack direction="row" spacing={0}>
                    <Grid
                      sx={{ pr: "0px" }}
                      container
                      spacing={0}
                      direction="column"
                      alignItems="center"
                      justifyContent="center"
                    >
                     <Stack
                direction="column"
                spacing={1}
                alignItems="right"
                justifyContent="center"
                sx={{ pr: "2px" }}
              >
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="right"
                  justifyContent="center"
                >
                 {(entityRootReducer.entityReducer && 
                      entityRootReducer.entityReducer.companies[props.companyId].isBlocked) 
                      ? <LightTooltip title={props.compName + " can no longer communicate to you via JobTarget, nor can they see your Profile."}>
                    <InfoOutlinedIcon sx={{ color: "#F98C97" }} />
                  </LightTooltip> : ""}
                  <Typography
                        className={
                          (entityRootReducer.entityReducer && 
                            entityRootReducer.entityReducer.companies[props.companyId].isBlocked) 
                          ? css.blockedStatus
                          : props.isConnected
                            ? css.activeStatus
                            : css.InActiveStatus
                        }
                      >
                        {(entityRootReducer.entityReducer && 
                          entityRootReducer.entityReducer.companies[props.companyId].isBlocked) ? 
                            "Blocked" : props.isConnected ? "Connected" : "Disconnected"}
                      </Typography>
                  </Stack>
                </Stack>
                    </Grid>
                    {/* {(entityRootReducer.entityReducer &&
                      entityRootReducer.entityReducer.companies[props.companyId].isBlocked)
                    ? <UnBlockStatusMenu
                        isConnected={props.isConnected}
                        category="company"
                        companyId={props.companyId}
                        companyName={props.compName}
                        isButton={false}
                      /> 
                    : <StatusMenu
                      isConnected={props.isConnected}
                      category="company"
                      companyId={props.companyId}
                      isBlocked={props.isBlocked}
                      companyName={props.compName}
                    />} */}
                  </Stack>
                </Grid>
              </Grid>
            </Paper>
            <CustomizedAccordions
              connId={props.connectionId}
              totalConnPerCompany={props.totalConnPerCompany}
              count={props.count}
              hideCountBlocked={hideCountBlocked}
            />
          </Stack>
        ) : showIndividualCompany ? (
          <BootstrapDialog
            fullScreen
            aria-labelledby="customized-dialog-title"
            open={true}
            TransitionComponent={Transition}
            key={props.uniqueKey}
            >
              <DialogTitle id="customized-dialog-title" sx={{ ml: -3, mt: -5, mb: -2 }}>
                <Box
                  sx={{
                    background: `url('${headerImage}')`,
                    backgroundSize: isPortrait ? `100% 100%` : `cover`,
                    height: 230,
                    width: windowWidth,
                  }}
                >
                  <IconButton
                    onClick={() => {
                      setGlobalState("currentPage", "main");
                      handleBackButtonClicked();
                      setShowIndividualCompany(false);
                    }}
                    sx={{
                      position: "absolute",
                      left: 15,
                      top: 15,
                      background: "black",
                      width: "30px",
                      height: "30px",
                      opacity: 2,
                    }}
                  >
                    <ArrowBackIosIcon
                      sx={{
                        color: "white",
                        width: "25px",
                        height: "25px",
                        pl: "10px",
                        opacity: 1,
                      }}
                    ></ArrowBackIosIcon>
                  </IconButton>
                  {companyReducer.companyInfo?.company[props.companyId].isBlocked ?
                    <UnBlockStatusMenu
                      isConnected={companyReducer.companyInfo?.company[props.companyId].isConnected}
                      category="company"
                      companyId={props.companyId}
                      companyName={props.compName}
                      isButton={false}
                    /> 
                  : 
                  <StatusMenu
                      isConnected={companyReducer.companyInfo?.company[props.companyId].isConnected}
                      category="company"
                      companyId={props.companyId}
                      isBlocked={companyReducer.companyInfo?.company[props.companyId].isBlocked}
                      companyName={props.compName}
                    />
                  }
                </Box>
                <Typography className={css.dataSharingSettingsModal} component={'div'}>
                  <Avatar
                    src={defaultCompanyImage}
                    sx={{
                      width: "60px",
                      height: "60px",
                      position: "relative",
                      left: 15,
                      top: -30,
                    }}
                  />

                  {companyReducer.companyInfo?.company[props.companyId].isBlocked  ?
                    <Grid container direction="column" sx={{ pl: "15px" }}>
                      <Typography className={css.primaryName} sx={{ pb: "5px" }}>
                        {props.compName}
                      </Typography>
                      <Typography
                        sx={{ fontSize: 15, fontWeight: "light", pb: "5px" }}
                      >
                        {props.compLocation.replace(/,(\s+)?$/, "")}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 15,
                          fontWeight: "light",
                          pb: "5px",
                        }}
                      >
                        <Brightness1RoundedIcon className={css.dotDanger} sx={{marginLeft:"0px !important"}}/>
                        Blocked
                      </Typography>
                    </Grid>
                          :
                          <Grid container direction="column" sx={{ pl: "15px" }}>
                          <Grid
                            container
                            direction="row"
                            justifyContent="flex-end"
                            sx={{ position: "relative", top: -20 }}
                          >
                            {companyReducer.companyInfo?.company[props.companyId].isConnected ? (
                              <>
                                <Button
                                  variant="outlined"
                                  sx={{
                                    color: "#2F86DD",
                                    borderColor: "#2F86DD",
                                    borderRadius: "25px",
                                    textTransform: "none",
                                  }}
                                  onClick={(event) => {
                                    setShowConnectStatus(true);
                                    setAnchorEl(event.currentTarget);
                                  }}
                                >
                                  Connected
                                </Button>
                                {/* {showConnectStatus && (
                                  <Popover
                                    open={true}
                                    onClose={() => {
                                      setShowConnectStatus(false);
                                    }}
                                    anchorEl={anchorEl}
                                    sx={{
                                      alignItems: "center",
                                    }}
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "left",
                                    }}
                                  >
                                    <Typography
                                      sx={{ m: 2, color: "red" }}
                                      onClick={() => {
                                        handleChange("Disconnect");
                                        setShowConnectStatus(false);
                                      }}
                                    >
                                      Disconnect {props.compName}
                                    </Typography>
                                  </Popover>
                                )} */}
                              </>
                            ) : (
                              <Button
                                variant="contained"
                                sx={{
                                  color: "white",
                                  borderColor: "black",
                                  borderRadius: "25px",
                                  textTransform: "none",
                                }}
                                // onClick={() => {
                                //   handleChange("Connect");
                                //   setShowConnectStatus(false);
                                // }}
                              >
                                Connect
                              </Button>
                            )}
                          </Grid>
                          <Typography className={css.primaryName} sx={{ pb: "5px" }}>
                            {props.compName}
                          </Typography>
                          <Typography
                            sx={{ fontSize: 15, fontWeight: "light", pb: "5px" }}
                          >
                            {props.compLocation.replace(/,(\s+)?$/, "")}
                          </Typography>
                          {companyReducer.companyInfo?.company[props.companyId].isConnected ? (
                            <Typography
                              sx={{
                                fontSize: 15,
                                fontWeight: "light",
                                pb: "5px",
                              }}
                            >
                              <CalendarTodayIcon
                                sx={{ width: 15, height: 15 }}
                              ></CalendarTodayIcon>
                              {props.connectionEstablished ? "  Connected: " 
                                + new Date(props.connectionEstablished).toLocaleString(
                            "default",
                            { month: "long" }
                          ) +
                          " " +
                          new Date(props.connectionEstablished).getFullYear() : "  Connected: "}
                            </Typography>
                          ) : (
                            <Typography
                              sx={{
                                fontSize: 15,
                                fontWeight: "light",
                                pb: "5px",
                                ml: "-5px"
                              }}
                            >
                              <Brightness1RoundedIcon className={css.dotGrey} />
                              Disconnected
                            </Typography>
                          )}
                      </Grid>
                        // )
                      // }
                    // })
                  }
                </Typography>
              </DialogTitle>
              <DialogContent sx={{ ml: "-10px", overflow: `unset` }}>
                <hr />
                {
                  companyReducer.companyInfo?.company[props.companyId].isBlocked  ? 
                  <Grid
                  container
                  direction="column"
                  justifyContent="flex-end"
                  sx={{ position: "relative", top: 10 }}
                  >
                  <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        sx={{ position: "relative", pl: 2 }}
                        >
                          <Typography>{props.compName} can no longer communicate with you via JobTarget, nor can they see your profile</Typography>
                      </Grid>
                      <UnBlockStatusMenu
                          isConnected={companyReducer.companyInfo?.company[props.companyId].isConnected}
                          category="company"
                          companyId={props.companyId}
                          companyName={props.compName}
                          isButton={true}
                          /> 
                </Grid>
                : 
                    <Grid direction={"column"}>
                      <Typography
                        className={css.primaryName}
                        sx={{ pb: 1, pl: 1.3 }}
                        >
                        Connections
                      </Typography>
                      <DialogActions>
                          <SearchBox
                            PlaceHolder={"Search"}
                            Mobile={true}
                            Person={true}
                            ></SearchBox>
                        </DialogActions>
                      <DialogActions>
                      <TabConnIndividual
                            connId={props.connectionId}
                            count={companyReducer.companyInfo?.company[props.companyId].count}
                            totalConnPerCompany={props.totalConnPerCompany}
                            hideCountBlocked={hideCountBlocked}
                            />
                        </DialogActions>
                    </Grid>
                }
              </DialogContent>
            </BootstrapDialog>
        ) : (
          <>
            <Stack
              direction="column"
              sx={{ width: !isPortrait ? 240 : (windowWidth - 20) / 2, padding: 0.5 }}
              onClick={handlerIndividualCompany}
            >
                <Box
                sx={{
                  border: "1px solid #E4E6EF",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "24px 16px",
                  backgroundColor: "#F8F9FA",
                  borderRadius: '24px',
                  height: '220px',
                  width: '100%'
                }}
                >
                <Stack
                  direction="column"
                  spacing={2}
                  alignItems="center"
                  sx={{ width: "100%", height: "200px", position: "relative" }}
                >
                  <Avatar
                    src={defaultCompanyImage}
                    sx={{ width: "60px", height: "60px" }}
                  />
                  <Typography
                    className={css.primaryName}
                    sx={{
                      width: !isPortrait ? "225px" : (windowWidth - 50) / 2 + "px",
                      justifyContent: "center",
                    }}
                  >
                    {props.compName}
                  </Typography>
                  <>
                    <Typography
                      sx={{
                        bottom: "10px",
                        my: "20px",
                        position: "absolute",
                        fontSize: "15px",
                      }}
                    >
                      <Brightness1RoundedIcon
                        className={
                          entityRootReducer.entityReducer?.companies[props.companyId].isBlocked ? css.dotDanger : props.isConnected ? css.dotSuccess : css.dotGrey
                        }
                      />
                      {entityRootReducer.entityReducer?.companies[props.companyId].isBlocked ? "Blocked" : props.isConnected ? "Connected" : "Disconnected"}
                    </Typography>
                  </>
                </Stack>
                </Box>
            </Stack>
          </>
        )}
      </Box>
    </>
  );
};
export default CompanyDetails;
