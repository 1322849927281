import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses, TooltipProps } from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import UnBlockStatusMenu from "../../component/UnBlockStatusMenu";
import { useStyles } from "../../style/styles";
import StatusMenu from "../StatusMenu";

interface IStatus {
  isConnected: boolean;
  status: string;
  connectionId: string;
  companyId: string;
  entity: string;
  isBlocked?: boolean;
  fullname?: string;
}
const StatusSection = (props: IStatus) => {
  const css = useStyles();

  const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} placement="top" arrow />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: "rgba(0, 0, 0, 0.20)",
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));

  // console.log("STATUS SECTION: " + props.status + " CONNECTION ID: " + props.connectionId);

  if (props.status.toLowerCase() == "blocked") {
    return (
      <Grid
        item
        xs={2}
        container
        spacing={0}
        direction="row"
        alignItems="right"
        justifyContent="right"
      >
        <Stack
          direction="column"
          spacing={1}
          alignItems="right"
          justifyContent="center"
          sx={{ pr: "2px" }}
        >
          <Stack
            direction="row"
            spacing={1}
            alignItems="right"
            justifyContent="center"
          >
            <LightTooltip title="This Recruiter cannot communicate to you via JobTarget, nor can they see your profile.">
              <InfoOutlinedIcon sx={{ color: "#F98C97" }} />
            </LightTooltip>
            <Typography className={css.blockedStatus}>Blocked</Typography>
          </Stack>
        </Stack>

        <Stack
          direction="column"
          spacing={1}
          alignItems="right"
          justifyContent="center"
          sx={{ pr: "20px" }}
        >
          <UnBlockStatusMenu
            isConnected={props.isConnected}
            category="individual"
            connectionId={props.connectionId}
            isButton={false}
          />
        </Stack>
      </Grid>
    );
  } else if (props.status.toLowerCase() == "pending") {
    return (
      <Grid
        item
        xs={6}
        container
        spacing={0}
        direction="column"
        alignItems="right"
      >
        <Grid
          item
          xs={2}
          container
          spacing={0}
          direction="column"
          alignItems="right"
          justifyContent="right"
        >
          <Stack
            direction="row"
            spacing={1}
            alignItems="right"
            justifyContent="right"
            sx={{ pr: "50px" }}
          >
            <Typography className={css.normalText400}>
              A Recruiter is trying to connect to you
            </Typography>
          </Stack>

          <Stack
            spacing={2}
            direction="row"
            alignItems="right"
            justifyContent="right"
            sx={{ pr: "50px", pt: "10px" }}
          >
            <Button
              variant="text"
              className={css.cancelTextBtn}
              //   onClick={handleClose}
            >
              Decline
            </Button>
            <Button variant="contained" className={css.saveBtn}>
              Accept Connection
            </Button>
          </Stack>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid
      item
      xs={3}
      container
      spacing={0}
      direction="row"
      alignItems="center"
      justifyContent="right"
    >
      <Stack direction="row" spacing={0}>
        <Grid
          sx={{ pr: "0px" }}
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            className={
              props.isConnected ? css.activeStatus : css.InActiveStatus
            }
          >
            {props.status.toLowerCase() == "connected"
              ? "Connected"
              : "Disconnected"}
          </Typography>
        </Grid>
        <Grid
          sx={{ pr: "-10px" }}
          container
          spacing={0}
          direction="row"
          alignItems="center"
          justifyContent="right"
        >
          <StatusMenu
            isConnected={props.isConnected}
            category="individual"
            connectionId={props.connectionId}
            companyId={props.companyId}
            isBlocked={props.isBlocked ? props.isBlocked : false}
            fullname={props.fullname}
          />
        </Grid>
      </Stack>
    </Grid>
  );
};

export default StatusSection;
