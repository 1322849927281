import { Grid } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import { useCallback, useRef, useState, useEffect } from "react";
import { Dimensions } from "react-native";
import { useSelector } from "react-redux";
import { RootStore } from "../../store/Store";
import { getEntityCompanyConnection } from "../../utils/EntityFunctions";
import { useGlobalState } from "../../utils/GlobalState";
import CompanyDetails from "./CompanyDetails";


interface ICompany {
  tabIndex: number;
}

const Company = (props: ICompany) => {
  const entityState = useSelector(
    (state: RootStore) => state.entityRootReducer
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(3);
  const indexOfLastPage = currentPage * perPage;

  const filterByTabIndex = (targetList) => {
    if (targetList)
      return targetList.filter(([key, val]) => 
        (props.tabIndex == 1 && val.isConnected) || 
        (props.tabIndex == 2 && !val.isConnected && !val.isBlocked) ||
        (props.tabIndex == 3 && val.isBlocked) ||
        (props.tabIndex == 0));
    
    return [];
  };

  const allDataByIndex = entityState.entityReducer
    ? filterByTabIndex(Object.entries(entityState.entityReducer.companies)) : [];
    
  const [isFetching, setIsFetching] = useState(false);
  const totalShowLenth = 10;
  const [dataList, setDataList] = useState(getNextList(0, totalShowLenth));
  const [isCanLoadMore, setIsCanLoadMore] = useState(true);
  const searchEntity = useGlobalState("searchEntity");
  const currPage = useGlobalState("currentPage");

  const totalCompanies = allDataByIndex
    ? allDataByIndex.length
    : 0;

  const scrollToRef = (ref) => window.scrollTo(0, ref.length); // ref.current.offsetTop-110)

  const observer = useRef(null as any);

  const lastElementRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          // console.log("Visible", "Total Companies", totalCompanies)
          if (dataList && dataList.length < totalCompanies) {
            setCurrentPage(currentPage + 1);
            // console.log("Current Page: ",currentPage);
          }
        }
      });

      if (node) {
        observer.current.observe(node);
        // console.log(node)
      }
    },
    [dataList]
  );

  const scrollToTop = () => {
    scrollToRef(lastElementRef);
  };

  function getNextList(from, to) {
    let totalLength = to;
    if (allDataByIndex && to > allDataByIndex.length)
      totalLength = 
      allDataByIndex?.length;
    return allDataByIndex && allDataByIndex
      .slice(from, totalLength);
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  },[]);

  useEffect(() => {
    if (!isFetching) return;
    scrollMoreDataList();
  }, [isFetching]);

  useEffect(() => {
    if (dataList?.length == 
      allDataByIndex?.length) 
      setIsCanLoadMore(false);
  }, [dataList]);

  function handleScroll() {
    const collection = document.getElementsByClassName("MuiBox-root css-1fdwimu");
    // const footerEl = document.querySelector('footer');
    if (!collection ||  collection.length == 0) return;
    if ((!isBottom(collection.item(collection.length - 1)) || isFetching) && !isCanLoadMore) return;
    setIsFetching(true);
  }

  function scrollMoreDataList() {
    setTimeout(() => {
      setDataList(prevState => ([...prevState, ...getNextList(prevState?.length, 
        totalShowLenth + (prevState?.length + 1))]));
      setIsFetching(false);
    }, 2000);
  }

  function isBottom(el) {
    return Math.round(el.getBoundingClientRect().bottom) <= window.innerHeight;
  }

  const updateDataList = () => {
    setDataList((currentList) => filterByTabIndex(currentList));
  };

  useEffect(() => {
    (currPage[0].toLowerCase() == "main" && props.tabIndex != 0) && updateDataList();
  },[entityState]);

  // console.log(searchEntity);
  const { isMobile } = useSelector(
    (state: RootStore) => state.deviceReducer.deviceData
  );
  return (
    <>
    <Grid container direction={isMobile ? "row" : "column"}>
      {/* <Grid container direction="row"> */}
      {(dataList && searchEntity[0].toLowerCase() == "") ? (
        dataList
          .map(([key, value], index) => {
            return (
              <div
                key={index}
                ref={
                  dataList.length === index + 1
                    ? lastElementRef
                    : null
                }
              >
                <CompanyDetails
                  companyId={key}
                  compName={
                    value.companyName ? value.companyName : "No Company Name"
                  }
                  compLocation={
                    (value.city ? value.city + "," : "") +
                    " " +
                    (value.state ? value.state + "," : "") +
                    " " +
                    (value.country ? value.country + "," : "") +
                    " " +
                    (value.zipCode ? value.zipCode + "," : "")
                  }
                  compEmail={value.email}
                  isConnected={value.isConnected}
                  isBlocked={value.isBlocked}
                  uniqueKey={key}
                  connections={value.connections}
                  connectionId={key}
                  totalConnPerCompany={
                    value.connections ? value.connections.length : 0
                  }
                  profileAccess={value.profileAccessEnabled}
                  contactInfo={value.contactInfoEnabled}
                  count={value.count}
                  connectionEstablished={value.date}
                />
              </div>
            );
          })
      ) : !(searchEntity[0].toLowerCase() == "") ? 
        (
          allDataByIndex
          .filter(([key, value]) => {
            if (searchEntity[0].toLowerCase() == "") {
              return value;
            } else if (
              value.companyName
                .toLowerCase()
                .includes(searchEntity[0].toLowerCase())
            ) {
              return value;
            }
          })
          .map(([key, value], index) => {
            // console.log("COMPANY BLOCKED: " + value.isBlocked);
            return (
              <div
                key={index}
                ref={
                  dataList.length === index + 1
                    ? lastElementRef
                    : null
                }
              >
                <CompanyDetails
                  companyId={key}
                  compName={
                    value.companyName ? value.companyName : "No Company Name"
                  }
                  compLocation={
                    (value.city ? value.city + "," : "") +
                    " " +
                    (value.state ? value.state + "," : "") +
                    " " +
                    (value.country ? value.country + "," : "") +
                    " " +
                    (value.zipCode ? value.zipCode + "," : "")
                  }
                  compEmail={value.email}
                  isConnected={value.isConnected}
                  isBlocked={value.isBlocked}
                  uniqueKey={key}
                  connections={value.connections}
                  connectionId={key}
                  totalConnPerCompany={
                    value.connections ? value.connections.length : 0
                  }
                  profileAccess={value.profileAccessEnabled}
                  contactInfo={value.contactInfoEnabled}
                  count={value.count}
                  connectionEstablished={value.date}
                />
              </div>
            );
          })
      )
      :      
      (
        <Skeleton variant="rectangular" width={210} height={118} />
      )}
    </Grid>
    {isFetching && isCanLoadMore && "Loading more list items..."}
    </>
  );
};
export default Company;
