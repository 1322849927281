import {
    DeviceData,
    DeviceDispatchTypes,
    UPDATE_DEVICE,
} from "../action/DeviceActionTypes";
import { Dimensions } from "react-native";
import { REACT_APP_MOBILE_WIDTH, REACT_APP_MOBILE_PORTRAIT_WIDTH } from "@env";

export interface DefaultStateI {
    deviceData: DeviceData;
}

export const defaultState: DefaultStateI = {
    deviceData: { 
        isMobile: false,
        isPortrait: false,
        windowWidth: 0,
    },
};

const deviceReducer = (
    state: DefaultStateI = defaultState,
    action: DeviceDispatchTypes
) : DefaultStateI => {
    switch (action.type) {
        case UPDATE_DEVICE:
            const windowWidth = Dimensions.get("window").width;
            let mobileWidth = 800;
            let mobilePortraitWidth = 500;

            if (REACT_APP_MOBILE_WIDTH)
                mobileWidth = REACT_APP_MOBILE_WIDTH;
            if (REACT_APP_MOBILE_PORTRAIT_WIDTH)
                mobilePortraitWidth = REACT_APP_MOBILE_PORTRAIT_WIDTH;

            return {
                deviceData: {
                    isMobile: windowWidth <= mobileWidth, 
                    isPortrait: windowWidth <= mobilePortraitWidth,
                    windowWidth: windowWidth,
                },
            };
        default:
          return state;
    }
};

export default deviceReducer;