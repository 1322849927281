import {
    RecruiterDispatchTypes,
    RECRUITER_SUCCESS,
    RECRUITER_FAIL,
    RECRUITER_LOADING
 } from "../action/RecruiterActionTypes";
 import { Connections } from "../action/EntityActionType";

export interface DefaultStateI {
  loading: boolean;
  connection?: Connections;
}

export const defaultState: DefaultStateI = {
  loading: false,
};

const recruiterReducer = (
  state: DefaultStateI = defaultState,
  action: RecruiterDispatchTypes
): DefaultStateI => {
  switch (action.type) {
    case RECRUITER_FAIL:
      return {
        loading: false,
      };
    case RECRUITER_LOADING:
      return {
        loading: true,
      };
    case RECRUITER_SUCCESS:
      return {
        loading: false,
        connection: action.payload,
      };
    default:
      return state;
  }
};

export default recruiterReducer;
