import CloseIcon from "@mui/icons-material/Close";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useContext } from "react";
import { Dimensions } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import {
  SendEventsByMessage,
  UpdateIsBlocked,
} from "../action/DsSettingsAction";
import { CompanyLevelIsBlocked, UpdateEntity } from "../action/EntityAction";
import { ENTITY_FAIL, ENTITY_SUCCESS } from "../action/EntityActionType";
import { EntitiesStoreContext } from "../store/EntitiesStore";
import { RootStore } from "../store/Store";
import { useStyles } from "../style/styles";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Popover from "@mui/material/Popover";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import {
  formatConnectionDsSettings,
  getCompanyConnection,
  getEntityCompanyConnection,
  handleConnectionUpdate,
  handleStatusChange,
} from "../utils/EntityFunctions";
import { RECRUITER_SUCCESS } from "../action/RecruiterActionTypes";
import { COMPANY_SUCCESS } from "../action/CompanyActionTypes";
import { useGlobalState } from "../utils/GlobalState";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const Item = styled(Box)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  // ...theme.typography.body2,
  // padding: theme.spacing(1),
  textAlign: 'center',
  // borderLeft: '1px solid #eee'
  // color: theme.palette.text.secondary,
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const options = ["Connect", "Disconnect"];

const ITEM_HEIGHT = 48;

interface IStatusMenu {
  isConnected: boolean;
  isBlocked: boolean;
  category: string;
  connectionId?: string;
  companyId?: string;
  companyName?: string;
  fullname?: string;
}

const StatusMenu = (props: IStatusMenu) => {
  const css = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const entityStore = useContext(EntitiesStoreContext);
  const dispatch = useDispatch();
  const [isOpen, setOpen] = React.useState(false);
  const [showBlock, setShowBlock] = React.useState(false);
  const currentPage = useGlobalState("currentPage");

  const {entityRootReducer, companyReducer, recruiterReducer} = useSelector(
    (state: RootStore) => state
  );

  const { isMobile, windowWidth } = useSelector(
    (state: RootStore) => state.deviceReducer.deviceData
  );

  const entityType = entityRootReducer.entityReducer?.type;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleBlock = () => {
    console.log("handleBlock");
    const stat = "Block";

    if (props.category == "individual") {
      // Payload to block the individual
      const isBlockedPayload = {
        entityId: entityStore.entityId,
        connectionId: props.connectionId,
        isBlocked: true,
      };
      // Dispatch call to block the individual
      dispatch(UpdateIsBlocked(isBlockedPayload));

      // update cache here...
      const entity = entityRootReducer.entityReducer;
      if (!isMobile) {
        if (props.connectionId  && entity) {
          const recruiter = {};
          recruiter[props.connectionId] = 
              entity.connections[props.connectionId];
          handleStatusChange(stat, false,
            {}, {}, recruiter);
          
          const companyId = recruiter[props.connectionId].companyId;
          const [company, connections] = getEntityCompanyConnection(entity, companyId);
          handleConnectionUpdate(companyId, company, connections);
          
          // This will reset the entityrootreducer state with changes
          dispatch({
            type: ENTITY_SUCCESS,
            payload: entity,
          });
        }
      } else {
        if (props.connectionId && recruiterReducer && recruiterReducer.connection) {
          handleStatusChange(stat, false,
            {}, {}, recruiterReducer.connection);
        
          dispatch({
            type: RECRUITER_SUCCESS,
            payload: recruiterReducer.connection,
          });
        }
      }
    } else if (props.category == "company") {
      const isBlockedPayload = {
        entityId: entityStore.entityId,
        connectionId: props.companyId,
        isBlocked: true,
      };
  
      // console.log(isBlockedPayload);
  
      // dispatch(UpdateIsBlocked(isBlockedPayload));
      console.log("Call block company api");
      dispatch(CompanyLevelIsBlocked(isBlockedPayload));

      // update cache here...
      if (!isMobile && props.companyId) {
        const companyId = props.companyId.toString();
        const [company, connections] = getEntityCompanyConnection(entityRootReducer.entityReducer, 
          companyId);
        handleStatusChange(stat, true,
          company, connections, {}, companyId);
        handleConnectionUpdate(companyId, company, connections);
        // This will reset the entityrootreducer state with changes
        dispatch({
          type: ENTITY_SUCCESS,
          payload: entityRootReducer.entityReducer,
        });
      } else {
        if (props.companyId) {
          const companyId = props.companyId.toString();
          const [company, connections] = getCompanyConnection(companyReducer.companyInfo, 
            companyId);
          handleStatusChange(stat, true,
            company, connections, {}, companyId);
          handleConnectionUpdate(companyId, company, connections);

          // This will set the companyreducer state
          dispatch({
            type: COMPANY_SUCCESS,
            payload: companyReducer.companyInfo,
          });

          // This will reset the entityrootreducer state with no changes
          dispatch({
            type: ENTITY_SUCCESS,
            payload: entityRootReducer.entityReducer,
          });
        }
      }
    }

    setOpen(false);
  };

  const handleChange = (stat: string) => {
    console.log ("handleChange - stat: " + stat +
      " category: " + props.category);

    let isShow = false;
    let recruiterId = "";
    let recruiterId64 = "";
    let jobseekerId64 = "";
    let profileId = "";

    if (props.category == "individual") {
      // handleIndividuals(stat);
      if (stat == "Block") {
        setOpen(true);
      } else {
        if (stat == "Connect") {
          isShow = true;
        }
        // console.log("PROPS CONNECTION ID: " + props.connectionId);
        if (props.connectionId && entityRootReducer.entityReducer) {
          let connId = parseInt(props.connectionId);
          if (entityStore.entityType == "jobseeker") {
            recruiterId = entityRootReducer.entityReducer.connections[connId].id;
            recruiterId64 =
              entityRootReducer.entityReducer.connections[connId].id64;
            jobseekerId64 = entityRootReducer.entityReducer.id64;
            profileId =
              entityRootReducer.entityReducer.emails[
                entityRootReducer.entityReducer.email
              ].profileId[0];
              // console.log("JOBSEEKER TYPE: RECRUITER ID: " + recruiterId64);
          } else if (entityStore.entityType == "recruiter") {
            recruiterId = entityRootReducer.entityReducer.id;
            recruiterId64 = entityRootReducer.entityReducer.id64;
            jobseekerId64 =
              entityRootReducer.entityReducer.connections[connId].id64;
          }
        }

        const eventsPayload = {
          action: stat.toLowerCase(), // connect/disconnect
          source_entity: recruiterId, // recruiter_id from dynamoDB
          job_seeker_id_64: jobseekerId64, // jobseeker_id from 64recs
          profile_id: profileId, // Empty if recruiter
          isConnected: isShow.toString(), // true or false (true/false is string as requirement of the endpoint)
          isBlocked: "false", // set to false since this condition is for active/inactive (true/false is string as requirement of the endpoint)
          status: isShow ? "accepted" : "declined", // accepted, declined, pending, blocked (this condition is for active/inactive only)
          isPending: "false", // set to false since this condition is for active/inactive (true/false is string as requirement of the endpoint)
          source: recruiterId64, // recruiter_id from 64recs
          caller: "connection", // "connection" for Connections App
          target: jobseekerId64, // jobseeker_id from 64recs
        };

        // console.log(eventsPayload);

        dispatch(SendEventsByMessage(eventsPayload));

        // Update cache here...
        try {
          if ( (!isMobile || currentPage[0] == "main") && 
            entityRootReducer.entityReducer && props.connectionId) {
            const entity = entityRootReducer.entityReducer;
            const connection = {};
            connection[props.connectionId] = entity.connections[props.connectionId];
            formatConnectionDsSettings(isShow, 
              props.connectionId, connection);
            
            const companyId = entity.connections[props.connectionId].companyId;
            const [company, connections] = getEntityCompanyConnection(entity, 
              companyId)
            handleConnectionUpdate(companyId, company, connections);

            // This will set the state with changes
            dispatch({
              type: ENTITY_SUCCESS,
              payload: entity,
            });
          } else {
            if (recruiterReducer && recruiterReducer.connection) {
              formatConnectionDsSettings(isShow, props.connectionId, 
                recruiterReducer.connection);
              
              dispatch({
                type: RECRUITER_SUCCESS,
                payload: recruiterReducer.connection,
              });
            }
          }
        } catch {
          dispatch(ENTITY_FAIL);
        }
      }
    } else if (props.category == "company") {
      console.log("ANG STATUS KAY " + stat);
      if (stat == "Block") {
        setOpen(true);
      } else {

      if (stat == "Connect") {
        isShow = true;
      }

      const payloads = {
        id: entityStore.entityId,
        connectionId: props.companyId,
        connect: isShow,
      };

      // Call Update entity api
      console.log("Call update entity api");
      dispatch(UpdateEntity(payloads));

      // Refresh the entire entity state
      if (entityRootReducer.entityReducer && props.companyId) {
        const entity = entityRootReducer.entityReducer;
        const companyId = props.companyId.toString();
        const [company, connections] = getEntityCompanyConnection(entity, companyId);
        handleStatusChange(stat, true,
          company, connections, {}, companyId);

        for (var connId in connections) {
          const connData = {};
          connData[connId] = connections[connId];
          formatConnectionDsSettings(isShow, connId, connData);
        }

        handleConnectionUpdate(companyId, company, connections);
        // This will reset the entityrootreducer state with changes
        // console.log(entity);
        // This will set the state
        dispatch({
          type: ENTITY_SUCCESS,
          payload: entity,
        });
      }
    }
    }
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  return (
   !isMobile ? <div>
      <Dialog
        open={isOpen}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCloseDialog}
        >
          <Typography className={css.dataSharingSettingsModal}>
            Block Connection
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description">
            {props.category.toLowerCase() == "company" 
            ? "Recruiters from " + props.companyName + " will no longer be able to see your profile and connect with you." : (
              entityType?.toLowerCase() == "recruiter" ? 
                "Jobseeker " + props.fullname + " will no longer be able to see your profile and connect with you.":
                "Recruiter " + props.fullname + " will no longer be able to see your profile and connect with you."
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            className={css.cancelTextBtn}
            onClick={handleCloseDialog}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            className={css.primaryBtn}
            onClick={handleBlock}
            autoFocus
          >
            Block
          </Button>
        </DialogActions>
      </Dialog>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option}
            // selected={option === "Connect"}
            onClick={() => handleChange(option)}
            disabled={
              (props.isConnected && option === "Connect") ||
              (!props.isConnected && option === "Disconnect")
                ? true
                : false
            }
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
    :
    <>
      <Dialog
        open={isOpen}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        // sx={{borderRadius: "34px", backgroundColor: "#F2F2F2"}}
      >
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description">
            <div><Typography component={'span'} sx={{fontSize: "17px"}}>Block {props.category == "company" ? props.companyName : props.fullname}</Typography></div>
            <div><Typography component={'span'} sx={{fontSize: "13px"}}>{props.category == "company" ? 
            "Recruiters from " + props.companyName : 
              entityType?.toLowerCase() == "recruiter" ? "Jobseeker " : "Recruiter " + props.fullname} will no longer be able to see your profile and connect with you.</Typography></div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Grid 
          container 
          direction="row"
          justifyContent="space-evenly"
          alignItems="center"
        >
          <Grid item xs={6}>
            <Item sx={{borderRight: '1px solid #eee'}}>
            <Button
              variant="text"
              className={css.cancelTextBtn}
              onClick={handleCloseDialog}
              sx={{textTransform: 'unset'}}
            >
              Cancel
            </Button>
            </Item>
          </Grid>
          <Grid item xs={6}>
            <Item>
            <Button
              variant="text"
              className={css.cancelTextBtn}
              onClick={handleBlock}
              sx={{textTransform: 'unset'}}
            >
              Block
            </Button>
            </Item>
          </Grid>
        </Grid>
        </DialogActions>
      </Dialog>
      <IconButton
        onClick={(event) => {
          setShowBlock(true);
          setAnchorEl(event.currentTarget);
          console.log("setShowBlock to TRUE");
        }}
        sx={{
          position: "absolute",
          right: 15,
          top: 15,
          background: "black",
          width: "30px",
          height: "30px",
          opacity: 2,
        }}
      >
        <MoreHorizIcon
          sx={{
            color: "white",
            width: "25px",
            height: "25px",
            // pl: "5px",
            opacity: 2,
          }}
        ></MoreHorizIcon>
      </IconButton>
      {showBlock && (
        <Popover
          open={true}
          onClose={() => {
            setShowBlock(false);
          }}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <Button 
          variant="text"
          onClick={() => {
            setShowBlock(false);
            handleChange("Block");
            console.log("Block COmp");
          }}
          sx={{textTransform: 'unset', color: '#000000'}}
          >
            <Typography sx={{ m: 1 }}>
              Block {props.category == "company" ? props.companyName : props.fullname}
            </Typography>
          </Button>
        </Popover>
      )}
    </>
  );
};

export default StatusMenu;
